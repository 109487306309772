import React, { FC } from "react";
import { Season } from "../../../models/season";
import useTranslation from "../../../i18n/hooks/useTranslation";
// import Dots from "../../../assets/icons/dots_with_square.svg";
import moment from "moment";

interface IProps {
  seasons: Season[];
  openDeleteSeason: (id: string | number) => void;
  seasonUpdated: Function;
}

const SeasonsTable: FC<IProps> = ({ seasons, openDeleteSeason, seasonUpdated }) => {
  const t = useTranslation();

  return (
    <div className="db-card tw-overflow-x-scroll">
      <h4 className="mb-3" style={{ color: "#EB9C3F" }}>
        المواسم السابقة غير مفعلة
      </h4>

      {seasons.length === 0 ? (
        <div className="no-season d-flex">
          <p className="m-auto">لا يوجد اي مواسم سابقة</p>
        </div>
      ) : (
        <table className="seasons-table">
          <thead>
            <tr>
              <th scope="col">{t("MANAGE_SEASONS.EDUCATIONAL_YEAR")}</th>
              <th scope="col">{t("MANAGE_SEASONS.REGISTERED_SCHOOLS")}</th>
              <th scope="col">{t("MANAGE_SEASONS.SEASON_STATE")}</th>
              <th scope="col">{t("MANAGE_SEASONS.RESULTS")}</th>
              <th scope="col">{t("MANAGE_SEASONS.RESULTS_DATE")}</th>
              <th scope="col">{t("MANAGE_SEASONS.BY")}</th>
            </tr>
          </thead>
          <tbody>
            {seasons.map((season) => (
              <tr key={"season-" + season.id}>
                <td>{season.year}</td>
                <td>{season.registration_requests?.length || 0}</td>
                <td>{t("MANAGE_SEASONS.CLOSED")}</td>
                <td>{t(season.resultsPublished ? "MANAGE_SEASONS.RESULTS_PUBLISHED" : "MANAGE_SEASONS.RESULTS_NOT_PUBLISHED")}</td>
                <td>{season.resultsPublished ? moment(season.resultsPublishedAt).locale("en").format("DD/MM/YYYY") : "-"}</td>
                <td>{season.user?.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SeasonsTable;
