import React, { FC, useEffect, useState } from "react";
import Cards from "./Cards";
import Header from "./Header";
import Stage from "./Stage";
import "./results.scss";
import Footer from "../../../components/Footer";
import Tables from "./Tables";
import { Season } from "../../../models/season";
import api from "../../../api";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Results: FC = () => {
  AOS.init();
  const [seasons, setSeasons] = useState<Season[] | null>(null);
  const [filters, setFilters] = useState({
    season: "",
    name: "",
  });

  const getSeasons = async () => {
    let { data } = await api.get("/seasons");
    setSeasons(data);
  };

  const getRequests = (seasons: Season[]) => {
    let registrationRequests = [];
    let { season, name } = filters;
    if (season) registrationRequests = seasons.filter((s) => s.year.toString() === season)[0].registration_requests;
    else registrationRequests = seasons![0].registration_requests;

    if (name) registrationRequests = registrationRequests.filter((r) => r.school.name.includes(name));

    return registrationRequests.filter((req) => req.status === "accepted" && req.rank).sort((a, b) => b.score! - a.score!);
  };

  useEffect(() => {
    if (!seasons) getSeasons();
  }, [seasons]);

  let requests = seasons ? getRequests(seasons) : []

  var originalRequests = null

  if(seasons){
    if (filters.season) 
      originalRequests = seasons.filter((s) => s.year.toString() === filters.season)[0].registration_requests;
    else 
      originalRequests = seasons![0].registration_requests;

    originalRequests = originalRequests.filter((req) => req.status === "accepted" && req.rank).sort((a, b) => b.score! - a.score!)
  }
  
  

  return (
    <div className="results-page">
      <Header season={seasons && seasons[0] ? seasons[0].year : ""} />
      <Stage />
      {!seasons || !originalRequests ? (
        <div className="mt-4 pt-4">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Cards schools={originalRequests.length} bestMark={originalRequests[0]?.score || 0} />
          <Tables seasons={seasons} requests={requests} setFilters={setFilters} filters={filters} />
        </>
      )}
      <Footer />
    </div>
  );
};

export default Results;
