import React, { FC, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import api from "../../../api";
import Footer from "../../../components/Footer";
import SchoolCard from "../../../components/SchoolCard";
import { School } from "../../../models/school";
import Header from "./Header";
import "./school-details.scss";
import { SchoolDet } from "./SchoolDet";
import useTranslation from "../../../i18n/hooks/useTranslation";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";

const SchoolDetails: FC = () => {
  const t = useTranslation();

  let { id } = useParams<{ id: string }>();

  const [school, setSchool] = useState<School | null>(null);

  const getSchool = async (id: string) => {
    let { data } = await api.get("/schools/" + id);
    setSchool(data);
  };

  useEffect(() => {
    if (!school) getSchool(id!);
  }, [school, id]);

  return (
    <div className="school-details-page">
      <Header title={!school ? t("LOADING") + "..." : school.name} schoolId={id} />
      {!school ? (
        <div className="mt-5">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="container px-4 -tw-mt-14">
          <Row>
            <Col xs={12} md={3}>
              <SchoolCard {...school} />
            </Col>
            <Col xs={12} md={9}>
              <SchoolDet requests={school.registration_requests} />
            </Col>
          </Row>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default SchoolDetails;
