import React, { FC } from "react";
import "./Headerimage.scss"

const Headerimage: FC = () => {
    return ( 
        <div className="headerimage" data-aos="fade-up">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img4"></div>
            <div className="img3"></div>
            <div className="img5"></div>
        </div>
    );
};
export default Headerimage;