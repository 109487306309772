import React, { FC, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { RootState } from "../../../store";
import { resetPassword } from "../../../store/actions/authActions";
import { BsChevronLeft } from "react-icons/bs";
import Logo from "../../../assets/images/Login/logo-loginpage.svg";
import path_final from "../../../assets/images/Login/path-final.svg";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ResetPassword: FC = () => {
  
  const t = useTranslation();
  let { token } = useParams<{token: string}>()

  const navigate = useNavigate()
  const { authenticated } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const [data, setData] = useState({
    password: "",
    confirm: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }));
  };

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    let { password, confirm } = data
    if (password !== confirm) 
      toast.error(t('SUCCESS.PASSWORDS_DONT_MATCH'))

    dispatch(resetPassword({ token, password }, () => navigate("/login")));
  };

  if (authenticated) return <Navigate to="/admin/dashboard" />;

  return (
    <div className="login-page reset-page">
      <div className="right">
        <img src={Logo} alt="" />
          <form onSubmit={submitHandler}>
            <h5>{t("AUTH.CHANGE_PASSWORD")}</h5>
            <hr />
            <div className="form-group mb-3">
              <label htmlFor="password">{t("AUTH.NEW_PASSWORD")}</label>
              <input type="password" className="form-control" id="password" name="password" value={data.password} onChange={handleChange} required />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="confirm">{t("AUTH.CONFIRM_NEW_PASSWORD")}</label>
              <input type="password" className="form-control" id="confirm" name="confirm" value={data.confirm} onChange={handleChange} required />
            </div>
            <button type="submit" className="btn btn-primary">
              <span>{t("AUTH.CHANGE_PASSWORD")}</span>
              <BsChevronLeft />
            </button>
          </form>
        <p>جميع الحقوق محفوظة لمبادرة تعزيز الصحة المدرسية 2022</p>
      </div>
      <div className="left">
        <img src={path_final} alt="" className="path_final" />
        <p>معا من أجل مدارس معززة للصحة</p>
      </div>
    </div>
  );
};

export default ResetPassword;
