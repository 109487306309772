import React, { FC } from "react";
import card1 from "../../../assets/images/1card.png";
import card2 from "../../../assets/images/2card.png";
import card3 from "../../../assets/images/3card.png";
import card4 from "../../../assets/images/4card.png";
import card5 from "../../../assets/images/5card.png";
import "./about.scss";

const Partners: FC = () => {
  return (
    <div data-aos="fade-up" className="cardsection">
      <img data-aos="fade-up"  src={card4} alt="" className="img-1" />
      <img data-aos="fade-up" src={card2} alt="" className="img-2" />
      <img data-aos="fade-up" src={card5} alt="" className="img-3" />
      <img data-aos="fade-up" src={card1} alt="" className="img-4" />
      <img data-aos="fade-up" src={card3} alt="" className="img-5" />
    </div>
  );
};

export default Partners;
