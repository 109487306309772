import React, { FC} from 'react'
import MainNavbar from '../../../components/MainNavbar'

const Header: FC<{ title: string }> = ({ title }) => {
    return (
        <div className="background-Head">
            <div className="bg-coll px-4">
                <div className="container pt-2 ">
                    <MainNavbar />
                </div>
                <div className="T-Section">
                    <div className="container px-2 titll">
                        <h1>{title}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header