import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import api from "../../api";
import { Notification } from "../../models/notification";
import LoadingSpinner from "../DashboardWidgets/LoadingSpinner/LoadingSpinner";
import Icon from "./icon";
import Menu from "./Menu";
import "./notifications-dropdown.scss";
import Toggle from "./Toggle";

const getLink = (type: string) => {
  return type === "reg_closed" || type === "24hours_before_closing"
    ? "/admin/seasons"
    : type === "results_published"
    ? "/admin/results"
    : "/admin/new_requests";
};

const NotificationsDropdown: FC = () => {
  const location = useLocation();

  const [notifs, setNotifs] = useState<Notification[] | null>(null);

  const toggleRef = useRef<HTMLDivElement>(null);

  const markAllRead = async () => {
    await api.put("/notifications/read_all");
    setNotifs([]);
  };

  const markRead = async (id: number) => {
    await api.put("/notifications/read/" + id);
    setNotifs((prevNotifs) => prevNotifs!.filter((n) => n.id !== id));
  };

  const getNotifs = async () => {
    let { data } = await api.get("/notifications/unread");
    setNotifs(data);
  };

  useEffect(() => {
    if (!notifs) getNotifs();
  }, [notifs]);

  useEffect(() => {
    getNotifs();
  }, [location]);

  return (
    <Dropdown>
      <Dropdown.Toggle ref={toggleRef} as={Toggle}>
        {notifs && notifs.length > 0 ? notifs.length : ""}
      </Dropdown.Toggle>
      <Dropdown.Menu as={Menu}>
        {!notifs ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="list d-flex flex-column">
              <div className="head d-flex">
                <span className="me-auto my-auto">الإشعارات</span>
                <div className="my-auto count">{notifs.length} جديدة</div>
              </div>
              {notifs.map(({ id, type, title, body, display_at }) => (
                <Link
                  onClick={() => {
                    markRead(id);
                    toggleRef!.current!.click();
                  }}
                  key={display_at.toString() + id}
                  to={getLink(type)}
                  className="notif-item">
                  <div className="d-flex mb-2">
                    <div>
                      <Icon type={type} />
                    </div>
                    <div className="ms-2">
                      <div className="title">{title}</div>
                      <div className="description">{body}</div>
                    </div>
                  </div>
                  <div className="from-now">{moment(display_at).fromNow()}</div>
                </Link>
              ))}
            </div>
            <div
              className="d-flex see-all"
              onClick={() => {
                markAllRead();
                toggleRef!.current!.click();
              }}>
              تحديد قراءة الكل
            </div>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationsDropdown;
