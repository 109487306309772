import React, { FC, useEffect, useState } from "react";
import api from "../../../api";
import useTranslation from "../../../i18n/hooks/useTranslation";
import RequestsTable from "./RequestsTable";
import { Season } from "../../../models/season";
import SelectSearch from "react-select-search";
import { RegistrationRequest } from "../../../models/registration-request";
import ExportCSVButton from "../../../components/ExportCSVButton";
import moment from "moment";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";

const RegistrationRequestsHistory: FC = () => {
  const t = useTranslation();

  const [seasons, setSeasons] = useState<Season[] | null>(null);
  const [activeSeason, setActiveSeason] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");

  const getSeasons = async () => {
    let { data } = await api.get("/seasons");
    setSeasons(data);
  };

  const requestUpdated = (id: number, status: any) => {
    let { registration_requests } = seasons![0];
    let index = registration_requests.findIndex((r) => r.id === id);
    registration_requests[index].status = status;

    setSeasons([{ ...seasons![0], registration_requests }, ...seasons!.slice(1)]);
  };
  const filterRequests = (reqs: RegistrationRequest[]) => {
    let newReqs;
    if (search) newReqs = reqs.filter((r) => r.school.name.includes(search));

    if (status) newReqs = reqs.filter((r) => r.status === status);

    return newReqs || reqs;
  };

  useEffect(() => {
    if (!seasons) getSeasons();
  }, [seasons]);

  if (!seasons) return <LoadingSpinner />;

  let hasEnded = moment().diff(moment(seasons[0].endDate), "minutes") >= 0;

  return (
    <div className="requests-history-page">
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-6 tw-mb-6">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-justify-between md:tw-justify-start tw-items-start md:tw-items-center tw-gap-3">
          <h1 className="tw-text-lg md:tw-text-2xl">سجل طلبات التسجيل للعام الدراسي</h1>
          <SelectSearch
            className={"SelectSec my-auto"}
            onChange={(...args: any) => setActiveSeason(parseInt(args[0]))}
            options={seasons.map((season, idx) => ({ name: season.year, value: idx }))}
            value={activeSeason.toString()}
          />
        </div>
        <div className="tw-flex">
          <div className="table-search ms-auto my-auto d-flex px-2">
            <input type="text" className="my-auto me-auto" placeholder="بحث" value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
            <svg xmlns="http://www.w3.org/2000/svg" width="19.457" height="19.457" viewBox="0 0 19.457 19.457" className="my-auto">
              <g id="Layer_2" data-name="Layer 2" opacity="0.1">
                <path
                  id="search"
                  d="M22.138,20.6,18.463,16.94a8.558,8.558,0,0,0,1.826-5.295,8.645,8.645,0,1,0-8.645,8.645,8.558,8.558,0,0,0,5.295-1.826L20.6,22.138A1.085,1.085,0,1,0,22.138,20.6ZM5.161,11.645a6.484,6.484,0,1,1,6.484,6.484,6.484,6.484,0,0,1-6.484-6.484Z"
                  transform="translate(-3 -3)"
                />
              </g>
            </svg>
          </div>
          <ExportCSVButton
            headers={["الحالة", "رقم الهاتف", "الإيميل", "المدرسة"]}
            data={seasons[activeSeason].registration_requests.map(({ school, status, phone, email }, idx) => [status, phone, email, school.name])}
            file={"سجل_طلبات_التسحيل_لعام_" + seasons[activeSeason].year}
          />
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-6">
        <div className="lg:tw-col-span-2">
          <div className="status-list">
            <div className={`status ${status === "" && "selected"}`} onClick={() => setStatus("")}>
              الكل
            </div>
            <div className={`status ${status === "accepted" && "selected"}`} onClick={() => setStatus("accepted")}>
              مقبول
            </div>
            <div className={`status ${status === "pending" && "selected"}`} onClick={() => setStatus("pending")}>
              قيد الانتظار
            </div>
            <div className={`status ${status === "refused" && "selected"}`} onClick={() => setStatus("refused")}>
              مرفوض
            </div>
          </div>
        </div>
        <div className="lg:tw-col-span-10 tw-overflow-x-scroll">
          <RequestsTable
            requests={filterRequests(seasons[activeSeason].registration_requests)}
            canEdit={activeSeason === 0 && !hasEnded}
            requestUpdated={requestUpdated}
          />
        </div>
      </div>
    </div>
  );
};

export default RegistrationRequestsHistory;
