import React, { FC } from 'react'
import HeatIcon from '../../../assets/icons/HeatIcon.png'
import HighRec from '../../../assets/icons/HighRec.png'

const Cards: FC<{ schools: number, bestMark: number }> = ({ schools, bestMark }) =>{
    return(
        <div data-aos="fade-up" className="container">
            <div className='CardsSecResult d-flex'>
                <div className='s-card ms-auto me-1 me-md-3'>
                    <div className="d-flex mb-2">
                        <img src={HighRec} alt="hight" className="ms-auto my-auto me-1 me-md-3" />
                        <h3 className="me-auto my-auto">المدارس المشاركة</h3>
                    </div>
                    <p className="p-0">{schools}</p>
                </div>
                <div className='s-card me-auto ms-1 ms-md-3'>
                    <div className="d-flex mb-2">
                        <img src={HeatIcon} alt="hight" className="ms-auto my-auto me-1 me-md-3" />
                        <h3 className="me-auto my-auto">أعلى الدرجات</h3>
                    </div>
                    <p className="p-0">{bestMark}</p>
                </div>
            </div>
        </div>
    )
}

export default Cards