import React, { FC, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import toast from 'react-hot-toast'
import Form from 'react-bootstrap/Form'
import useTranslation from '../../../i18n/hooks/useTranslation'
import api from '../../../api'
import DBInput from '../../../components/DBInput'
import { Season } from '../../../models/season'
import moment from 'moment'

interface IProps{
    setShown: (modal:string) => void
    shown: string
    season: Season
    seasonUpdated: () => void
}

const UpdateSeason: FC<IProps> = ({setShown, shown, seasonUpdated, season}) => {

    const t = useTranslation()

    const initState = {
        endDate: moment(season.endDate).locale("en").format("yyyy-MM-DD"),
        endTime: moment(season.endDate).locale("en").format("HH:mm")
    }
    const [data, setData] = useState<any>(initState);

    const handleClose = () => setShown("")

    const handleChange = (event: React.ChangeEvent<any>) => {
        event.persist()
        setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }))
    }

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        const toastId = toast.loading(t('LOADING'))
        let { endDate, endTime } = data

        const body = JSON.stringify({
            endDate: `${endDate} ${endTime}:00`
        })
        let diff = moment(`${endDate} ${endTime}:00`).diff(moment(season.endDate), 'minutes')
        if(diff <= 0)
            return toast.error(t('ERROR.ENTER_VALID_DATES'), { id: toastId })

        try {
            await api.put('/seasons/' + season.id, body)
            toast.success(t('SUCCESS.DONE'), { id: toastId })
            seasonUpdated()
        } catch (err: any) {
            toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED") , {
                id: toastId,
            })
        }
    }

    return (
        <Modal show={shown === "updateEndDate"} centered={true} className="db-modal add-season" onHide={handleClose}>
            <Form onSubmit={submitHandler}>
                <Modal.Body className="p-3">    
                    <div className="title">تمديد التسجيل</div>
                    <div className="row">
                        <div className="col-6 mb-4">
                            <label htmlFor="endDate" className="mb-2">التاريخ الجديد</label>
                            <DBInput type="date" min={2021} name="endDate" id="endDate"
                                value={data.endDate} onChange={handleChange} required />
                        </div>
                        <div className="col-6 mb-4">
                            <label htmlFor="endTime" className="mb-2">التوقيت الجديد</label>
                            <DBInput type="time" min={2021} name="endTime" id="endTime"
                               value={data.endTime} onChange={handleChange} required />
                        </div>
                    </div>   
                    
                    <div className="d-flex">
                        <button className="btn-c btn-green w-80 me-2" type="submit">تمديد التسجيل</button>
                        <button className="btn-c btn-cancel w-20" type="button" onClick={handleClose}>{t("CANCEL")}</button>
                    </div>
                </Modal.Body>
            </Form>
        </Modal>
    )
}

export default UpdateSeason