import React, { FC, useState, useEffect } from "react";
import useTranslation from "../../../i18n/hooks/useTranslation";
import MainNavbar from "../../../components/MainNavbar";
import { School } from "../../../models/school";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import "./register-school.scss";
import api from "../../../api";
import { Season } from "../../../models/season";
import SelectSchool from "./SelectSchool";
import moment from "moment";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";

const RegisterSchool: FC = () => {
  const t = useTranslation();

  const [schools, setSchools] = useState<School[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSeason, setCurrentSeason] = useState<Season | null>(null);
  const [data, setData] = useState({
    school_id: "",
    phone: "",
    coordinator: "",
    email: ""
  });

  const handleChange = (event: React.ChangeEvent<any>) => {
    event.persist();
    setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }));
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const toastId = toast.loading(t("LOADING"));
    const body = JSON.stringify({ ...data, season_id: currentSeason!.id });

    try {
      await api.post("/registration_requests", body);
      toast.success(t("MANAGE_SCHOOLS.SCHOOL_ADDED"), { id: toastId });
      setIsLoading(false);
    } catch (err: any) {
      toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
      setIsLoading(false);
    }
  };

  const getSchools = async () => {
    let { data } = await api.get("/schools");
    setSchools(data);
  };

  const getCurrentSeason = async () => {
    let { data } = await api.get("/seasons/current");
    let { season } = data;
    setCurrentSeason(season || {});
  };

  useEffect(() => {
    if (!currentSeason) getCurrentSeason();
    if (!schools) getSchools();
  }, [schools, currentSeason]);

  let endDate, startDate, hasStarted, hasEnded;

  if (currentSeason) {
    endDate = moment(currentSeason.endDate);
    startDate = moment(currentSeason.startDate);
    hasStarted = moment().diff(startDate, "minutes") >= 0;
    hasEnded = moment().diff(endDate, "minutes") >= 0;
  }

  return (
      <div className="register-school-page">
        <header>
          <div className="container pt-2 h-100">
            <MainNavbar />
            <div className="d-flex w-100 mt-5 reg-card">
              {!currentSeason ? (
                  <div className="py-5 my-5 w-100">
                    <LoadingSpinner />
                  </div>
              ) : (
                  <div className="card p-4 m-auto">
                    {currentSeason.resultsPublished ? (
                        <h3>{t("REGISTER_SCHOOL.RESULTS_ARE_PUBLISHED")}</h3>
                    ) : hasEnded ? (
                        <h3>لقد إنتهى الموسم الحالي، لا يمكنك اللتسجيل</h3>
                    ) : !hasStarted ? (
                        <div>
                          <h3>لم يبدأ التسجيل في الموسم الحالي بعد</h3>
                        </div>
                    ) : !schools ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                          <h3 className="mb-4">تسجيل المدرسة للمشاركة في المبادرة المعززة للصحة {currentSeason.year}</h3>
                          <Form onSubmit={submitHandler} className="p-0">
                            <div className="group-form mb-3">
                              <label htmlFor="school_id">المدرسة</label>
                              <SelectSchool
                                  options={schools.map((school) => ({ name: school.name, value: school.id! }))}
                                  selectedSchool={data.school_id}
                                  setData={setData}
                              />
                            </div>

                            <div className="group-form mb-3">
                              <label>إسم منسق المبادرة</label>
                              <input
                                  type="text"
                                  placeholder={"إسم منسق المبادرة"}
                                  name="coordinator"
                                  value={data.coordinator}
                                  onChange={handleChange}
                                  required
                              />
                            </div>

                            <div className="group-form mb-3">
                              <label>الإيميل</label>
                              <input
                                  type="email"
                                  placeholder={"أدخل الإيميل"}
                                  name="email"
                                  pattern=".+@moe.om"
                                  value={data.email}
                                  onChange={handleChange}
                                  required
                              />
                            </div>

                            <div className="group-form mb-3">
                              <label>{t("PHONE_NUMBER")}</label>
                              <input
                                  type="tel"
                                  placeholder={"أدخل رقم الهاتف"}
                                  name="phone"
                                  pattern="^((\+|00)?968)?[279]\d{7}$"
                                  value={data.phone}
                                  onChange={handleChange}
                                  required
                              />
                            </div>

                            <button className="btn-green" disabled={isLoading}>إرسال طلب التسجيل</button>
                          </Form>
                        </>
                    )}
                  </div>
              )}
            </div>
          </div>
        </header>
      </div>
  );
};

export default RegisterSchool;
