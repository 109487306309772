import React, { FC } from "react";
import ASection2img from "../../../assets/images/ASection2img.png";
import ASection2imgPhone from "../../../assets/images/ASection2imgPhone.png";
import longaro from "../../../assets/images/longaro.png";

const HealthPromo: FC = () => {
  return (
    <div className="tw-my-20 tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-10">
      <div className="">
        <h2 data-aos="fade-up" className="tw-mb-8">
          مفهوم <q>تعزيز الصحة</q>
        </h2>
        <p data-aos="fade-up" className="tw-mb-16">
          "ھو عملیة تمكین الناس من زیادة تحكمھم في صحتھم و محدداتھا بأسلوب یؤدي إلى تحسین صحتھم التي ینعمون بھا" وذلك من خلال توفیر السیاسات و المعارف
          والمھارات اللازمة لتعزیز الصحة ، إضافة إلى توفیر بیئة صحیة داعمة" باعتبار أن ھنالك العدید من العوامل ذات التأثیر على الصحة منھا عوامل
          اجتماعیة و اقتصادیة وسیاسیة تقع خارج المسؤولیة الفردیة ،لذا وجب تكاتف كافة القطاعات من أجل خلق بیئة داعمة لتمكین الناس من تبني نمط حیاة صحي
          لتحسین صحتھم ، وعلیھ فإن الصحة لیست مسؤولیة وزارة الصحة فقط وإنما مسؤولیة مشتركة مع مؤسسات وھیئات وجھات معنیة أخرى وكذلك الأفراد، تتضافر
          جھودھم من أجل تعزیز صحتھم.{" "}
        </p>
        <p data-aos="fade-up" className="tw-text-lg tw-opacity-40 tw-mb-2">وقد حدد میثاق أوتاوا خمس مناطق عمل أساسیة من أجل تمكین الناس من تعزیز صحتھم </p>
        <img data-aos="fade-up" src={longaro} alt="longaro" className="tw-w-3/5 lg:tw-w-full"/>
      </div>
      <div className="">
        <img data-aos="fade-up" className="tw-hidden lg:tw-block" src={ASection2img} alt="longaro" />
      </div>
      <img data-aos="fade-up" className="tw-block tw-w-4/5 sm:tw-w-1/2 tw-mx-auto lg:tw-hidden" src={ASection2imgPhone} alt="longaro" />
    </div>
  );
};

export default HealthPromo;
