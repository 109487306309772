import { FC } from "react";
import SVG from "react-inlinesvg";
import graduation from "./svg/graduation.svg";
import calendar from "./svg/calendar.svg";
import school from "./svg/school.svg";
import users from "./svg/users.svg";
import up from "./svg/trend-up.svg";
import down from "./svg/trend-down.svg";
import "./sidewidget.scss";

const icons = {
  graduation, calendar, school, users, up, down
}

const colors = {
  green: "#9095371A",
  yellow: "#FED9651A",
  grey: "#A6A6A61A",
  red: "#FF00001A"
}

interface IProps {
  large?: boolean;
  percentage?: number;
  title: string;
  value: string|number;
  icon: "graduation"|"calendar"|"school"|"users"|"up"|"down";
  color: "green"|"yellow"|"grey"|"red";
}

const SideWidget: FC<IProps> = ({ icon, title, value, color, large, percentage }) => {

  return (
    <>
      <div className={`stat-card db-shadow d-flex ${large && "large"}`}>
        <div className="icon d-flex me-3" style={{ background: colors[color], width: 70, height: 70 }}>
          <SVG src={icons[icon]} className="m-auto" />
        </div>
        <div className="h-100 d-flex flex-column justify-content-between">
          <div style={{ color: "#3E3E3E", fontSize: 18, fontWeight: 600 }}>{title}</div>
          <div className="d-flex">
            <div style={{ color: "#3E3E3E", fontSize: 28, fontWeight: 700 }}>{value}</div>
            {percentage && percentage !== 0 ?  
              <div className="my-auto ms-4" style={{ 
                color: percentage > 0 ? "#909537" : "#FF0000", fontSize: 18, fontWeight: 700 
              }}>{percentage.toPrecision(2) || percentage}%</div> : null
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default SideWidget;
