import React, { FC, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { RootState } from "../../../store";
import { forgotPassword, login } from "../../../store/actions/authActions";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Logo from "../../../assets/images/Login/logo-loginpage.svg";
import path_final from "../../../assets/images/Login/path-final.svg";

import "./login.scss";

const LoginPage: FC = () => {
  const t = useTranslation();

  const { authenticated, user } = useSelector((state: RootState) => state.auth);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const dispatch = useDispatch();

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }));
  };

  const submitLoginHandler = (e: FormEvent) => {
    e.preventDefault();
    dispatch(login(data));
  };

  const submitResetPasswordHandler = (e: FormEvent) => {
    e.preventDefault();
    dispatch(forgotPassword(data.email));
  };

  if (authenticated){
    if(user!.ministry === "EDUCATION")
      return <Navigate to="/admin/schools" />
    return <Navigate to="/admin/dashboard" />
  } 

  return (
    <div className="login-page">
      <div className="right">
        <img src={Logo} alt="" />
        {!showResetPassword ? (
          <form onSubmit={submitLoginHandler}>
            <h5 className="mb-1">{t("AUTH.LOGIN")}</h5>
            <hr />
            <div className="form-group mb-3">
              <label htmlFor="email">{t("AUTH.EMAIL")}</label>
              <input type="email" className="form-control" id="email" name="email" value={data.email} onChange={handleChange} required />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password">{t("AUTH.PASSWORD")}</label>
              <input type="password" className="form-control" id="password" name="password" value={data.password} onChange={handleChange} required />
            </div>
            <button type="submit" className="btn btn-primary">
              <span>{t("AUTH.LOGIN")}</span>
              <BsChevronLeft />
            </button>
            <a href="#!" className="reset-password" onClick={() => setShowResetPassword((s) => !s)}>
              {t("AUTH.RESET_PASSWORD")}
            </a>
          </form>
        ) : (
          <form onSubmit={submitResetPasswordHandler}>
            <div className="d-flex gap-2 align-items-center mb-1">
              <BsChevronRight />
              <h5>{t("AUTH.RESET_PASSWORD")}</h5>
            </div>
            <span className="hint">{t("AUTH.RESET_PASSWORD_HINT")}</span>
            <hr className="mb-3" />
            <div className="form-group mb-3">
              <label htmlFor="email">{t("AUTH.EMAIL")}</label>
              <input type="email" className="form-control" id="email" name="email" value={data.email} onChange={handleChange} required />
            </div>
            <button type="submit" className="btn btn-primary">
              <span>{t("AUTH.RESET_PASSWORD_CONFIRM")}</span>
              <BsChevronLeft />
            </button>
            <a href="#!" className="reset-password py-2" onClick={() => setShowResetPassword((s) => !s)}>
              {t("AUTH.GO_BACK")}
            </a>
          </form>
        )}
        {/* <p>جميع الحقوق محفوظة لمبادرة تعزيز الصحة المدرسية 2022</p> */}
      </div>
      <div className="left">
        <img src={path_final} alt="" className="path_final" />
        <p>معا من أجل مدارس معززة للصحة</p>
      </div>
    </div>
  );
};

export default LoginPage;
