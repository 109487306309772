import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./bread-crumbs.scss";

type link = {
  title: string;
  url: string;
};
interface IBreadCrumbs {
  links: link[];
  white?: boolean;
}
const BreadCrumbs: FC<IBreadCrumbs> = ({ links, white }) => {
  return (
    <div className={"bread-crumbs"}>
      {links.map((link, idx) => (
        <React.Fragment key={idx}>
          <Link to={link.url ? link.url : "#"} className="item tw-m-0 tw-whitespace-nowrap" style={{ color: white ? "white" : "black" }}>
            {link.title}
          </Link>
          {idx !== links.length - 1 && (
            <svg xmlns="http://www.w3.org/2000/svg" width="6.852" height="11.426" viewBox="0 0 6.852 11.426" className="my-auto mx-3">
              <path
                id="Path_58566"
                data-name="Path 58566"
                d="M16.983,11.5l-4.241,4.241L8.5,11.5"
                transform="matrix(-0.017, 1, -1, -0.017, 17.06, -6.793)"
                fill="none"
                stroke={white ? "#FFF" : "#000"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </svg>
          )}
        </React.Fragment>
      ))}

      <div className="item my-auto"></div>
    </div>
  );
};

export default BreadCrumbs;
