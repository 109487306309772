import React, { FC} from 'react'
import stag from '../../../assets/images/stag.png'

const Stage: FC = () => {
    
    return (
        <div data-aos="fade-up" className="container">
            <div className='Stag d-flex'>
                <img className='StagImg mx-auto' src={stag} alt="stage" />
            </div>
        </div>
        
    )
}

export default Stage