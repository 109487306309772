import React, { FC, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import useTranslation from '../../../i18n/hooks/useTranslation'
import api from '../../../api';
import DBInput from '../../../components/DBInput/DBInput';
import UploadIllustration from '../../../assets/illustrations/upload_file.svg'
import PDFIcon from '../../../assets/icons/pdf.svg'

interface IProps{
    setShown: (modal:string) => void
    shown: string
    setContent: Function
}

const AddEvalFile: FC<IProps> = ({ setShown, shown, setContent }) => {

    const t = useTranslation()

    const initState = {
        name: ""
    }

    const [data, setData] = useState(initState)
    const [file, setFile] = useState<File|null>(null)

    const handleClose = () => setShown("")

    const handleChange = (event: React.ChangeEvent<any>) => {
        event.persist()
        setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value}))
    }

    const uploadImage = (e: React.ChangeEvent<any>) =>{
        if(e.currentTarget.files)
            setFile(e.currentTarget.files[0])
    }

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        const toastId = toast.loading(t('LOADING'))

        try {
            const formData = new FormData();
            formData.append("file", file!)
            formData.append("collection", "eval_forms")
            formData.append("name", data.name)
            await api.post("/content/files", formData)
            setShown("")
            setContent(null)
            toast.success(t("SUCCESS.FILE_UPLOADED"), { id: toastId })
        } catch (err: any) {
            toast.error(t(err?.response?.data.message || 'ERROR.ERROR_OCCURED'), {
                id: toastId
            })
        }
    }

    return (
        <Modal show={shown === "add"} className="db-modal" onHide={handleClose} centered={true}>
            <Form onSubmit={submitHandler}>
                <Modal.Body className="p-3">
                    <div className="title">إضافة ملف</div>   
                    <div className="mb-3">
                        <label htmlFor="name" className="mb-2">{t("NAME")}</label>
                        <DBInput type="text" placeholder={t("NAME")} name="name" id="name"
                            value={data.name} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label className="mb-2">الملف</label>
                        <label htmlFor="file" className="upload-file d-flex">
                            {file?
                                <div className="file-details m-auto d-flex flex-column">
                                    <img src={PDFIcon} className="mx-auto mb-2" alt="pdf file" />
                                    <div className="file-name">{file.name}</div>
                                </div>
                            :
                                <img src={UploadIllustration} className="m-auto" alt="upload" />}
                        </label>
                        <input type="file" id="file" placeholder={t("THE_FILE")} className="d-none"
                            onChange={uploadImage} required />
                    </div>
                    <div className="d-flex">
                        <button className="btn-c btn-green w-80 me-2" type="submit" disabled={!file || !data.name}>{t("ADD")}</button>
                        <button className="btn-c btn-cancel w-20" type="button" onClick={handleClose}>{t("CANCEL")}</button>
                    </div>
                </Modal.Body>
            </Form>

        </Modal>
    )
}

export default AddEvalFile