import React, { FC } from "react";
import MainNavbar from "../../../components/MainNavbar";
import useTranslation from "../../../i18n/hooks/useTranslation";
import kidsphoneview from "../../../assets/images/kids-phone-view.png";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { motion } from "framer-motion";
import AnimatedTextUp from "../../../utils/AnimatedTextUp";
import Headerimage from "./Headerimage"

const Header: FC<{ season: string | undefined, lastResultsYear: string }> = ({ season, lastResultsYear }) => {
  const t = useTranslation();

  return (
    <header className="tw-h-screen md:tw-h-[700px]">
      <div className="container pt-2 px-4 tw-h-full">
        <MainNavbar />
        <div className="row tw-mt-[5%] lg:tw-mt-[10%] tw-h-full">
          <div className="col-md-6 tw-h-full">
            <div className="tw-flex tw-flex-col tw-h-full">
              <motion.h1
                initial="hidden"
                animate={"visible"}
                variants={{
                  visible: {
                    transition: {
                      staggerChildren: 0.1,
                      delayChildren: 0.2,
                    },
                  },
                }}
                className="tw-text-[2.5rem] lg:tw-text-4xl !tw-leading-[1.2] lg:tw-leading-[3.5rem]">
                <AnimatedTextUp>{t("HOME.TITLE")}</AnimatedTextUp>
              </motion.h1>
              {season && (
                <motion.div
                  initial="hidden"
                  animate={"visible"}
                  variants={{
                    visible: {
                      transition: {
                        staggerChildren: 0.1,
                        delayChildren: 1.2,
                      },
                    },
                  }}
                  className="title2 tw-text-2xl tw-font-bold tw-mb-10 lg:tw-mb-12">
                  <AnimatedTextUp>{t("HOME.CURRENT_YEAR").replace("##", season)}</AnimatedTextUp>
                </motion.div>
              )}
              
              <div className="tw-hidden lg:tw-block sub-title tw-text-[#ccb86e] tw-font-bold tw-mb-6">{t("HOME.HERO_SUB_TITLE")}</div>
              <Link to="/register_school">
                <button className="btn-trans-white tw-text-xl lg:tw-text-lg ">سجل المدرسة</button>
              </Link>
              <img src={kidsphoneview} alt="kidsphoneview" className="tw-w-[90%] sm:tw-w-[80%] md:tw-hidden  tw-mx-auto" />
              <div className="tw-flex  pt-5 tw-pb-20 tw-flex-grow tw-items-center tw-text-white tw-font-bold tw-text-md">
                {lastResultsYear && <Link to="/results" className="hover:tw-text-[#fffff2] more-btn-ani">
                  <span>المدارس الفائزة لنسخة</span>
                  <span className="tw-mr-2">{lastResultsYear}</span>
                </Link>}
                {lastResultsYear && <BsArrowLeft className=" BsArrowaLeft tw-mr-4 tw-text-2xl color-white" />}
              </div>
            </div>
          </div>
          <div className="col-md-6 d-none d-md-flex pt-4">
          <Headerimage />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
