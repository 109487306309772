import React, { FC, useEffect } from 'react';
import './public-pagination.scss'

interface IProps{
    entityName?: string;
    currentPage: number;
    totalRows: number;
    limit: number;
    setCurrentPage: Function;
}
const LEFT_PAGE = "..."
const RIGHT_PAGE = "..."
const pageNeighbours = 2

const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

const PublicPagination: FC<IProps> = ({ currentPage, totalRows, limit, setCurrentPage }) => {

    useEffect(() => {
        if(currentPage !== 1){
            let scrollElem = document.getElementById("scrollTo");
            if(scrollElem)
                scrollElem.scrollIntoView({behavior: 'smooth'});
            else
                window.scrollTo({ behavior: 'smooth', top: 0 });
        }
    }, [currentPage]);

    
    const totalPages = Math.ceil(totalRows / limit)
    
    const fetchPageNumbers = () => {
        const totalNumbers = pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;
    
        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
    
            let pages: any[] = range(startPage, endPage);
    
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = totalPages - endPage > 1;
            const spillOffset = totalNumbers - (pages.length + 1);
    
            switch (true) {
                case hasLeftSpill && !hasRightSpill: {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }
                case hasLeftSpill && hasRightSpill:
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, totalPages];
        }
        return range(1, totalPages);
    };

    const pages = fetchPageNumbers() || []

    if(currentPage < 5 && pages[1] === "..."){
        pages.splice(1,1)
    }

    return (
        <div>
            {pages.length > 0 && 
                <div className='btn-12'>
                    {pages.map((page,idx) =>
                        <p 
                            key={"page-" + idx}
                            onClick={() => setCurrentPage(page === "..." ? currentPage : page )}
                            className={`me-3 ${currentPage === idx + 1 ? "active" : ""}`}
                        >
                            {page}
                        </p>
                    )}
                </div>
            }   
        </div>
    );
}

export default PublicPagination;