import React, { FC, useState } from "react";
import Pagination from "../../../components/Pagination";
import RegistrationRequestCard from "../../../components/RegistrationRequestCard";
import { RegistrationRequest } from "../../../models/registration-request";

interface IProps {
  requests: RegistrationRequest[];
  requestHandled: Function;
  dashboardVersion? :boolean;
}

const RequestsList: FC<IProps> = ({ requests, requestHandled, dashboardVersion }) => {

  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const currentData = requests.slice(
    (currentPage - 1) * limit,
    (currentPage - 1) * limit + limit
  );
  
  return (
    <>
      <div className="tw-overflow-x-scroll tw-whitespace-nowrap">
        <div className="requests-header tw-text-sm md:tw-text-base">
          <span>المدرسة</span>
          <span>معلومات التواصل</span>
        </div>
        <div className="requests">
          {currentData.map((req, idx) => (
            <RegistrationRequestCard key={"school-" + req.id} {...req} requestHandled={requestHandled} />
          ))}
        </div>
      </div>
      {!dashboardVersion && <div className="bottom">
          <Pagination 
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            entityName="طلبات"
            setLimit={setLimit}
            totalRows={requests.length}
          />
      </div>}
    </>
  );
};

export default RequestsList;
