import React, { FC } from 'react';
import './quote-block.scss'

const QuoteBlock: FC<{text: string, className: string}> = ({ text, className }) => {
  return (
    <div className={"quote-block " + className}>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 21.858 21.084">
            <g id="Octicons" transform="translate(25.571 21.453) rotate(167)">
                <g id="quote" transform="translate(0 1.66)">
                <path id="Shape" d="M9.9,4.616c-3.9,2.507-5.8,5.093-5.8,9.416a2.225,2.225,0,0,1,.707-.08,3.832,3.832,0,0,1,4.017,3.872A3.869,3.869,0,0,1,4.8,22.017c-3.053,0-4.8-2.442-4.8-6.829C0,9.083,2.812,4.7,8.066,1.66Zm11.247,0c-3.9,2.507-5.8,5.093-5.8,9.416a2.225,2.225,0,0,1,.707-.08,3.832,3.832,0,0,1,4.017,3.872,3.869,3.869,0,0,1-4.017,4.194c-3.037,0-4.788-2.442-4.788-6.829,0-6.106,2.812-10.492,8.066-13.529l1.832,2.956Z" transform="translate(0 -1.66)" fill="#fff" fillRule="evenodd"/>
                </g>
            </g>
        </svg>
        <p>{text}</p>
        <div className="d-flex">
            <svg xmlns="http://www.w3.org/2000/svg" className="ms-auto left-quote" width="15" height="12" viewBox="0 0 21.858 21.084" style={{ marginTop: -12 }}>
                <g id="Octicons" transform="matrix(0.999, -0.035, 0.035, 0.999, -0.058, -0.92)">
                    <g id="quote" transform="translate(0 1.66)">
                    <path id="Shape" d="M9.9,4.616c-3.9,2.507-5.8,5.093-5.8,9.416a2.225,2.225,0,0,1,.707-.08,3.832,3.832,0,0,1,4.017,3.872A3.869,3.869,0,0,1,4.8,22.017c-3.053,0-4.8-2.442-4.8-6.829C0,9.083,2.812,4.7,8.066,1.66Zm11.247,0c-3.9,2.507-5.8,5.093-5.8,9.416a2.225,2.225,0,0,1,.707-.08,3.832,3.832,0,0,1,4.017,3.872,3.869,3.869,0,0,1-4.017,4.194c-3.037,0-4.788-2.442-4.788-6.829,0-6.106,2.812-10.492,8.066-13.529l1.832,2.956Z" transform="translate(0 -1.66)" fill="#fff" fillRule="evenodd"/>
                    </g>
                </g>
            </svg>
        </div>
    </div>
  );
}

export default QuoteBlock;
