import React, { FC, useEffect } from "react";
import ApexCharts from "apexcharts";
import "./registeredschoolschart.scss";
import { Season } from "../../../models/season";

interface IProps {
  baseColor: string;
  lightColor: string;
  data: (Season & { registration_requests_count: number; year: string })[] | undefined;
}

const RegisteredSchoolsChart: FC<IProps> = ({ baseColor, lightColor, data }) => {

  useEffect(() => {
    if (data) {
      const element = document.getElementById("kt_stats_widget_registered_schools_chart");

      if (!element) {
        return;
      }

      const options = getChartOption(data, baseColor, lightColor);
      const chartnewUsers = new ApexCharts(element, options);
      chartnewUsers.render();
      return function cleanUp() {
        chartnewUsers.destroy();
      };
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className={`card card-custom db-shadow`}>
      <div className="card-body p-1 pt-4">
        <div id="kt_stats_widget_registered_schools_chart" className="card-rounded-bottom" style={{ height: "350px" }}></div>
      </div>
    </div>
  );
};

function getChartOption(data: (Season & { registration_requests_count: number; year: string })[], baseColor: string, lightColor: string) {
  var options = {
    series: [
      {
        name: "عدد المدارس",
        data: data.map((item) => item.registration_requests_count),
      },
    ],
    grid: {
      padding: {
        left: 40,
        right: 40, // Also you may want to increase this (based on the length of your labels)
      },
    },
    chart: {
      type: "area",
      height: 300,
      events: {
        mounted: (chart:any) => {
          chart.windowResizeHandler();
        }
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}: any) {
        return `
          <div class="chart-tooltip">
            <div class="title mb-2">المدارس المشاركة</div>
            <div class="count" style="color: ${baseColor}">${series[seriesIndex][dataPointIndex]}</div>
          </div>
        `
      }
    },
    fill: {
      // type: "solid",
      // opacity: 1,
      type: "gradient",
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.55,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.6,
        opacityTo: 0,
        // stops: [25, 50, 100],
        stops: [0, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      // colors: [layoutProps.colorsThemeBasePrimary],
      colors: [baseColor],
    },
    xaxis: {
      categories: data.map((item) => item.year.split("-")[1]),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        rotate: 80,
        style: {
          // colors: layoutProps.colorsGrayGray500,
          colors: "#C0BDCC",
          fontSize: ".9rem",
          fontWeight: 500,
          fontFamily: "Cairo",
        },
        offsetY: -15,
        // formatter: function (val: number) {
        //   return t("SEASON") + " " + val;
        // },
      },
      crosshairs: {
        show: true,
        position: "front",
        stroke: {
          color: lightColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: "Cairo",
        },
      },
    },
    yaxis: {
      min: -1,
      max: Math.max(...data.map((item) => item.registration_requests_count)) + 1,
      labels: {
        show: true,
        style: {
          colors: lightColor,
          fontSize: "12px",
          fontFamily: "Cairo",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    colors: [baseColor],
    markers: {
      colors: [baseColor],
      strokeWidth: 1
    },
  };
  return options;
}

export default RegisteredSchoolsChart;
