import React, { FC } from "react";
import { RegistrationRequest } from "../../models/registration-request";
import Eye from "./eye.svg";
import "./registration-request.scss";
import useTranslation from "../../i18n/hooks/useTranslation";
import toast from "react-hot-toast";
import api from "../../api";
import { MdOutlineLocationOn, MdMale, MdFemale } from "react-icons/md";
import SchoolImage from "../SchoolImage";
import { Link } from "react-router-dom";

const RegistrationRequestCard: FC<RegistrationRequest & { requestHandled: Function }> = ({ school, phone, email, id, requestHandled }) => {
  const t = useTranslation();

  let { name, gender, governorate, city } = school;

  const changeStatus = async (status: string) => {
    const toastId = toast.loading(t("LOADING"));
    const body = JSON.stringify({ status });

    try {
      let { data } = await api.put("/registration_requests/status/" + id, body);
      toast.success(t(data.message), { id: toastId });
      requestHandled(id);
    } catch (err: any) {
      toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
    }
  };

  const accept = () => changeStatus("accepted");
  const refuse = () => changeStatus("refused");

  return (
    <div className="registration-request-card mb-4">
      <SchoolImage style={{ width: 70, height: 70 }} gender={school.gender} />
      <div className="details d-flex flex-column ms-2">
        <h3 className="mb-2 mt-auto">{name}</h3>
        <p className="mb-2">
          <MdOutlineLocationOn style={{ color: "#adadad", fontSize: "1.3rem" }} />
          {governorate} - {city}
        </p>
        <p className="mb-2">
          {gender === "ذكور" ? (
            <MdMale style={{ color: "#adadad", fontSize: "1.3rem" }} />
          ) : (
            <MdFemale style={{ color: "#adadad", fontSize: "1.3rem" }} />
          )}
          {gender}
        </p>
      </div>
      <div className="contact-info">
        {/* <span>محمد بن خالد الوهيبي</span> */}
        <span className="ltr">{phone}</span>
        <span>{email}</span>
      </div>
      <div className="buttons ms-auto d-flex align-items-center gap-4">
        <div className="">
          <button className="btn-db btn-confirm me-2" onClick={accept}>
            تأكيد التسجيل
          </button>
          <button className="btn-db btn-deny" onClick={refuse}>
            رفض التسجيل
          </button>
        </div>
        <Link to={"/admin/schools/" + school.id} className="ms-auto" target="_blank">
          <img src={Eye} alt="Eye" className="eye" />
        </Link>
      </div>
    </div>
  );
};

export default RegistrationRequestCard;
