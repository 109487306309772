import React, { useLayoutEffect, useRef } from "react";
import "./tabs.css";
import { Link } from "react-scroll";

export function Tabsection() {
  return (
    <div className="header">
      <div className="Dcontainer">
        <div className="navContainer">
          <nav>
            <Link className="selector" activeClass="active" spy={true} duration={1000} to="section1">
              <div className="num">1</div>
              <p>العصف الذهني</p>
            </Link>
            <Link className="selector" activeClass="active" spy={true} duration={1000} smooth={true} to="section2">
              <div className="num">2</div>
              <p>عمل المجموعات</p>
            </Link>
            <Link className="selector" activeClass="active" spy={true} duration={1000} smooth={true} to="section3">
              <div className="num">3</div>
              <p>القاء المحاضرات</p>
            </Link>
            <Link className="selector" activeClass="active" spy={true} duration={1000} smooth={true} to="section4">
              <div className="num">4</div>
              <p>المناقشات الجماعية</p>
            </Link>
            <Link className="selector" activeClass="active" spy={true} duration={1000} smooth={true} to="section5">
              <div className="num">5</div>
              <p>لعبة الدور</p>
            </Link>
            <Link className="selector" activeClass="active" spy={true} duration={1000} smooth={true} to="section6">
              <div className="num">6</div>
              <p>دراسة الحالة</p>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

export function MTabsection() {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const mcontainerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    // Make the nav sticky
    const observer = new IntersectionObserver(([e]) => e.target.toggleAttribute("stuck", e.intersectionRatio < 1), { threshold: [1] });
    if (headerRef.current) observer.observe(headerRef.current);

    // Scroll sticky bar to active item
    const observer2 = new MutationObserver((elementsChanged) => {
      for (const e of elementsChanged) {
        let elem = e.target as HTMLElement;
        if (elem.classList.contains("Mactive") && mcontainerRef.current) {
          let pos = elem.getBoundingClientRect().left;
          let currentscroll = mcontainerRef.current.scrollLeft;
          let divwidth = mcontainerRef.current.getBoundingClientRect().width;
          pos = pos + currentscroll - divwidth / 2;
          mcontainerRef.current.scrollTo({ left: pos, behavior: "smooth" });
        }
      }
    });

    if (mcontainerRef.current) {
      let links = mcontainerRef.current.querySelectorAll("nav .Mselector");
      for (const link of links) {
        observer2.observe(link, {
          attributes: true,
          attributeFilter: ["class"],
          childList: false,
          characterData: false,
        });
      }
    }
  }, []);

  return (
    <div ref={headerRef} className="header md:tw-hidden ">
      <div ref={mcontainerRef} id="Mcontainer">
        <div className="MnavContainer">
          <nav>
            <Link className="Mselector" activeClass="Mactive" offset={-100} spy={true} duration={1000} smooth={true} to="Msection1">
              <p>العصف الذهني</p>
            </Link>
            <Link className="Mselector" activeClass="Mactive" offset={-100} spy={true} duration={1000} smooth={true} to="Msection2">
              <p>عمل المجموعات</p>
            </Link>
            <Link className="Mselector" activeClass="Mactive" offset={-100} spy={true} duration={1000} smooth={true} to="Msection3">
              <p>القاء المحاضرات</p>
            </Link>
            <Link className="Mselector" activeClass="Mactive" offset={-100} spy={true} duration={1000} smooth={true} to="Msection4">
              <p>المناقشات الجماعية</p>
            </Link>
            <Link className="Mselector" activeClass="Mactive" offset={-100} spy={true} duration={1000} smooth={true} to="Msection5">
              <p>لعبة الدور</p>
            </Link>
            <Link className="Mselector" activeClass="Mactive" offset={-100} spy={true} duration={1000} smooth={true} to="Msection6">
              <p>دراسة الحالة</p>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}
