import React, { FC } from "react";
import useTranslation from "../../../i18n/hooks/useTranslation";

const Results: FC = () => {
  const t = useTranslation();
  return (
    <div data-aos="fade-up" className="results w-100 mb-5">
      <h1 data-aos="fade-up" className="mb-5">{t("ABOUT.EXPECTED_RESULTS")}</h1>
      <ol>
        <li data-aos="fade-up" className="tw-flex gap-3">
          <span>1-</span>
          {t("ABOUT.RESULTS.STUDENTS_BETTER_HEALTH")}
        </li>
        <li data-aos="fade-up" className="tw-flex gap-3">
          <span>2-</span>
          {t("ABOUT.RESULTS.LESS_ABSENCES")}
        </li>
        <li data-aos="fade-up" className="tw-flex gap-3">
          <span>3-</span>
          {t("ABOUT.RESULTS.MORE_CHANCES")}
        </li>
        <li data-aos="fade-up" className="tw-flex gap-3">
          <span>4-</span>
          {t("ABOUT.RESULTS.BETTER_MENTAL_HEALTH")}
        </li>
        <li data-aos="fade-up" className="tw-flex gap-3">
          <span>5-</span>
          {t("ABOUT.RESULTS.SOCIETY_BENIFITS")}
        </li>
        <li data-aos="fade-up" className="tw-flex gap-3">
          <span>6-</span>
          {t("ABOUT.RESULTS.BETTER_HEALTH_GENERATION")}
        </li>
      </ol>
    </div>
  );
};

export default Results;
