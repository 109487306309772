import React, { FC, useState } from "react";
import { Media } from "../../../models/media";
import ImageFileCard from "../../../components/ImageFileCard";
import ConfirmDelete from "../../../components/ConfirmDelete";

interface IProps {
  gallery: Media[];
  uploadFile: (e: React.FormEvent<HTMLInputElement>) => void;
  deleteFile: Function;
}

const Images: FC<IProps> = ({ uploadFile, gallery, deleteFile }) => {
  const [shownModal, setShownModal] = useState("");
  const [idToDelete, setIdToDelete] = useState<number | string>("");

  const openDeleteFile = async (id: number | string) => {
    setIdToDelete(id);
    setShownModal("delete");
  };

  return (
    <>
      {shownModal === "delete" && (
        <ConfirmDelete setShown={setShownModal} shown={shownModal} handleDelete={() => deleteFile(idToDelete, "gallery")} />
      )}
      <div className="db-card mb-4">
        <div className="d-flex mb-4">
          <h4 className="me-auto my-auto">معرض الصور</h4>
          <label htmlFor="image" className="btn-green-o my-auto tw-text-xs tw-pt-2.5 md:tw-py-[5px] md:tw-text-base">
            إضافة صورة
          </label>
          <input className="d-none" type="file" name="image" id="image" accept="image/*" onChange={uploadFile} />
        </div>
        <div className="images mb-4 d-flex flex-wrap tw-justify-center md:tw-justify-start">
          {gallery.map((img, idx) => (
            <ImageFileCard key={img.uuid} {...img} idx={idx} openDeleteFile={openDeleteFile} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Images;
