import moment from "moment";
import React, { FC } from "react";
import { RegistrationRequest } from "../../../models/registration-request";
import { Season } from "../../../models/season";

interface IProps {
  season: Season;
  requests: RegistrationRequest[];
  validValues: boolean;
}

const Sidebar: FC<IProps> = ({ season, requests, validValues }) => {
  let step = season.resultsPublished ? "published" : validValues ? "check" : "rate";

  return (
    <div className="results-sidebar mt-2">
      <div className={`step d-flex ${step === "rate" && "active"}`}>
        <div className="number me-4 my-auto">1</div>
        <div className="my-auto">
          <div className="title">في إنتظار التقييم</div>
          {step === "rate" && (
            <div className="mt-2 fz-18" style={{ fontWeight: 600 }}>
              {requests.length}
              <span className="mx-1">/</span>
              <span style={{ color: "#3E3E3E" }}>{requests.filter((r) => r.score! > 0).length}</span>
            </div>
          )}
        </div>
      </div>
      <div className={`step d-flex ${step === "check" && "active"}`}>
        <div className="number my-auto me-4">2</div>
        <div className={`title my-auto ${step === "check" && "py-3"}`}>مراجعة النتائج</div>
      </div>
      <div className={`step d-flex ${step === "published" && "active"}`}>
        <div className="number me-4">3</div>
        <div className="title">نشر النتائج</div>
      </div>

      {step === "published" && (
        <div className="step active">
          <div className="fz-14 ms-2 mb-1" style={{ color: "black", fontWeight: 600 }}>
            تم النشر بتاريخ{" "}
            <span className="ms-1" style={{ color: "#B5B5B5" }}>
              {moment(season.resultsPublishedAt).locale("en").format("DD/MM/YYYY")}
            </span>
          </div>
          <div className="fz-14 ms-2 mb-1" style={{ color: "black", fontWeight: 600 }}>
            التوقيت :{" "}
            <span className="ms-1" style={{ color: "#B5B5B5" }}>
              {moment(season.resultsPublishedAt).locale("en").format("HH:mm")}
            </span>
          </div>
          <div className="fz-14 ms-2" style={{ color: "black", fontWeight: 600 }}>
            بواسطة{" "}
            <span className="ms-1" style={{ color: "#B5B5B5" }}>
              {season.user?.name}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
