import React, { FC } from 'react'
import Modal from 'react-bootstrap/Modal'
import useTranslation from '../../i18n/hooks/useTranslation'

interface IProps{
    setShown: (modal:string) => void
    shown: string
    handleDelete: any
}


const ConfirmDelete: FC<IProps> = ({ shown, setShown, handleDelete }) => {

    const t = useTranslation()

    const handleClose = () => setShown("")

    return (
        <Modal show={shown === "delete"} onHide={handleClose} className="db-modal confirm-modal" centered={true}>
            <Modal.Body>
                <div className="title">{t("ATTENTION")}</div>
                <div className="description">{t("ARE_YOU_SURE_TO_DELETE")}</div>
                <div className="d-flex mt-4">
                    <button className="btn-c btn-green w-50 me-2" onClick={handleDelete} >{t("CONFIRM")}</button>
                    <button className="btn-c btn-cancel w-50" onClick={handleClose}>{t("CANCEL")}</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ConfirmDelete;
