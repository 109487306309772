import i18n from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'
import en from '../assets/locales/en.json'
import ar from '../assets/locales/ar.json'

const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar
  },
}

i18n.use(LngDetector).init({
  resources,
  fallbackLng: 'ar',
  debug: false,
  supportedLngs: ['ar'],
  load: 'languageOnly',
  returnEmptyString: false
})

export default i18n
