import React, { FC } from "react";
import Modal from "react-bootstrap/Modal";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { RegistrationRequest } from "../../../models/registration-request";
import "./school-profile.scss";

interface IProps {
  setShown: (modal: boolean) => void;
  shown: boolean;
  info: RegistrationRequest[];
}

const PreviousInfoModal: FC<IProps> = ({ shown, setShown, info }) => {
  const t = useTranslation();

  const handleClose = () => setShown(false);

  return (
    <Modal show={shown} onHide={handleClose} className="db-modal prev-info" dialogClassName="tw-max-w-max" contentClassName="tw-py-2" centered={true}>
      <Modal.Body>
        <div className="text-green tw-font-bold tw-mb-6">{t("MANAGE_SCHOOLS.PREVIOUS_CONTACT_INFO")}</div>
        <div className="tw-overflow-x-scroll lg:tw-overflow-auto">
          <table className="db-table admins-table">
            <thead>
              <tr>
                <th scope="col">السنة</th>
                <th scope="col">الإسم</th>
                <th scope="col">البريد الإلكتروني</th>
                <th scope="col">رقم الهاتف</th>
              </tr>
            </thead>
            <tbody>
              {info.map(req =>
                <tr className="table-tr-border" key={"info-row-" + req.id}>
                  <td>{req.season?.year}</td>
                  <td>{req.coordinator}</td>
                  <td>{req.email}</td>
                  <td className="ltr">{req.phone}</td>
                </tr>
              )}
              
            </tbody>
          </table>
        </div>
        <div className="d-flex mt-4 tw-justify-center">
          <button className="btn-c btn-cancel w-50" onClick={handleClose}>
            {t("CLOSE")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PreviousInfoModal;
