import React, { FC} from 'react'
import i18n from '../../i18n';

const RTLTheme = React.lazy(() => import('./RTLTheme'));
const LTRTheme = React.lazy(() => import('./LTRTheme'));

const ThemeSeletor: FC<any> = ({children}) => {
    const lang = i18n.language
    return (
        <>
            <React.Suspense fallback={<></>}>
                {lang === "ar" ? <RTLTheme /> : <LTRTheme />}
            </React.Suspense>
            {children}
        </>
    )
}

export default ThemeSeletor