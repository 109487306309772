import React, { FC, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import useTranslation from "../../i18n/hooks/useTranslation";
import SVG from "react-inlinesvg";
import long_arrow_left from "./long_arrow_left.svg";

import "./footer.scss";
import api from "../../api";
import "../../assets/animation/btn-anim.scss"

const Footer: FC = () => {
  const t = useTranslation();

  const [data, setData] = useState<{ count: number; governorates: number } | undefined>();

  const getSchoolsCount = async () => {
    let { data } = await api.get("/schools/count");
    setData(data);
  };

  useEffect(() => {
    if (!data) getSchoolsCount();
  }, [data]);

  return (
    <footer className="lg:tw-px-10 tw-pt-12 lg:tw-pt-0">
      <div className="FooterCardBackm tw-hidden tw-h-[200px] tw-justify-between lg:tw-flex tw-flex-row tw-max-w-6xl tw-mx-auto -tw-translate-y-1/2 tw-bg-[#909537] tw-rounded-3xl tw-py-8 tw-px-10">
        <div className="tw-flex tw-flex-col">
          <h1 className="title tw-text-3xl tw-mb-8 tw-max-w-[250px]">{t("FOOTER.CARD_TITLE")}</h1>
          <div className="tw-flex-1 tw-flex tw-flex-col fotr-mor-btn tw-justify-end">
            <a className="tw-text-[#FED965] hover:tw-text-white tw-text-xl tw-font-bold tw-flex tw-items-center tw-gap-3" href="/about">
              {t("FOOTER.CARD_LINK")}
              <SVG src={long_arrow_left} width={65} height={14} className="tw-fill-[#FED965] fotr-mor-arrow" />
            </a>
          </div>
        </div>
        <div className="tw-grid tw-grid-cols-2 tw-grid-rows-3 tw-items-baseline tw-text-left tw-gap-x-3">
          <p className="tw-text-[2.5em] tw-leading-[1em] tw-text-left">11</p>
          <h4 className="tw-text-white tw-w-full">{t("FOOTER.CARD_LOCATIONS")}</h4>
          <p className="tw-text-[2.5em] tw-leading-[1em] tw-text-right">+ 1200</p>
          <h4 className="tw-text-white tw-w-full">{t("FOOTER.CARD_SCHOOLS")}</h4>
          <p className="tw-text-[2.5em] tw-leading-[1em] tw-text-left">3</p>
          <h4 className="tw-text-white tw-w-full">{t("FOOTER.CARD_WINNER_SCHOOLS")}</h4>
        </div>
      </div>

      <div className="FooterCardBackm lg:tw-hidden -tw-translate-y-1/2 tw-flex tw-flex-col tw-bg-[#909537] tw-rounded-3xl tw-py-8 tw-mx-10 tw-px-4">
        <h1 className="tw-text-3xl tw-text-center tw-mb-5 tw-px-5">{t("FOOTER.CARD_TITLE")}</h1>
        <div className="tw-flex tw-justify-between tw-gap-3 tw-mb-12">
          <Col className="FooterCardmContainCol">
            <p className="tw-text-[2em]">11</p>
            <h4 className="tw-text-white">{t("FOOTER.CARD_LOCATIONS")}</h4>
          </Col>
          <Col className="FooterCardmContainCol">
            <p className="tw-text-[2em]">+1200</p>
            <h4 className="tw-text-white">{t("FOOTER.CARD_SCHOOLS")}</h4>
          </Col>

          <Col className="FooterCardmContainCol">
            <p className="tw-text-[2em]">3</p>
            <h4 className="tw-text-white">{t("FOOTER.CARD_WINNER_SCHOOLS")}</h4>
          </Col>
        </div>
        <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-end">
          <a className="tw-text-white tw-text-lg tw-font-bold tw-flex tw-items-center tw-gap-3" href="/about">
            {t("FOOTER.CARD_LINK")}
            <SVG src={long_arrow_left} width={40} height={14} className="tw-fill-white" />
          </a>
        </div>
      </div>

      <div className="AMFooter container tw-grid tw-gap-y-14 tw-grid-cols-2 grid-flow-col-dense lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-px-8 md:tw-px-12 lg:tw-px-0">
        <div className="FoSection">
          <h3>{t("FOOTER.THE_INTIATIVE")}</h3>
          <ul>
            <li>
              <a href="/">{t("FOOTER.HOME")}</a>
            </li>
            <li>
              <a href="/about">{t("FOOTER.ABOUT_THE_INTIATIVE")}</a>
            </li>
            <li>
              <a href="/login">{t("FOOTER.DASHBOARD")}</a>
            </li>
          </ul>
        </div>
        <div className="FoSection">
          <h3>{t("FOOTER.SCHOOL_INFORMATION")}</h3>
          <ul>
            <li>
              <a href="/news">{t("FOOTER.SCHOOL_DIRECTORY")}</a>
            </li>
            <li>
              <a href="/register_school">{t("FOOTER.SCHOOL_REGISTERITION")}</a>
            </li>
            <li>
              <a href="/participating_schools">{t("FOOTER.REGISTERED_SCHOOLS")}</a>
            </li>
            <li>
              <a href="/evaluation_methods">{t("FOOTER.EVAL_METHODS")}</a>
            </li>
            <li>
              <a href="/results">{t("FOOTER.RESULTS")}</a>
            </li>
          </ul>
        </div>
        <div className="FoSection tw-col-span-2 md:tw-col-span-1">
          <h3>{t("FOOTER.RELATED_SITES")}</h3>
          <ul>
            <li>
              <a href="https://home.moe.gov.om/?GetLang=en">{t("FOOTER.MINISTRY_OF_EDUCATION")}</a>
            </li>
            <li>
              <a href="https://www.moh.gov.om/en/home">{t("FOOTER.MINISTRY_OF_HEALTH")}</a>
            </li>
            <li>
              <a href="http://www.aljisrfoundation.org/en/">{t("FOOTER.JISR")}</a>
            </li>
            <li>
              <a href="https://www.emro.who.int/index.html">{t("FOOTER.WHO")}</a>
            </li>
          </ul>
        </div>
        <div className="FoSection tw-mx-auto md:tw-mx-0 xl:-tw-translate-y-6 md:tw-ml-auto">
          <div className="contactinfo">
            <h3 className="RCINFO tw-mb-6"> {t("FOOTER.CONTACT")}</h3>
            <p>24946353</p>
            <p>24946352</p>
            <p className="tw-mt-6 tw-text-sm tw-mr-6">suh@moh.gov.om</p>
          </div>
        </div>
      </div>
      <hr className="container tw-opacity-10 tw-my-8" />
      <div className="container tw-flex tw-flex-col md:tw-flex-row tw-text-xs sm:tw-text-base tw-gap-2 sm:tw-gap-0 tw-opacity-40 tw-pb-8 tw-items-center tw-justify-between">
        <div className="tw-flex tw-gap-2">
          <span>جميع الحقوق محفوظة لدائرة الصحة المدرسية والجامعية</span>
          <span className="tw-font-bold">2022</span>
        </div>
        <div className="tw-flex tw-gap-2">
        </div>
      </div>
    </footer>
  );
};

export default Footer;
