import React, { FC } from "react";
import { Media } from "../../../models/media";
import FileCard from "../../../components/FileCard";

interface IProps {
  forms: Media[];
  setShownModal: Function;
  deleteFile: Function;
}

const EvalFiles: FC<IProps> = ({ setShownModal, forms, deleteFile }) => {
  return (
    <div className="db-card mb-4">
      <div className="d-flex mb-4">
        <h4 className="me-auto my-auto">المرفقات و ملفات التقييم</h4>
        <button className="btn-green-o my-auto tw-text-xs md:tw-text-base" onClick={() => setShownModal("add")}>
          إضافة ملف
        </button>
      </div>
      <div className="images mb-4 d-flex flex-wrap">
        {forms.map((file, idx) => (
          <FileCard {...file} key={file.uuid} idx={idx} deleteFile={deleteFile} />
        ))}
      </div>
    </div>
  );
};

export default EvalFiles;
