import React, { FC } from 'react';

const Icon: FC<{icon: string, active?: boolean}> = ({ icon, active }) => {
    switch (icon) {
        case "dashboard":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="line-chart-dots" transform="translate(-232 -2148)">
              <rect id="Frame" width="24" height="24" transform="translate(232 2148)" fill={active ? "#909537" :"#a6a6a6"} opacity="0"/>
              <path id="line-chart-dots-2" data-name="line-chart-dots" d="M255.02,2157a2,2,0,0,1-2.794,1.835l-3.372,3.371a2,2,0,1,1-3.685.023l-3.383-3.382a1.993,1.993,0,0,1-1.56-.012l-3.372,3.371A2,2,0,1,1,235.01,2161h.01a1.988,1.988,0,0,1,.766.153l3.384-3.384a2,2,0,0,1,1.84-2.769h.01a2,2,0,0,1,1.834,2.794l3.369,3.368a1.981,1.981,0,0,1,.787-.162h.01a1.988,1.988,0,0,1,.766.153l3.384-3.384a2,2,0,0,1,1.84-2.769h.01A2,2,0,0,1,255.02,2157Z" fill={active ? "#909537" :"#a6a6a6"}/>
            </g>
          </svg> 
        case "emails":
          return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g id="envelope" transform="translate(-2080 -180)">
            <rect id="Frame" width="24" height="24" transform="translate(2080 180)" fill={active ? "#909537" :"#a6a6a6"} opacity="0"/>
            <path id="envelope-2" data-name="envelope" d="M2098,200.75h-12a3.383,3.383,0,0,1-3.75-3.75v-9a3.383,3.383,0,0,1,3.75-3.75h12a3.383,3.383,0,0,1,3.75,3.75v9A3.383,3.383,0,0,1,2098,200.75Zm-12-15c-1.577,0-2.25.673-2.25,2.25v9c0,1.577.673,2.25,2.25,2.25h12c1.577,0,2.25-.673,2.25-2.25v-9c0-1.577-.673-2.25-2.25-2.25Zm7.029,7.429,4.912-3.573a.749.749,0,1,0-.882-1.212l-4.913,3.572a.248.248,0,0,1-.292,0l-4.913-3.572a.749.749,0,1,0-.882,1.212l4.912,3.573a1.748,1.748,0,0,0,2.058,0Z" fill={active ? "#909537" :"#a6a6a6"}/>
          </g>
        </svg>         
        case "new_reqs":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="folder-plus" transform="translate(-3228 -404)">
              <rect id="Frame" width="24" height="24" transform="translate(3228 404)" fill={active ? "#909537" :"#a6a6a6"} opacity="0"/>
              <path id="folder-plus-2" data-name="folder-plus" d="M3246,410.25h-4.689l-2.781-2.78a.747.747,0,0,0-.53-.22h-4a3.383,3.383,0,0,0-3.75,3.75v10a3.383,3.383,0,0,0,3.75,3.75h12a3.383,3.383,0,0,0,3.75-3.75v-7A3.383,3.383,0,0,0,3246,410.25Zm2.25,10.75c0,1.577-.673,2.25-2.25,2.25h-12c-1.577,0-2.25-.673-2.25-2.25V411c0-1.577.673-2.25,2.25-2.25h3.689l2.781,2.78a.747.747,0,0,0,.53.22h5c1.577,0,2.25.673,2.25,2.25Zm-5-3.5a.75.75,0,0,1-.75.75h-1.75V420a.75.75,0,0,1-1.5,0v-1.75h-1.75a.75.75,0,0,1,0-1.5h1.75V415a.75.75,0,0,1,1.5,0v1.75h1.75A.75.75,0,0,1,3243.25,417.5Z" fill={active ? "#909537" :"#a6a6a6"}/>
            </g>
          </svg>

        case "reqs_history":
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="folder-open" transform="translate(-3620 -460)">
              <rect id="Frame" width="24" height="24" transform="translate(3620 460)" fill={active ? "#909537" :"#a6a6a6"} opacity="0"/>
              <path id="folder-open-2" data-name="folder-open" d="M3642.439,471.4a2.226,2.226,0,0,0-1.94-1.11h-.749V470a3.383,3.383,0,0,0-3.75-3.75h-5.689l-1.781-1.78a.747.747,0,0,0-.53-.22h-3a3.383,3.383,0,0,0-3.75,3.75v10.5l0,.012a2.247,2.247,0,0,0,2.25,2.242h12.781a3.758,3.758,0,0,0,3.278-1.92h0l2.9-5.2A2.222,2.222,0,0,0,3642.439,471.4ZM3625,465.75h2.689l1.781,1.78a.747.747,0,0,0,.53.22h6c1.577,0,2.25.673,2.25,2.25v.292l-10.529-.006h0a3.755,3.755,0,0,0-3.279,1.922l-1.689,3.03V468C3622.75,466.423,3623.423,465.75,3625,465.75Zm16.153,7.148-2.9,5.2h0a2.251,2.251,0,0,1-1.967,1.152H3623.5a.739.739,0,0,1-.648-.369.729.729,0,0,1-.007-.736l2.9-5.207a2.251,2.251,0,0,1,1.968-1.152h0l12.779.007a.743.743,0,0,1,.654,1.105Z" fill={active ? "#909537" :"#a6a6a6"}/>
            </g>
          </svg>

        case "seasons":
            return <svg id="grid-web-7" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect id="Frame" width="24" height="24" fill={active ? "#909537" :"#a6a6a6"} opacity="0"/>
            <path id="grid-web-7-2" data-name="grid-web-7" d="M1444.5,3681.75h-3a2.067,2.067,0,0,1-2.25-2.25v-5a2.067,2.067,0,0,1,2.25-2.25h3a2.067,2.067,0,0,1,2.25,2.25v5A2.067,2.067,0,0,1,1444.5,3681.75Zm-3-8c-.589,0-.75.161-.75.75v5c0,.589.161.75.75.75h3c.589,0,.75-.161.75-.75v-5c0-.589-.161-.75-.75-.75Zm3,16h-3a2.067,2.067,0,0,1-2.25-2.25v-1a2.067,2.067,0,0,1,2.25-2.25h3a2.067,2.067,0,0,1,2.25,2.25v1A2.067,2.067,0,0,1,1444.5,3689.75Zm-3-4c-.589,0-.75.161-.75.75v1c0,.589.161.75.75.75h3c.589,0,.75-.161.75-.75v-1c0-.589-.161-.75-.75-.75Zm13,4h-3a2.067,2.067,0,0,1-2.25-2.25v-5a2.067,2.067,0,0,1,2.25-2.25h3a2.067,2.067,0,0,1,2.25,2.25v5A2.067,2.067,0,0,1,1454.5,3689.75Zm-3-8c-.589,0-.75.161-.75.75v5c0,.589.161.75.75.75h3c.589,0,.75-.161.75-.75v-5c0-.589-.161-.75-.75-.75Zm3-4h-3a2.067,2.067,0,0,1-2.25-2.25v-1a2.067,2.067,0,0,1,2.25-2.25h3a2.067,2.067,0,0,1,2.25,2.25v1A2.067,2.067,0,0,1,1454.5,3677.75Zm-3-4c-.589,0-.75.161-.75.75v1c0,.589.161.75.75.75h3c.589,0,.75-.161.75-.75v-1c0-.589-.161-.75-.75-.75Z" transform="translate(-1436 -3669)" fill={active ? "#909537" :"#a6a6a6"}/>
          </svg>          
        case "content":
            return <svg id="image-gallery-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect id="Frame" width="24" height="24" fill={active ? "#909537" :"#a6a6a6"} opacity="0"/>
            <path id="image-gallery-plus-2" data-name="image-gallery-plus" d="M1570.175,2697.015a3.451,3.451,0,0,0-2.388-1.23l-10.56-1.481a3.087,3.087,0,0,0-3.859,2.907l-.147,1.037h-.554a3.082,3.082,0,0,0-3.417,3.417v8.668a3.082,3.082,0,0,0,3.417,3.417h10.668c1.909,0,3.072-.914,3.35-2.59a3.014,3.014,0,0,0,2.811-2.937l1.2-8.58A3.427,3.427,0,0,0,1570.175,2697.015Zm-4.922,13.318c0,1.362-.556,1.917-1.918,1.917h-10.668c-1.361,0-1.917-.555-1.917-1.917v-8.668c0-1.362.556-1.917,1.917-1.917h10.668c1.362,0,1.918.555,1.918,1.917Zm3.957-10.9-1.2,8.578c-.146,1.007-.524,1.514-1.258,1.638v-7.985a3.082,3.082,0,0,0-3.418-3.417h-8.6l.118-.826a1.627,1.627,0,0,1,2.164-1.632l10.561,1.48a2.048,2.048,0,0,1,1.4.647A2.028,2.028,0,0,1,1569.21,2699.434Zm-7.96,6.566a.75.75,0,0,1-.75.75h-1.75v1.75a.75.75,0,0,1-1.5,0v-1.75h-1.75a.75.75,0,0,1,0-1.5h1.75v-1.75a.75.75,0,0,1,1.5,0v1.75h1.75A.75.75,0,0,1,1561.25,2706Z" transform="translate(-1548 -2692)" fill={active ? "#909537" :"#a6a6a6"}/>
          </svg>
          
        case "results":
            return <svg width="24" height="24" viewBox="0 0 24 24">
            <g id="certificate" transform="translate(-3172 -1492)">
              <rect id="Frame" width="24" height="24" transform="translate(3172 1492)" fill={active ? "#909537" :"#a6a6a6"} opacity="0"/>
              <path id="certificate-2" data-name="certificate" d="M3183.25,1509a.75.75,0,0,1-.75.75H3177a3.383,3.383,0,0,1-3.75-3.75v-8a3.383,3.383,0,0,1,3.75-3.75h12a3.383,3.383,0,0,1,3.75,3.75.75.75,0,0,1-1.5,0c0-1.577-.673-2.25-2.25-2.25h-12c-1.577,0-2.25.673-2.25,2.25v8c0,1.577.673,2.25,2.25,2.25h5.5A.75.75,0,0,1,3183.25,1509Zm.75-9.75h-6a.75.75,0,0,0,0,1.5h6a.75.75,0,0,0,0-1.5Zm-3,5.5a.75.75,0,0,0,0-1.5h-3a.75.75,0,0,0,0,1.5Zm13.107,2.134-.212.213.008.279a2.187,2.187,0,0,1-1.175,1.931l.994,3.487a.75.75,0,0,1-1,.9l-1.847-.74a3.252,3.252,0,0,0-2.417,0l-1.845.739a.751.751,0,0,1-1-.9l.994-3.487a2.189,2.189,0,0,1-1.174-1.931v-.3l-.2-.193a2.2,2.2,0,0,1,0-3.1l.213-.213-.008-.278a2.2,2.2,0,0,1,2.193-2.194h.3l.191-.2a2.246,2.246,0,0,1,3.1,0l.212.211.28-.007a2.2,2.2,0,0,1,2.193,2.194v.3l.2.193A2.195,2.195,0,0,1,3194.107,1506.884Zm-2.248,4.851-.578-2.028-.064.067a2.192,2.192,0,0,1-3.1,0l-.064-.064-.577,2.025.426-.172a4.752,4.752,0,0,1,3.531,0Zm1.188-6.892-.212-.212a1.469,1.469,0,0,1-.432-1.042v-.3a.694.694,0,0,0-.693-.694h-.3a1.462,1.462,0,0,1-1.043-.433l-.21-.21a.709.709,0,0,0-.982,0l-.211.211a1.468,1.468,0,0,1-1.042.432h-.3a.694.694,0,0,0-.693.694v.3a1.463,1.463,0,0,1-.432,1.041l-.214.213a.7.7,0,0,0,0,.981l.213.213a1.458,1.458,0,0,1,.432,1.04v.3a.694.694,0,0,0,.693.694h.3a1.465,1.465,0,0,1,1.04.43l.214.214a.709.709,0,0,0,.98,0l.214-.213a1.457,1.457,0,0,1,1.04-.43h.3a.694.694,0,0,0,.693-.694v-.3a1.461,1.461,0,0,1,.43-1.039l.214-.214A.7.7,0,0,0,3193.047,1504.843Zm-.964.49a2.417,2.417,0,1,1-2.416-2.416A2.419,2.419,0,0,1,3192.083,1505.333Zm-1.5,0a.917.917,0,1,0-.916.917A.917.917,0,0,0,3190.583,1505.333Z" fill={active ? "#909537" :"#a6a6a6"}/>
            </g>
          </svg>
        case "admins":
            return <svg width="24" height="24" viewBox="0 0 24 24">
            <g id="users" transform="translate(-1156 -1380)">
              <rect id="Frame" width="24" height="24" transform="translate(1156 1380)" fill={active ? "#909537" :"#a6a6a6"} opacity="0"/>
              <path id="users-2" data-name="users" d="M1165.009,1390.75a4.25,4.25,0,1,1,4.25-4.25A4.255,4.255,0,0,1,1165.009,1390.75Zm0-7a2.75,2.75,0,1,0,2.75,2.75A2.752,2.752,0,0,0,1165.009,1383.75Zm3.988,18H1161c-2.42,0-3.753-1.325-3.753-3.731,0-2.662,1.506-5.769,5.75-5.769h4c4.244,0,5.75,3.107,5.75,5.769C1172.75,1400.425,1171.417,1401.75,1169,1401.75Zm-6-8a3.958,3.958,0,0,0-4.25,4.269c0,1.564.674,2.231,2.253,2.231H1169c1.579,0,2.253-.667,2.253-2.231a3.958,3.958,0,0,0-4.25-4.269Zm9.56-2.56a3.415,3.415,0,0,1-2.405-.974.75.75,0,0,1,1.049-1.072,1.924,1.924,0,0,0,1.356.546,1.949,1.949,0,0,0,1.97-1.97,1.973,1.973,0,0,0-1.97-1.97,1.8,1.8,0,0,0-.828.187.75.75,0,0,1-.685-1.334,3.28,3.28,0,0,1,1.513-.353,3.47,3.47,0,0,1,0,6.94Zm3.1,8.56h-.68a.75.75,0,0,1,0-1.5h.68c1.145,0,1.59-.44,1.59-1.57a2.922,2.922,0,0,0-3.14-3.15h-1.51a.75.75,0,0,1,0-1.5h1.51a4.364,4.364,0,0,1,4.64,4.65A2.8,2.8,0,0,1,1175.66,1399.75Z" fill={active ? "#909537" :"#a6a6a6"}/>
            </g>
          </svg>
        case "stats":
            return <svg id="Page-1" xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20">
                <g id="Artboard" transform="translate(-859 -1321)">
                <g id="activity-Dashboard_-Rules" data-name="activity-Dashboard,-Rules" transform="translate(860 1322)">
                    <path id="Shape" d="M20,9H16l-3,9L7,0,4,9H0" fill="none" stroke={active ? "#909537" :"#a6a6a6"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fillRule="evenodd"/>
                </g>
                </g>
            </svg>
        case "schools":
            return <svg xmlns="http://www.w3.org/2000/svg" width="20.001" height="20.001" viewBox="0 0 20.001 20.001">
                <path id="Path_58587" data-name="Path 58587" d="M10.431,291.181a1,1,0,0,0-.9,1V294.7l-4.62,3.7a1,1,0,0,0-.377.775v2H1.525a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H19.534a1,1,0,0,0,1-1v-8a1,1,0,0,0-1-1H16.527v-2a1,1,0,0,0-.367-.775l-4.63-3.7v-1.52h1a1,1,0,0,0,0-2h-2a.976.976,0,0,0-.1,0Zm.1,5.278,3.993,3.205v9.516h-3v-3a1,1,0,1,0-2,0v3h-3v-9.524Zm-8,6.719h2v6h-2Zm14,0h2v6h-2Z" transform="translate(-0.529 -291.18)" fill={active ? "#909537" :"#a6a6a6"}/>
            </svg>
        case "requests":
            return <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                <g id="Group_42506" data-name="Group 42506" transform="translate(-0.467 -0.467)">
                <rect id="Rectangle_5364" data-name="Rectangle 5364" width="23" height="23" transform="translate(0.467 0.467)" fill="none"/>
                <circle id="Ellipse_550" data-name="Ellipse 550" cx="2.5" cy="2.5" r="2.5" transform="translate(3.467 14.467)" fill="none" stroke={active ? "#909537" :"#a6a6a6"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_551" data-name="Ellipse 551" cx="2.5" cy="2.5" r="2.5" transform="translate(2.467 3.467)" fill="none" stroke={active ? "#909537" :"#a6a6a6"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_487" data-name="Line 487" y2="6" transform="translate(5.467 8.467)" fill="none" stroke={active ? "#909537" :"#a6a6a6"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <circle id="Ellipse_552" data-name="Ellipse 552" cx="2.5" cy="2.5" r="2.5" transform="translate(14.467 14.467)" fill="none" stroke={active ? "#909537" :"#a6a6a6"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_58588" data-name="Path 58588" d="M140.609,57.927V54.371a4.254,4.254,0,0,0-1.246-3.008L136,48" transform="translate(-123.946 -43.746)" fill="none" stroke={active ? "#909537" :"#a6a6a6"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_58589" data-name="Path 58589" d="M136,51.545V48h3.545" transform="translate(-123.946 -43.746)" fill="none" stroke={active ? "#909537" :"#a6a6a6"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
            </svg>
        default:
            return <svg id="Group_42505" data-name="Group 42505" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_58585" data-name="Path 58585" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_58586" data-name="Path 58586" d="M19,5V7H15V5h4M9,5v6H5V5H9m10,8v6H15V13h4M9,17v2H5V17H9M21,3H13V9h8ZM11,3H3V13h8Zm10,8H13V21h8ZM11,15H3v6h8Z" fill={active ? "#909537" :"#a6a6a6"}/>
            </svg>
    }
}

export default Icon;
