import { User } from "../models/user";

export const SET_LOADING = "SET_LOADING";
export const SET_USER = "SET_USER";
export const LOG_OUT = "LOG_OUT";
export const LOGIN = "LOGIN";

//State
export interface AuthState {
  user: User | null;
  authenticated: boolean;
  checkingToken: boolean;
}

//DTOs
export interface LogInData {
  email: string;
  password: string;
}

//Actions
interface SetUserAction {
  type: typeof SET_USER;
  payload: User;
}
interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}
interface LogOutAction {
  type: typeof LOG_OUT;
}
interface LoginPayload {
  user: User;
  token: string;
}
interface LoginAction {
  type: typeof LOGIN;
  payload: LoginPayload;
}
export type AuthAction = SetLoadingAction | SetUserAction | LogOutAction | SetLoadingAction | LoginAction;

export interface IRegistrationsEachWeekDay {
  year: number;
  week?: number;
  day?: number;
  last_year_rank: number | null;
  count: number;
}
