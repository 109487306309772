import React, { FC} from 'react'

const Loader: FC<any> = ({ gender, className, style }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="119.616" height="122.113" viewBox="0 0 119.616 122.113" className={className} style={style}>
            <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_5401" data-name="Rectangle 5401" width="119.616" height="122.113" fill="none"/>
            </clipPath>
            <clipPath id="clip-path-2">
                <path id="Path_58609" data-name="Path 58609" d="M60.422,0A59.194,59.194,0,0,0,1.229,59.193h0a59.193,59.193,0,1,0,118.387,0h0A59.2,59.2,0,0,0,60.422,0Z" fill="none"/>
            </clipPath>
            <clipPath id="clip-path-3">
                <rect id="Rectangle_5395" data-name="Rectangle 5395" width="0.267" height="8.285" transform="translate(66.544 34.334)" fill="none"/>
            </clipPath>
            <clipPath id="clip-path-4">
                <rect id="Rectangle_5399" data-name="Rectangle 5399" width="0.844" height="0.95" transform="translate(60.973 35.332)" fill="none"/>
            </clipPath>
            <clipPath id="clip-path-5">
                <rect id="Rectangle_5400" data-name="Rectangle 5400" width="0.832" height="0.948" transform="translate(60.973 35.333)" fill="none"/>
            </clipPath>
            </defs>
            <g id="Group_42552" data-name="Group 42552" transform="translate(-997.692 -919.529)">
            <path id="Path_58612" data-name="Path 58612" d="M59,0A59,59,0,1,1,0,59,59,59,0,0,1,59,0Z" transform="translate(999.308 919.529)" fill={gender === "ذكور" ? "#909537" : gender === "إناث" ? "#FFC0CB" : "#FED965"}/>
            <g id="Group_42546" data-name="Group 42546" transform="translate(997.692 919.529)">
                <g id="Group_42538" data-name="Group 42538" clipPath="url(#clip-path)">
                <g id="Group_42537" data-name="Group 42537">
                    <g id="Group_42536" data-name="Group 42536" clipPath="url(#clip-path-2)">
                    <rect id="Rectangle_5384" data-name="Rectangle 5384" width="1.256" height="16.93" transform="translate(59.794 40.067)" fill="#c7c9cb"/>
                    <rect id="Rectangle_5385" data-name="Rectangle 5385" width="22.911" height="47.782" transform="translate(0 74.331)" fill="#d6d4c9"/>
                    <rect id="Rectangle_5386" data-name="Rectangle 5386" width="22.911" height="47.782" transform="translate(96.248 74.331)" fill="#d6d4c9"/>
                    <rect id="Rectangle_5387" data-name="Rectangle 5387" width="73.338" height="58.325" transform="translate(22.911 63.788)" fill="#e7e5d9"/>
                    <path id="Path_58599" data-name="Path 58599" d="M42.724,77.555H22.91V73.648H42.724a1.953,1.953,0,0,1,0,3.907" fill="#c0b3a9"/>
                    <path id="Path_58600" data-name="Path 58600" d="M96.248,77.555H76.434a1.953,1.953,0,0,1,0-3.907H96.248Z" fill="#c0b3a9"/>
                    <path id="Path_58601" data-name="Path 58601" d="M59.794,76.205h0A12.233,12.233,0,0,0,47.561,88.438v33.675H72.027V88.438A12.233,12.233,0,0,0,59.794,76.205" fill="#968576"/>
                    <path id="Path_58602" data-name="Path 58602" d="M35.322,91.654h0a9.016,9.016,0,0,0-9.016,9.016v21.443H44.338V100.67a9.016,9.016,0,0,0-9.016-9.016" fill="#968576"/>
                    <path id="Path_58603" data-name="Path 58603" d="M84.066,92.3h0a9.016,9.016,0,0,0-9.016,9.016v20.795H93.082V101.318A9.016,9.016,0,0,0,84.066,92.3" fill="#968576"/>
                    <path id="Path_58604" data-name="Path 58604" d="M59.213,105.041h0a5.557,5.557,0,0,0-5.558,5.558v11.173a.341.341,0,0,0,.341.341H64.43a.341.341,0,0,0,.341-.341V110.6a5.557,5.557,0,0,0-5.558-5.558" fill="#7a6b5c"/>
                    <path id="Path_58605" data-name="Path 58605" d="M35.322,109.651h0a4.057,4.057,0,0,0-4.057,4.057v8.156a.249.249,0,0,0,.249.249H39.13a.249.249,0,0,0,.249-.249v-8.156a4.057,4.057,0,0,0-4.057-4.057" fill="#7a6b5c"/>
                    <path id="Path_58606" data-name="Path 58606" d="M84.066,109.651h0a4.057,4.057,0,0,0-4.057,4.057v8.156a.249.249,0,0,0,.249.249h7.616a.249.249,0,0,0,.249-.249v-8.156a4.057,4.057,0,0,0-4.057-4.057" fill="#7a6b5c"/>
                    <path id="Path_58607" data-name="Path 58607" d="M12.166,84.369h0a3.674,3.674,0,0,1,3.674,3.674v12.088H8.492V88.043a3.674,3.674,0,0,1,3.674-3.674" fill="#c0b3a9"/>
                    <path id="Path_58608" data-name="Path 58608" d="M107.551,84.369h0a3.674,3.674,0,0,1,3.674,3.674v12.088h-7.348V88.043a3.674,3.674,0,0,1,3.674-3.674" fill="#c0b3a9"/>
                    <rect id="Rectangle_5388" data-name="Rectangle 5388" width="76.205" height="7.907" transform="translate(21.31 55.881)" fill="#c0b3a9"/>
                    <rect id="Rectangle_5389" data-name="Rectangle 5389" width="22.911" height="6.966" transform="translate(96.248 67.194)" fill="#c1b4aa"/>
                    <rect id="Rectangle_5390" data-name="Rectangle 5390" width="22.911" height="6.966" transform="translate(0 67.194)" fill="#c1b4aa"/>
                    <rect id="Rectangle_5391" data-name="Rectangle 5391" width="5.767" height="8.651" transform="translate(66.678 34.334)" fill="#cf021c"/>
                    <rect id="Rectangle_5392" data-name="Rectangle 5392" width="5.767" height="2.947" transform="translate(66.678 34.334)" fill="#e6e7e5"/>
                    <rect id="Rectangle_5393" data-name="Rectangle 5393" width="5.767" height="3.186" transform="translate(66.678 39.799)" fill="#297030"/>
                    <g id="Group_42529" data-name="Group 42529" opacity="0.1">
                        <g id="Group_42528" data-name="Group 42528">
                        <g id="Group_42527" data-name="Group 42527" clipPath="url(#clip-path-3)">
                            <rect id="Rectangle_5394" data-name="Rectangle 5394" width="0.267" height="8.285" transform="translate(66.545 34.334)" fill="#1a1818"/>
                        </g>
                        </g>
                    </g>
                    <rect id="Rectangle_5396" data-name="Rectangle 5396" width="6.884" height="8.651" transform="translate(59.794 33.967)" fill="#cf021c"/>
                    <rect id="Rectangle_5397" data-name="Rectangle 5397" width="3.43" height="2.947" transform="translate(63.248 33.967)" fill="#e6e7e5"/>
                    <rect id="Rectangle_5398" data-name="Rectangle 5398" width="3.43" height="3.186" transform="translate(63.248 39.433)" fill="#297030"/>
                    <g id="Group_42532" data-name="Group 42532" opacity="0.26">
                        <g id="Group_42531" data-name="Group 42531">
                        <g id="Group_42530" data-name="Group 42530" clipPath="url(#clip-path-4)">
                            <line id="Line_489" data-name="Line 489" x2="0.691" y2="0.821" transform="translate(61.05 35.397)" fill="#fff"/>
                            <line id="Line_490" data-name="Line 490" x2="0.691" y2="0.821" transform="translate(61.05 35.397)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.2"/>
                        </g>
                        </g>
                    </g>
                    <g id="Group_42535" data-name="Group 42535" opacity="0.26">
                        <g id="Group_42534" data-name="Group 42534">
                        <g id="Group_42533" data-name="Group 42533" clipPath="url(#clip-path-5)">
                            <line id="Line_491" data-name="Line 491" x1="0.678" y2="0.821" transform="translate(61.05 35.397)" fill="#fff"/>
                            <line id="Line_492" data-name="Line 492" x1="0.678" y2="0.821" transform="translate(61.05 35.397)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.2"/>
                        </g>
                        </g>
                    </g>
                    </g>
                </g>
                </g>
            </g>
            </g>
        </svg>
  
    )
}

export default Loader