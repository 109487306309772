import React, { FC, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import useTranslation from '../../i18n/hooks/useTranslation'
import api from '../../api';

interface IProps{
    setShown: (value: boolean) => void
    shown: boolean
}

const UpdateMyPasswordModal: FC<IProps> = ({setShown, shown }) => {

    const t = useTranslation()

    
    const initState = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    }

    const [data, setData] = useState<any>(initState);

    const handleClose = () => setShown(false)

    const handleChange = (event: React.ChangeEvent<any>) => {
        event.persist()
        setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }))
    }

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        let { newPassword, oldPassword, confirmPassword } = data

        if(newPassword !== confirmPassword)
            return toast.error(t("AUTH.PASSWORDS_DONT_MATCH"))

        if(newPassword === oldPassword)
            return toast.error(t("AUTH.ENTER_DIFF_PASSWORDS"))

        const toastId = toast.loading(t('LOADING'))
        const body = JSON.stringify({ oldPassword, newPassword })

        try {
            await api.put('/auth/password', body)
            toast.success(t('SUCCESS.PASSOWRD_UPDATED'), { id: toastId })
            setShown(false)
        } catch (err: any) {
            toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED") , {
                id: toastId,
            })
        }
    }

    return (
        <Modal show={shown} onHide={handleClose}>
            <Form onSubmit={submitHandler}>
                <Modal.Header>
                    <Modal.Title>{t("AUTH.CHANGE_PASSWORD")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("AUTH.CURRENT_PASSWORD")}</Form.Label>
                        <Form.Control type="password" placeholder={t("AUTH.CURRENT_PASSWORD")} name="oldPassword" 
                            value={data.oldPassword} onChange={handleChange} required />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t("AUTH.NEW_PASSWORD")}</Form.Label>
                        <Form.Control type="password" placeholder={t("AUTH.NEW_PASSWORD")} name="newPassword" 
                            value={data.newPassword} onChange={handleChange} required />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>{t("AUTH.CONFIRM_NEW_PASSWORD")}</Form.Label>
                        <Form.Control type="password" placeholder={t("AUTH.CONFIRM_NEW_PASSWORD")} name="confirmPassword" 
                            value={data.confirmPassword} onChange={handleChange} required />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("CANCEL")}
                    </Button>
                    <Button variant="primary" type="submit">
                        {t("UPDATE")}
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    )
}

export default UpdateMyPasswordModal