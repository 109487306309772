import React, { FC, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import { School } from '../../../models/school';
import useTranslation from '../../../i18n/hooks/useTranslation'
import api from '../../../api';
import DBInput from '../../../components/DBInput';
import SelectSearch from 'react-select-search';

interface IProps{
    setShown: (modal:string) => void
    shown: string
    schoolAdded: (school:School) => void
}

const AddSchoolModal: FC<IProps> = ({setShown, shown, schoolAdded}) => {

    const t = useTranslation()

    const initState = {
        id: "",
        name: "",
        gender: "",
        education_level: "",
        governorate: "",
        city: ""
    }

    const [data, setData] = useState<any>(initState);

    const handleClose = () => setShown("")

    const handleChange = (event: React.ChangeEvent<any>) => {
        event.persist()
        setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }))
    }

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        const toastId = toast.loading(t('LOADING'))
        const formData = new FormData()

        for (const property in data) 
            if(data[property])
                formData.append(property, data[property]);
        
        try {
            let res = await api.post('/schools', formData)
            toast.success(t('MANAGE_SCHOOLS.SCHOOL_ADDED'), { id: toastId })
            schoolAdded(res.data.school)
        } catch (err: any) {
            toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED") , {
                id: toastId,
            })
        }
    }

    return (
        <Modal show={shown === "add"} onHide={handleClose} className="db-modal" centered={true}>
            <Form onSubmit={submitHandler}>
                <Modal.Body>
                    <div className="title">{t("MANAGE_SCHOOLS.ADD_SCHOOL")}</div>

                    <div className="mb-3">
                        <label htmlFor="id" className="mb-2">رقم المدرسة</label>
                        <DBInput type="id" placeholder={"رقم المدرسة"} name="id" id="id"
                            value={data.id} onChange={handleChange} required />
                    </div> 

                    <div className="mb-3">
                        <label htmlFor="name" className="mb-2">{t("SCHOOL_NAME")}</label>
                        <DBInput type="text" placeholder={t("SCHOOL_NAME")} name="name" id="name"
                            value={data.name} onChange={handleChange} required />
                    </div>  

                    <div className="row">
                        <div className="col-6">
                            <div className="mb-3">
                                <label htmlFor="gender" className="mb-2">{t("GENDER")}</label>
                                <SelectSearch
                                    className={"SelectSec db-select"}
                                    onChange={(...args: any) => setData((prevData: any) => ({ ...prevData, gender: args[0] }))}
                                    options={[{ name: "ذكور", value: "ذكور"}, {name: "إناث", value: "إناث"}, {name: "مشترك", value: "مشترك"}]}
                                    placeholder={t("CHOOSE_GENDER")}
                                    value={data.gender}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <Form.Group>
                                <label htmlFor="education_level" className="mb-2">{t("EDUCATION_LEVEL")}</label>
                                <SelectSearch
                                    className={"SelectSec db-select"}
                                    onChange={(...args: any) => setData((prevData: any) => ({ ...prevData, education_level: args[0] }))}
                                    options={[{ name: "حلقة أولى", value: "حلقة أولى"}, {name: "حلقة ثانية", value: "حلقة ثانية"}, {name: "ما بعد الأساسي", value: "ما بعد الأساسي"}]}
                                    placeholder={t("CHOOSE_EDUCATION_LEVEL")}
                                    value={data.education_level}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="governorate" className="mb-2">{t("THE_GOVERNORATE")}</label>
                        <DBInput type="text" placeholder={t("THE_GOVERNORATE")} name="governorate" id="governorate"
                            value={data.governorate} onChange={handleChange} required />
                    </div> 

                    <div className="mb-3">
                        <label htmlFor="city" className="mb-2">{t("CITY")}</label>
                        <DBInput type="text" placeholder={t("CITY")} name="city" id="city"
                            value={data.city} onChange={handleChange} required />
                    </div>  
                    
                    <div className="d-flex">
                        <button className="btn-c btn-green w-80 me-2" type="submit">{t("ADD")}</button>
                        <button className="btn-c btn-cancel w-20" type="button" onClick={handleClose}>{t("CANCEL")}</button>
                    </div>
                </Modal.Body>
            </Form>

        </Modal>
    )
}

export default AddSchoolModal