import React, { FC, useState } from "react";
import { Nav } from "react-bootstrap";
import { logout } from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import UpdateMyPasswordModal from "../UpdateMyPasswordModal";
import { RootState } from "../../store";
import NotificationsDropdown from "../NotificationsDropdown";

interface IProps {
  toggleSideBar: () => void;
}

const DBNavbar: FC<IProps> = ({ toggleSideBar }) => {
  const dispatch = useDispatch();

  const [shown, setShown] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <>
      {shown && <UpdateMyPasswordModal setShown={setShown} shown={shown} />}
      <div className="admin-navbar d-flex w-100">
        <Nav className="tw-flex tw-items-center tw-justify-between tw-w-full">
          <button className="tw-text-black xl:tw-hidden" onClick={() => toggleSideBar()}>
            <div className="tw-space-y-1">
              <div className="tw-w-6 tw-h-0.5 tw-bg-gray-600"></div>
              <div className="tw-w-6 tw-h-0.5 tw-bg-gray-600"></div>
              <div className="tw-w-6 tw-h-0.5 tw-bg-gray-600"></div>
            </div>
          </button>
          <div className="left-sidebar tw-flex tw-items-center xl:tw-mr-auto">
            {user?.ministry !== "EDUCATION" && <NotificationsDropdown />}

            {/* <div className="profile-photo ms-3 my-auto"></div> */}
            <div className="username mx-3 my-auto">{user?.name}</div>

            <svg className="my-auto" style={{ cursor: "pointer" }} width="24" height="24" viewBox="0 0 24 24" onClick={() => dispatch(logout())}>
              <rect id="Frame" width="24" height="24" fill="#a6a6a6" opacity="0" />
              <path
                id="log-out-2"
                data-name="log-out"
                d="M744.249,421v1a3.383,3.383,0,0,0,3.75,3.75h6a3.383,3.383,0,0,0,3.75-3.75V410a3.383,3.383,0,0,0-3.75-3.75h-6a3.383,3.383,0,0,0-3.75,3.75v1a.75.75,0,0,0,1.5,0v-1c0-1.577.673-2.25,2.25-2.25h6c1.577,0,2.25.673,2.25,2.25v12c0,1.577-.673,2.25-2.25,2.25h-6c-1.577,0-2.25-.673-2.25-2.25v-1a.75.75,0,0,0-1.5,0Zm-5.942-4.713a.751.751,0,0,1,0-.574.772.772,0,0,1,.162-.244l3-3a.75.75,0,0,1,1.06,1.06l-1.719,1.72H752a.75.75,0,0,1,0,1.5H740.81l1.719,1.72a.75.75,0,1,1-1.06,1.06l-3-3A.772.772,0,0,1,738.307,416.287Z"
                transform="translate(-735.999 -404)"
                fill="#a6a6a6"
              />
            </svg>
          </div>
        </Nav>
      </div>
    </>
  );
};

export default DBNavbar;
