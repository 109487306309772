import React, { FC } from "react";
import { Link } from "react-router-dom";
import NewsCard from "../../../components/NewsCard";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { News } from "../../../models/news";
import { BsArrowLeft } from "react-icons/bs";

const NewsSection: FC<{ news: News[] }> = ({ news }) => {
  const t = useTranslation();

  return (
    <div className="news-section tw-mb-28">
      <div className="d-flex tw-justify-between tw-mb-14" data-aos="fade-up">
        <h3 className="tw-text-xl lg:tw-text-2xl" data-aos="fade-up">{t("HOME.THE_LAST_NEWS")}</h3>

        <Link to="/news" className="see-more tw-hidden md:tw-flex" data-aos="fade-up">
          {t("HOME.SHOW_ALL_NEWS")} <BsArrowLeft className="tw-mr-4 tw-text-2xl color-green" />
        </Link>
      </div>

      <div className="row mb-3" data-aos="fade-up">
        {news.map((article, idx) => (
          <div className="col-12 col-md-4" key={"news-card-" + idx}>
            <NewsCard {...article} />
          </div>
        ))}
      </div>
      <div className="more-btn-anii " data-aos="fade-up">
      <Link to="/news" className="see-more tw-flex md:tw-hidden tw-items-center">
        {t("HOME.SHOW_ALL_NEWS")} <BsArrowLeft className="tw-mr-2 more-arr-btnn tw-text-xl color-green" />
      </Link>
      </div>
      
    </div>
  );
};

export default NewsSection;
