import React, { FC } from "react";
import useTranslation from "../../../i18n/hooks/useTranslation";
import RightCirSec4 from "../../../assets/images/RightCirSec4.png";
import LeftCirSec4 from "../../../assets/images/LeftCirSec4.png";
import AOS from 'aos';
import 'aos/dist/aos.css'

const HowItWorks: FC = () => {
  AOS.init();
  const t = useTranslation();

  return (
    <div className="HomeSection4 container pt-1 tw-px-5 tw-mb-28">
      <h3 className="tw-mb-14 tw-text-xl lg:tw-text-2xl mt-5 mx-2" data-aos="fade-up" >{t("HOME.HOW_IT_WORKS")}</h3>
      <div className="HomeSection4Container w-75">
        <div className="Section4Container" data-aos="fade-up">
          <div className="whitCard">
            <div className="WhitCRSec">
              <p className="num">1</p>
              <p className="title">{t("HOME.FIRST_STEP")}</p>
              <p className="Des">{t("HOME.FIRST_STEP_DESCRIPTION")}</p>
            </div>
            <div className="WhitCLSec">
              <ul>
                <li>
                  <p>{t("HOME.FIRST_STEP_LIST_1")}</p>
                </li>
                <li>
                  <p>{t("HOME.FIRST_STEP_LIST_2")}</p>
                </li>
               
                <li>
                  <p>{t("HOME.FIRST_STEP_LIST_4")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="LeftCirSec4" >
          <img src={LeftCirSec4} alt="" />
        </div>

        <div className="Section4Container " data-aos="fade-up">
          <div className="GrenCard">
            <div className="GrenCRSec">
              <p className="num">2</p>
              <p className="title">{t("HOME.SECOND_STEP")}</p>
              <p className="Des">{t("HOME.SECOND_STEP_DESCRIPTION")}</p>
            </div>
            <div className="GrenCLSec">
              <ul>
                <li>
                  <p>{t("HOME.SECOND_STEP_LIST_1")}</p>
                </li>
                <li>
                  <p>{t("HOME.SECOND_STEP_LIST_2")}</p>
                </li>
                <li>
                  <p>{t("HOME.SECOND_STEP_LIST_3")}</p>
                </li>
                <li>
                  <p>{t("HOME.SECOND_STEP_LIST_4")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="RightCirSec4">
          <img src={RightCirSec4} alt="line" />
        </div>

        <div className="Section4Container" data-aos="fade-up">
          <div className="whitCard">
            <div className="WhitCRSec">
              <p className="num">3</p>
              <p className="title">{t("HOME.THIRD_STEP")}</p>
              <p className="Des">{t("HOME.THIRD_STEP_DESCRIPTION")}</p>
            </div>
            <div className="WhitCLSec">
              <ul>
                <li>
                  <p>{t("HOME.THIRD_STEP_LIST_1")}</p>
                </li>

                <li>
                  <p>{t("HOME.THIRD_STEP_LIST_2")}</p>
                </li>

                <li>
                  <p>{t("HOME.THIRD_STEP_LIST_3")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
