import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../api";
import ConfirmDelete from "../../../components/ConfirmDelete";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import ExportCSVButton from "../../../components/ExportCSVButton";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { User } from "../../../models/user";
import AddAdminModal from "./AddAdminModal";
import AdminsTable from "./AdminsTable";
import UpdatePasswordModal from "./UpdatePasswordModal";

const ManageAdmins: FC = () => {
  const t = useTranslation();

  const [admins, setAdmins] = useState<User[] | null>(null);
  const [search, setSearch] = useState("");
  const [idToDelete, setIdToDelete] = useState<number | string>("");
  const [idToUpdatePassword, setIdToUpdatePassword] = useState<number | string>("");
  const [shownModal, setShownModal] = useState("");

  const getAdmins = async () => {
    let { data } = await api.get("/users/admins");
    setAdmins(data);
  };

  const adminAdded = (admin: User) => {
    setShownModal("");
    setAdmins([admin, ...admins!]);
  };

  const filterAdmins = (admins: User[]) => {
    if (!search) return admins;
    return admins!.filter((a) => a.name.toLowerCase().includes(search) || a.email.toLowerCase().includes(search));
  };

  const deleteAdmin = async () => {
    let id = idToDelete;
    await api.delete("/users/admins/" + id);
    toast.success(t("SUCCESS.DELETED"));
    setAdmins(admins!.filter((s) => s.id !== id));
    setShownModal("");
  };

  const openDeleteAdmin = async (id: number | string) => {
    setIdToDelete(id);
    setShownModal("delete");
  };

  const openUpdatePassword = async (id: number | string) => {
    setIdToUpdatePassword(id);
    setShownModal("update_password");
  };

  useEffect(() => {
    if (!admins) getAdmins();
  }, [admins]);

  if (!admins) return <LoadingSpinner />;

  return (
    <div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-6 tw-mb-6">
        <div className="tw-flex tw-w-full tw-justify-between md:tw-justify-start tw-items-center">
          <h1 className="me-2 tw-text-lg md:tw-text-2xl">إدارة المشرفين</h1>
          <div className="sub-title">{admins.length} مشرف</div>
        </div>
        <div className="tw-flex">
          <div className="table-search my-auto d-flex px-2">
            <input type="text" className="my-auto me-auto" placeholder="بحث" value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
            <svg xmlns="http://www.w3.org/2000/svg" width="19.457" height="19.457" viewBox="0 0 19.457 19.457" className="my-auto">
              <g id="Layer_2" data-name="Layer 2" opacity="0.1">
                <path
                  id="search"
                  d="M22.138,20.6,18.463,16.94a8.558,8.558,0,0,0,1.826-5.295,8.645,8.645,0,1,0-8.645,8.645,8.558,8.558,0,0,0,5.295-1.826L20.6,22.138A1.085,1.085,0,1,0,22.138,20.6ZM5.161,11.645a6.484,6.484,0,1,1,6.484,6.484,6.484,6.484,0,0,1-6.484-6.484Z"
                  transform="translate(-3 -3)"
                />
              </g>
            </svg>
          </div>
          <ExportCSVButton
            headers={["تاريخ الإنشاء", "آخر تسجيل دخول", "الوزارة", "الايميل", "المسمى الوظيفي", "الإسم", "id"]}
            data={admins.map(({ id, name, title, email, ministry, created_at, last_login }) => [
              moment(created_at).locale("en").format("DD/MM/YYYY"),
              last_login ? moment(last_login).locale("en").format("HH:mm DD/MM/YYYY") : "-",
              t(ministry),
              email,
              title,
              name,
              id,
            ])}
            file="المشرفون"
          />
          <button
            className="btn-green tw-py-0 tw-px-2 md:tw-px-4 tw-text-xs md:tw-text-base tw-whitespace-nowrap"
            onClick={() => setShownModal("add")}>
            إضافة مشرف
          </button>
        </div>
      </div>
      <div className="tw-overflow-x-scroll">
        <AdminsTable admins={filterAdmins(admins)} openDeleteAdmin={openDeleteAdmin} openUpdatePassword={openUpdatePassword} />
      </div>

      {shownModal === "add" && <AddAdminModal setShown={setShownModal} shown={shownModal} adminAdded={adminAdded} />}

      {shownModal === "update_password" && (
        <UpdatePasswordModal
          setShown={(val: boolean) => setShownModal(val ? "update_password" : "")}
          shown={shownModal === "update_password"}
          id={idToUpdatePassword!}
        />
      )}

      {shownModal === "delete" && <ConfirmDelete setShown={setShownModal} shown={shownModal} handleDelete={deleteAdmin} />}
    </div>
  );
};

export default ManageAdmins;
