import React, { FC } from "react";
import BreadCrumbs from "../../../components/BreadCrumbs";
import MainNavbar from "../../../components/MainNavbar";
import useTranslation from "../../../i18n/hooks/useTranslation";
import AnimatedTextUp from "../../../utils/AnimatedTextUp";
import { motion } from "framer-motion";

const Header: FC<{ season: string }> = ({ season }) => {
  const t = useTranslation();

  return (
    <div className="background-Head">
      <div className="bg-coll">
        <div className="container pt-2">
          <MainNavbar />
          <motion.h1
            initial="hidden"
            animate={"visible"}
            className="tw-text-[2rem] tw-mt-28 tw-leading-[3.2rem]"
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.1,
                  delayChildren: 1.5,
                },
              },
            }}>
            <AnimatedTextUp>{t("RESULTS.TITLE")}</AnimatedTextUp>
          </motion.h1>
          <motion.div
            initial="hidden"
            animate={"visible"}
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.1,
                  delayChildren: 0.5,
                },
              },
            }}
            className="tw-text-2xl tw-mb-2 tw-text-white tw-opacity-50">
            <AnimatedTextUp>{t("RESULTS.SUB_TITLE").replace("٢٠٢٠ -٢٠٢١", season)}</AnimatedTextUp>
          </motion.div>
          <BreadCrumbs
            white={true}
            links={[
              {
                title: "الرئيسية",
                url: "/",
              },
              {
                title: "النتائج",
                url: "/results",
              },
            ]}
          />
        </div>
        {/* <div className="T-Section">
          <div className="container">
            <h1>{t("RESULTS.TITLE")}</h1>
            <h3>{t("RESULTS.SUB_TITLE").replace("٢٠٢٠ -٢٠٢١", season)}</h3>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
