import React, { FC } from "react";
import MainNavbar from "../../../components/MainNavbar";
import { motion } from "framer-motion";
import BreadCrumbs from "../../../components/BreadCrumbs";
import AnimatedTextUp from "../../../utils/AnimatedTextUp";

const Header: FC<{ title: string }> = ({ title }) => {
  return (
    <header>
      <div className="container pt-2 px-4">
        <MainNavbar />
        <motion.h1
          initial="hidden"
          animate={"visible"}
          className="tw-text-[2.3rem] tw-pl-5 md:tw-pl-0 tw-mt-20 md:tw-mt-28"
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.1,
                delayChildren: 1.5,
              },
            },
          }}>
          <AnimatedTextUp>{title}</AnimatedTextUp>
        </motion.h1>
        <hr style={{ color: "white" }} className="tw-mb-2" />
        <BreadCrumbs
          white={true}
          links={[
            {
              title: "الرئيسية",
              url: "/",
            },
            {
              title: title,
              url: "/news",
            },
          ]}
        />
      </div>
    </header>
  );
};

export default Header;
