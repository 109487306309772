import React, { FC } from "react";
import { MultiSelect } from "react-multi-select-component";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { School } from "../../../models/school";
import { Season } from "../../../models/season";
import { genders, governorates, levels } from "./data";

interface IProps {
  seasons: Season[];
  filters: any;
  setFilters: Function;
  selectedSchools: School[] | [];
}
const Filters: FC<IProps> = ({ seasons, filters, setFilters }) => {
  const t = useTranslation();

  const handleChange = (name: string, value: any) => {
    if(value)
      setFilters((prevData: any) => ({ ...prevData, [name]: value }));
  };

  return (
    <div className="filters ">
      <div className="row">
        <div className="col-6 col-md-12">
          <div className="d-flex">
            <h3 className="my-auto">فرز المدارس</h3>
          </div>
          {/* Season */}
          <MultiSelect
            options={seasons.map((season) => ({ label: season.year, value: season.year }))}
            value={[
              {
                label: t("ACADEMIC_YEAR") + " " + (filters.season.value || seasons[0].year),
                value: filters.season.value || seasons[0].year,
              },
            ]}
            onChange={(item: any) => handleChange("season", item[item.length - 1])}
            labelledBy={t("ACADEMIC_YEAR")}
            hasSelectAll={false}
            valueRenderer={(selected, _options) => {
              return selected.length
                ? selected.map(({ label }) => {
                    return (
                      <span key={"k3-" + label} className="tw-text-white tw-bg-[#909537] hover:tw-bg-[#eb9c3f] tw-rounded-lg tw-text-sm tw-py-0.5 tw-px-2 tw-m-1 tw-ml-auto tw-inline-flex tw-items-center">
                        {label}
                      </span>
                    );
                  })
                : t("ACADEMIC_YEAR");
            }}
            ClearSelectedIcon={null}
            closeOnChangedValue={true}
            overrideStrings={{
              search: t("SEARCH"),
            }}
          />
        </div>
        <div className="col-6 col-md-12">
          {/* Governorate */}
          <MultiSelect
            options={governorates.map((gov) => ({ label: "محافظة " + gov, value: gov }))}
            value={filters.governorate}
            onChange={(item: any) => handleChange("governorate", item)}
            labelledBy={t("THE_GOVERNORATE")}
            hasSelectAll={false}
            valueRenderer={(selected, _options) => {
              return selected.length
                ? selected.map(({ label }) => {
                    return (
                      <span key={"k1-" + label} className="tw-text-white tw-bg-[#909537] hover:tw-bg-[#eb9c3f] tw-rounded-lg tw-text-sm tw-py-0.5 tw-px-2 tw-m-1 tw-ml-auto tw-inline-flex tw-items-center">
                        {label}
                      </span>
                    );
                  })
                : t("THE_GOVERNORATE");
            }}
            overrideStrings={{
              search: t("SEARCH"),
            }}
          />
          {/* Education Level */}
          <MultiSelect
            options={levels.map((level) => ({ label: level, value: level }))}
            value={filters.education_level}
            onChange={(item: any) => handleChange("education_level", item)}
            labelledBy={t("EDUCATION_LEVEL")}
            hasSelectAll={false}
            valueRenderer={(selected, _options) => {
              return selected.length
                ? selected.map(({ label }) => {
                    return (
                      <span key={"k2-" + label} className="tw-text-white tw-bg-[#909537] hover:tw-bg-[#eb9c3f] tw-rounded-lg tw-text-sm tw-py-0.5 tw-px-2 tw-m-1 tw-ml-auto tw-inline-flex tw-items-center">
                        {label}
                      </span>
                    );
                  })
                : t("EDUCATION_LEVEL");
            }}
            overrideStrings={{
              search: t("SEARCH"),
            }}
          />
          {/* Gender */}
          <MultiSelect
            options={genders.map((gender) => ({ label: gender, value: gender }))}
            value={filters.gender}
            onChange={(item: any) => handleChange("gender", item)}
            labelledBy={t("GENDER")}
            hasSelectAll={false}
            valueRenderer={(selected, _options) => {
              return selected.length
                ? selected.map(({ label }) => {
                    return (
                      <span key={"k3-" + label} className="tw-text-white tw-bg-[#909537] hover:tw-bg-[#eb9c3f] tw-rounded-lg tw-text-sm tw-py-0.5 tw-px-2 tw-m-1 tw-ml-auto tw-inline-flex tw-items-center">
                        {label}
                      </span>
                    );
                  })
                : t("GENDER");
            }}
            overrideStrings={{
              search: t("SEARCH"),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
