import moment from "moment";
import React, { FC } from "react";
import toast from "react-hot-toast";
import api from "../../../../api";
import useTranslation from "../../../../i18n/hooks/useTranslation";
import { Season } from "../../../../models/season";
import Clock from "./Clock";

const NotStarted: FC<{ season: Season; seasonUpdated: Function }> = ({ season, seasonUpdated }) => {
  const t = useTranslation();

  const startSeason = async (args: any) => {
    const toastId = toast.loading(t("LOADING"));
    const body = JSON.stringify({ startDate: moment().locale("en").format("YYYY-MM-DD HH:mm:00") });
    try {
      await api.put("/seasons/" + season.id, body);
      toast.success(t("SUCCESS.DONE"), { id: toastId });
      seasonUpdated();
    } catch (err: any) {
      toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
    }
  };

  return (
    <>
      <div className="lg:tw-col-span-8">
        <h4 className="mb-4 tw-text-base md:tw-text-xl">
          الموسم الحالي <span className="green tw-text-base md:tw-text-2xl">{season.year}</span>
        </h4>
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-6">
          <div className="lg:tw-col-span-4 d-flex details-card">
            <div className="icon d-flex me-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="30.278" height="30.277" viewBox="0 0 30.278 30.277">
                <path
                  id="Path_62388"
                  data-name="Path 62388"
                  d="M28.493,5.028H23.951V2.757a.757.757,0,1,0-1.514,0V5.028H11.84V2.757a.757.757,0,1,0-1.514,0V5.028H5.784A3.789,3.789,0,0,0,2,8.812v19.68a3.789,3.789,0,0,0,3.785,3.785H28.493a3.788,3.788,0,0,0,3.785-3.785V8.812A3.788,3.788,0,0,0,28.493,5.028Zm2.271,23.465a2.273,2.273,0,0,1-2.271,2.271H5.784a2.273,2.273,0,0,1-2.271-2.271V15.625h27.25Zm0-14.382H3.513v-5.3A2.273,2.273,0,0,1,5.784,6.542h4.542V8.812h0a.756.756,0,0,0,.757.756h0a.756.756,0,0,0,.756-.757V6.542h10.6V8.812h0a.756.756,0,0,0,.757.756h0a.756.756,0,0,0,.756-.757V6.542h4.542a2.273,2.273,0,0,1,2.271,2.271Z"
                  transform="translate(-2 -2)"
                  fill="#909537"
                />
              </svg>
            </div>
            <div className="details">
              <div className="title mb-1">حالة الموسم</div>
              <div className="state mb-1">تم بدأ الموسم</div>
              <div className="date">{moment(season.created_at).locale("en").format("DD/MM/YYYY HH:mm")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:tw-col-span-4 tw-border-t tw-border-t-[#707070]/20 lg:tw-border-t-0 lg:tw-border-r lg:tw-border-r-[#707070]/20 lg:tw-px-4 tw-pt-6 lg:tw-pt-0 tw-mt-6 lg:tw-mt-0">
        <h4 className="green tw-text-sm md:tw-text-base tw-whitespace-nowrap tw-mb-2" style={{ maxWidth: 180 }}>
          الوقت المتبقي حتى فتح باب التسجيل
        </h4>
        <div className="date">حتى {moment(season.startDate).locale("en").format("DD/MM/YYYY HH:mm")}</div>
        <Clock deadline={season.startDate} />
        <button className="btn-green-o tw-text-sm md:tw-text-base" onClick={startSeason}>
          فتح باب التسجيل الان
        </button>
      </div>
    </>
  );
};

export default NotStarted;
