import React, { FC} from 'react'
import useTranslation from '../../i18n/hooks/useTranslation'
import { CSVLink } from "react-csv"
import toast from 'react-hot-toast'

interface IProps {
    data: any[]
    headers: string[]
    file?: string
}

const ExportCSVButton: FC<IProps> = ({ data, file, headers}) => {

    const t = useTranslation()

    return (
        <CSVLink 
            className="btn-export my-auto mx-2 d-flex"
            onClick={() => toast.success(t("SUCCESS.FILE_EXPORTED")) }
            filename={file}
            data={[headers, ...data]} 
        >
            <svg className="m-auto" width="20.42" height="18.943" viewBox="0 0 20.42 18.943"><path id="icon_dowload" data-name="icon dowload" d="M1309.417,1012.782v-4a.786.786,0,0,1,1.565-.1l.007.1v4h4.88a4.571,4.571,0,0,1,4.543,4.366v5.239a4.561,4.561,0,0,1-4.321,4.561h-11.565a4.554,4.554,0,0,1-4.533-4.366v-5.229a4.563,4.563,0,0,1,4.31-4.562l.212-.005h4.9v6.381l-1.634-1.649a.784.784,0,0,0-1.09-.022l-.023.022a.817.817,0,0,0-.082,1.024l.082.1,2.971,3.006a.775.775,0,0,0,1.036.071l.076-.071,2.971-3.006a.8.8,0,0,0,0-1.123.785.785,0,0,0-1.021-.077l-.088.077-1.623,1.647v-6.381Z" transform="translate(-1299.993 -1008.005)" fill="#909537"/></svg>
        </CSVLink>
    )
}

export default ExportCSVButton