import React, { FC } from "react";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { Container, Row, Col } from "react-bootstrap";
import SVG from "react-inlinesvg";
import arrow from "./arrow.svg";

const Schools: FC<{ season: string | undefined; schools: number }> = ({ season, schools }) => {
  const t = useTranslation();
  return (
    <div>
      <div className="HomeSection2 tw-mb-28">
        {season && (
          <h3 data-aos="fade-up" className="tw-mb-14 tw-text-xl lg:tw-text-2xl">
            {t("HOME.REGISTERED_SCHOOLS")} <span className="green-sub">{t("HOME.CURRENT_YEAR").replace("##", season)}</span>
          </h3>
        )}
        <div data-aos="fade-up" className="row">
          <div className="col">
            <div className="card">
              <div className="cardimg">
                <div className="card-det">
                  <Container className="RCard-det tw-mb-4">
                    <Row>
                      <Col xs={4} className="num">
                        <p>{schools}</p>
                      </Col>
                      <Col xs={5} className="det">
                        <p className="Statu tw-mb-4">{t("HOME.SCHOOL_TILL_NOW")}</p>
                        {/* <p className="year">{t("HOME.SCHOOL_REGISTERED_YEAR")}</p> */}
                        {season && <p className="year">{season}</p>}
                      </Col>
                    </Row>
                  </Container>
                  <br />
                  <div className="card-det-btn">
                    <a href="/participating_schools" className="tw-flex tw-items-center tw-justify-between tw-px-5 tw-py-3 tw-whitespace-nowrap">
                      <p>{t("HOME.SHOW_ALL")}</p>
                      <SVG className="Arrow-icon" src={arrow} width={18} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="HomeSection2Phone">
        <h3 className="tw-mb-2 tw-text-xl">{t("HOME.REGISTERED_SCHOOLS")}</h3>
        {season && <h3 className="green-sub tw-mb-14 tw-text-lg">{t("HOME.CURRENT_YEAR").replace("##", season)}</h3>}
        <div className="card-container">
          <div className="HS2P-img">
            <div className="HS2P-container">
              <p className="num tw-mb-5">{schools}</p>

              <p className="Statu tw-mb-4">{t("HOME.SCHOOL_TILL_NOW")}</p>

              {/* <p className="year">{t("HOME.SCHOOL_REGISTERED_YEAR")}</p> */}
              {season && <p className="year">{season}</p>}
              <div className="card-det-btn">
                <a href="/participating_schools" className="tw-flex tw-items-center tw-justify-between">
                  <p>{t("HOME.SHOW_ALL")}</p>
                  <SVG src={arrow} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schools;
