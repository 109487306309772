import { Modal } from 'react-bootstrap'

interface DialogBoxProps {
  showDialog: any
  cancelNavigation: any
  confirmNavigation: any
}

const DialogBox: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  return (
    <Modal show={showDialog} className="db-modal confirm-modal" centered={true}>
      <Modal.Body>
        <div className="title">إنتباه</div>
        <div className="description mt-2">
          لقد قمت ببعض التغييرات<br />
          هل أنت متأكد من مغادرة الصفحة؟
        </div>  
        <div className="d-flex mt-4">
          <button className="btn-c btn-cancel w-50 me-2" type="button" onClick={confirmNavigation}>مغادرة</button>
          <button className="btn-c btn-green w-50" onClick={cancelNavigation}>البقاء</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default DialogBox