import React, { FC, useState, useEffect } from "react";
import api from "../../../api";
import RegisteredSchoolsChart from "../../../components/DashboardWidgets/RegisteredSchoolsChart";
import SideWidget from "../../../components/DashboardWidgets/SideWidget";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { Season } from "../../../models/season";
import RegistrationRequests from "../RegistrationRequests";
import { BsChevronDown, BsCheck } from "react-icons/bs";

import "./dashboard.scss";
import DetailedSchoolsChart from "../../../components/DashboardWidgets/DetailedSchoolsChart";
import { IRegistrationsEachWeekDay } from "../../../store/types";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import LastWinners from "./LastWinners";

interface Stats {
  schools: number
  inactive_schools: number
  seasons: number 
  admins: number
}

const Dashboard: FC = () => {
  const t = useTranslation();
  const [showDropdown, setShowDropdown] = useState("close");
  const [stats, setStats] = useState<Stats|null>(null);
  const [currentSeason, setCurrentSeason] = useState<(Season & { registration_requests_count: number; year: string }) | undefined>();
  const [registrationEachYear, setRegistrationEachYear] = useState<(Season & { registration_requests_count: number; year: string })[]>();
  const [registrationEachWeek, setRegistrationEachWeek] = useState<IRegistrationsEachWeekDay[] | undefined>();
  const [registrationEachDay, setRegistrationEachDay] = useState<IRegistrationsEachWeekDay[] | undefined>();
  const [lastResults, setLastResults] = useState<Season | null>(null);

  const getStats = async () => {
    let { data } = await api.get("/stats/");
    let res = await api.get("/seasons/last_results");
    setLastResults(res.data);
    setStats(data);
  };

  const getRegistrationsEachYear = async () => {
    let { data } = await api.get("/seasons/registrations_each_year");
    setRegistrationEachYear(data);
    setCurrentSeason(data[0]);
  };

  const getRegistrationsEachWeek = async () => {
    let { data } = await api.get(`/seasons/${currentSeason?.id}/registrations_each_week`);
    setRegistrationEachWeek(data.reverse());
  };

  const getRegistrationsEachDay = async () => {
    let { data } = await api.get(`/seasons/${currentSeason?.id}/registrations_each_day`);
    setRegistrationEachDay(data.reverse());
  };

  const toggleMenu = () => {
    setShowDropdown((s) => (s === "close" ? "open" : "close"));
  };

  const closeMenu = () => {
    setShowDropdown("close");
  };

  useEffect(() => {
    getStats();
    getRegistrationsEachYear();
  }, []);

  useEffect(() => {
    if (currentSeason) {
      getRegistrationsEachDay();
      getRegistrationsEachWeek();
      closeMenu();
    }
    // eslint-disable-next-line
  }, [currentSeason]);

  const getDiff = (original: any[]): any => {
    let daysDiff = 0
    let regs = JSON.parse(JSON.stringify(original)).reverse()
    let idx = regs.findIndex((season: any) => season.year === currentSeason!.year)
    if(idx < 1 )
      return { days: 0, percentage: 0 }
    let prev = regs[idx - 1]
    daysDiff = currentSeason!.registration_requests_count - prev.registration_requests_count
    let percentage = prev.registration_requests_count > 0 ? (daysDiff * 100) / prev.registration_requests_count : 0
    return { days: daysDiff, percentage: parseFloat(percentage.toPrecision())}
  }

  if (!stats || !registrationEachYear || !lastResults ) return <LoadingSpinner />;

  let { days, percentage } = getDiff(registrationEachYear)

  return (
    <div className="dashboard-page">
      {/* Cards */}
      <div className="row mb-5">
        <div className="col-sm-6 col-md-3 mt-3">
          <SideWidget title="عدد المدارس" color="green" icon="graduation" value={stats.schools} />
        </div>
        <div className="col-sm-6 col-md-3 mt-3">
          <SideWidget title="مدارس غير نشطة" color="yellow" icon="school" value={stats.inactive_schools} />
        </div>
        <div className="col-sm-6 col-md-3 mt-3">
          <SideWidget title="عدد المواسم" color="yellow" icon="calendar" value={stats.seasons} />
        </div>
        <div className="col-sm-6 col-md-3 mt-3">
          <SideWidget title="عدد المشرفين" color="grey" icon="users" value={stats.admins} />
        </div>
      </div>

      <div className="row">
          {/* Title */}
        <div className="col-12 d-flex justify-content-between align-items-center">
          <div className="tw-flex tw-flex-col sm:tw-flex-row gap-1">
            <div className="font-weight-bolder tw-text-xl md:tw-text-2xl">{t("DASHBOARD.TITLE")}</div>
            <div className="season-container d-flex align-items-center gap-2 text-green" onClick={() => toggleMenu()}>
              <span className="font-weight-boldest tw-text-xl md:tw-text-2xl">{currentSeason?.year}</span>
              <BsChevronDown className="font-weight-boldest font-size-h6 mt-1" />
              <div className={`season-dropdown ${showDropdown === "open" ? "show" : "closed"}`}>
                <ul>
                  {registrationEachYear?.map((item, index) => (
                    <li
                      key={index}
                      className={`${item.year === currentSeason?.year ? "active" : ""}`}
                      onClick={() => setCurrentSeason(registrationEachYear.find((season) => season.year === item.year))}>
                      <span>{item.year}</span>
                      <span className="checkmark">
                        <BsCheck className="font-size-h4" />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-3 col-xl-5 col-xxl-4 d-flex flex-column justify-content-between">
          <div className="mt-3">
            <SideWidget title="التسجيلات الجديدة" color={percentage < 0 ? "red" : "green"} icon={percentage < 0 ? "down" : "up"} 
              value={days} percentage={percentage} large />
          </div>
          <div className="mt-3">
            <SideWidget title="المدارس المشاركة" color="yellow" icon="school" value={currentSeason!.registration_requests_count} large />
          </div>
        </div>
        <div className="col-xs-9 col-xl-7 col-xxl-8">
          <DetailedSchoolsChart
            registrationEachWeek={registrationEachWeek}
            registrationEachDay={registrationEachDay}
            startDate={currentSeason!.startDate}
            baseColor="#909537"
            lightColor="#eb9c3f"
          />
        </div>
        <div className="col-12 mt-5">
          <div className={`font-weight-bolder tw-text-xl md:tw-text-2xl mb-3`}>{t("DASHBOARD.REGISTERED_SCHOOLS_CHART_TITLE")}</div>
          <RegisteredSchoolsChart data={registrationEachYear} baseColor="#eb9c3f" lightColor="#909537" />
        </div>
        {lastResults.year && <LastWinners winners={lastResults.registration_requests} year={lastResults.year} /> }
        <div className="col-12 mt-5">
            <RegistrationRequests dashboardVersion />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
