import React, { FC } from 'react';
import SelectSearch, { SelectSearchOption } from 'react-select-search'

interface IProps {
    options: SelectSearchOption[]
    selectedSchool: number|string
    setData: Function
}

const SelectSchool: FC<IProps> = ({ options, selectedSchool, setData }) => {

    const handleChange = (...args: any) => {
        setData((prevData: any) => ({ ...prevData, school_id: args[0] }))
    }
    
    const handleFilter = (items: SelectSearchOption[]) => {
        return (searchValue: string) => {
            return items.filter(item => 
                item.name.toLowerCase().includes(searchValue.toLowerCase())
            )
        }
    }
    
    return (
        <SelectSearch
            options={options}
            value={selectedSchool.toString()}
            filterOptions={handleFilter}
            placeholder="إختر المدرسة"
            search
            onChange={handleChange}
        />
    )
}

export default SelectSchool;
