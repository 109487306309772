import React, { FC, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import Form from "react-bootstrap/Form";
import useTranslation from "../../../i18n/hooks/useTranslation";
import api from "../../../api";
import DBInput from "../../../components/DBInput";
import Up from "../../../assets/icons/caret-up.svg";
import Down from "../../../assets/icons/caret-down.svg";
import moment from "moment";

interface IProps {
  setShown: (modal: string) => void;
  shown: string;
  seasonAdded: () => void;
}

const AddSeason: FC<IProps> = ({ setShown, shown, seasonAdded }) => {
  const t = useTranslation();

  const initState = {
    year: 2022,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  };

  const [data, setData] = useState<any>(initState);

  const handleClose = () => setShown("");

  const handleChange = (event: React.ChangeEvent<any>) => {
    event.persist();
    setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }));
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    const toastId = toast.loading(t("LOADING"));
    let { year, startDate, startTime, endDate, endTime } = data;

    let diff = moment().diff(moment(`${startDate} ${startTime}:00`), "minutes");
    if (diff >= 0) return toast.error(t("ERROR.ENTER_VALID_DATES"), { id: toastId });

    diff = moment(`${endDate} ${endTime}:00`).diff(moment(`${startDate} ${startTime}:00`), "minutes");
    if (diff <= 0) return toast.error(t("ERROR.ENTER_VALID_DATES"), { id: toastId });

    const body = JSON.stringify({
      year: `${year}-${year + 1}`,
      startDate: `${startDate} ${startTime}:00`,
      endDate: `${endDate} ${endTime}:00`,
    });
    try {
      await api.post("/seasons", body);
      toast.success(t("MANAGE_SEASONS.SEASON_ADDED"), { id: toastId });
      seasonAdded();
    } catch (err: any) {
      toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
    }
  };

  return (
    <Modal show={shown === "add"} centered={true} className="db-modal add-season" onHide={handleClose}>
      <Form onSubmit={submitHandler}>
        <Modal.Body className="p-3">
          <div className="title">إضافة موسم</div>
          <div className="row">
            <div className="mb-4 col-12">
              <label htmlFor="year" className="mb-2">
                {t("MANAGE_SEASONS.EDUCATIONAL_YEAR")}
              </label>
              <div className="year-input-cont d-flex">
                <div className="ms-2 arrows my-auto d-flex flex-column">
                  <img src={Up} alt="up" onClick={() => setData({ ...data, year: 1 + data.year })} />
                  <img src={Down} alt="Down" onClick={() => setData({ ...data, year: data.year - 1 })} />
                </div>
                <div className="separator my-auto mx-2" style={{ height: "40%" }}></div>
                <input
                  type="number"
                  className="my-auto"
                  min={2022}
                  placeholder={t("YEAR")}
                  name="year"
                  id="year"
                  value={data.year}
                  onChange={handleChange}
                  required
                />
                <div className="separator my-auto"></div>
                <div className="next-year my-auto mx-4">{1 + data.year}</div>
              </div>
            </div>
            <div className="col-6 mb-4">
              <label htmlFor="startDate" className="mb-2">
                تاريخ فتح طلبات التسجيل
              </label>
              <DBInput
                type="date"
                min={new Date().toISOString().split("T")[0]}
                name="startDate"
                id="startDate"
                value={data.startDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-6 mb-4">
              <label htmlFor="startTime" className="mb-2">
                توقيت فتح طلبات التسجيل
              </label>
              <DBInput type="time" name="startTime" id="startTime" value={data.startTime} onChange={handleChange} required />
            </div>
            <div className="col-6 mb-4">
              <label htmlFor="endDate" className="mb-2">
                تاريخ إغلاق طلبات التسجيل
              </label>
              <DBInput
                type="date"
                min={new Date().toISOString().split("T")[0]}
                name="endDate"
                id="endDate"
                value={data.endDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-6 mb-4">
              <label htmlFor="endTime" className="mb-2">
                توقيت إغلاق طلبات التسجيل
              </label>
              <DBInput type="time" name="endTime" id="endTime" value={data.endTime} onChange={handleChange} required />
            </div>
          </div>

          <div className="d-flex">
            <button className="btn-c btn-green w-80 me-2" type="submit" disabled={!data.year}>
              إضافة الموسم الجديد
            </button>
            <button className="btn-c btn-cancel w-20" type="button" onClick={handleClose}>
              {t("CANCEL")}
            </button>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default AddSeason;
