import React, { FC, useState } from "react";
import { RegistrationRequest } from "../../../models/registration-request";
import Fplace from "../../../assets/icons/Fplace.png";
import Tplace from "../../../assets/icons/Tplace.png";
import Splace from "../../../assets/icons/Splace.png";
import SchoolImage from "../../../components/SchoolImage";
import MaleG from "../../../assets/icons/MaleG.png";
import FemaleG from "../../../assets/icons/FemaleG.svg";
import Eye from "../../../assets/icons/eye.svg";
import { Link } from "react-router-dom";
import NumberControl from "./NumberControl";

interface IProps {
  sorted: RegistrationRequest[];
  idx: number;
  updateMode: boolean;
  updateValue: (id: number, score: number) => void;
}

const TableRow: FC<IProps> = ({ sorted, idx, updateValue, updateMode }) => {
  let { school, score, id } = sorted[idx];

  const [value, setValue] = useState(score);

  const saveValue = (val: number) => {
    updateValue(id, val || value);
  };
  
  return (
    <tr>
      <td>{idx + 1}</td>
      <td className="d-flex school-info">
        <SchoolImage className="my-auto img me-3" gender={school.gender} />
        <div className="my-auto">
          <div className="name">{school.name}</div>
          <div className="d-flex gender mt-1">
            <img
              src={school.gender === "ذكور" ? MaleG : FemaleG}
              alt="gender"
              className="my-auto me-1"
              style={{ width: school.gender === "ذكور" ? 12 : 18 }}
            />
            <span className="my-auto">{school.gender}</span>
          </div>
        </div>
      </td>
      <td className={`rating ${score === 0 && "empty"}`}>
        {updateMode ? (
          <div className="input-cont d-flex mx-auto">
            <NumberControl value={value} setValue={setValue} saveValue={saveValue} />
            <input
              type="number"
              min={1}
              className="my-auto"
              onChange={(e) => setValue(parseInt(e.currentTarget.value))}
              onBlur={(e) => saveValue(parseInt(e.currentTarget.value))}
              value={value}
              required
            />
          </div>
        ) : score === 0 ? (
          "في إنتظار التقييم"
        ) : (
          score
        )}
      </td>
      <td className="title">
        {idx < 3 ? <img className="m-auto" src={idx === 0 ? Fplace : idx === 1 ? Splace : Tplace} alt="place" /> : idx < 9 ? "افضل 9 مدارس" : "-"}
      </td>
      <td>
        <Link to={"/admin/schools/" + school.id} target="_blank">
          <img src={Eye} alt="show" />
        </Link>
      </td>
    </tr>
  );
};

export default TableRow;
