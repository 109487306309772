import React, { FC } from "react";

const AboutCards: FC = () => {
  return (
    <div className="sec1cards ">
      <div data-aos="fade-up" data-aos-duration="500" className="card">
        <h4>رؤية مبادرة المدارس المعززة للصحة (Vision)</h4>
        <p>النهوض بصحة الطلبة والمنتسبين للمجتمع المدرسي بصفة خاصة والمجتمع المحلي بصفة عامة</p>
      </div>
      <div data-aos="fade-up" data-aos-duration="1000" className="card wsec">
        <h4>رسالة المدرسة (Mission)</h4>
        <p>ربط الصحة بمحور العملية التعليمية و منتسبيها من معلمين و طلبة وأولياء أمور والمجتمع المحيط بالمدرسة.</p>
      </div>
      <div data-aos="fade-up" data-aos-duration="1500" className="card">
        <h4>الغايات ( الأهداف العامة) Goals</h4>
        <p>عامة وشاملة وصعبة القياس فيما يتعلق بالنتائج الكلية المطلوب تحقيقها</p>
        <h6>طويلة الأجل وغير محددة النهاية</h6>
      </div>
    </div>
  );
};

export default AboutCards;
