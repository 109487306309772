import { AuthState, AuthAction, SET_USER, SET_LOADING, LOG_OUT, LOGIN } from '../types';

const initState: AuthState = {
    user: null,
    authenticated: false,
    checkingToken: true
}

const authReducer = (state = initState, action: AuthAction) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                authenticated: true,
                checkingToken: false
            }
        case SET_LOADING:
            return {
                ...state,
                checkingToken: action.payload
            }
        case LOG_OUT:
            localStorage.removeItem("token");
            return {
                ...state,
                user: null,
                authenticated: false
            }
        case LOGIN:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                authenticated: true,
                user: action.payload.user
            }
        default:
            return state;
    }
}

export default authReducer;