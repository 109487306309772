import React, { FC, useState } from "react";
import Slider from "react-slick";
import { Media } from "../../../models/media";
import config from "../../../config";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const Photos: FC<{ images: Media[] }> = ({ images }) => {
  // const sliderRef = useRef();
  const [slider, setSlider] = useState<Slider | null>();
  const [showImage, setShowImage] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [dragging, setDragging] = useState(false);

  let imgsLenth = images.length;

  const settings = {
    className: "center",
    centerMode: imgsLenth <= 1,
    centerPadding: "25vw",
    infinite: true,
    slidesToShow: imgsLenth < 5 ? imgsLenth : 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3500,
    cssEase: "ease-in-out",
    pauseOnHover: true,
    beforeChange: () => setDragging(true),
    afterChange: (current: number) => {
      setCurrentImage(images[current].original_url.replace("http://localhost", config.serverUrl));
      setDragging(false);
    },
    responsive: [
      {
        breakpoint: 1277,
        settings: {
          slidesToShow: imgsLenth < 4 ? imgsLenth : 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: imgsLenth < 4 ? imgsLenth : 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: imgsLenth < 3 ? imgsLenth : 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: imgsLenth < 2 ? imgsLenth : 2,
          centerMode: true,
          centerPadding: "30vw",
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: imgsLenth < 1 ? imgsLenth : 1,
          arrows: true,
          centerMode: true,
          centerPadding: "15%",
        },
      },
    ],
  };

  const handleImageClick = (imgSrc: string) => {
    if (!dragging) {
      setCurrentImage(imgSrc);
      setShowImage(true);
    }
  };

  const handleCloseFloatingGallery = () => {
    setCurrentImage("");
    setShowImage(false);
  };

  const handlePrevSlide = () => {
    slider?.slickPrev();
  };

  const handleNextSlide = () => {
    slider?.slickNext();
  };

  return (
    <div>
      <div
        className={`${
          showImage ? "" : "tw-hidden"
        } tw-z-[999] tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 w-screen h-screen tw-bg-black tw-bg-opacity-90 tw-transition-all tw-duration-500`}>
        <button
          type="button"
          onClick={() => handleCloseFloatingGallery()}
          className="tw-absolute tw-top-6 tw-right-6 tw-z-10 tw-bg-transparent tw-bg-gray-200 tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-0.5 tw-m-1 tw-ml-auto tw-inline-flex tw-items-center">
          <svg
            aria-hidden="true"
            className="tw-w-6 md:tw-w-8 tw-h-6 md:tw-h-8"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"></path>
          </svg>
        </button>
        <button
          type="button"
          onClick={() => handlePrevSlide()}
          className="tw-absolute tw-top-3/4 md:tw-top-1/2 tw-left-6 tw-z-10 tw-bg-transparent tw-bg-gray-200 tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1 tw-m-1 tw-ml-auto tw-inline-flex tw-items-center">
          <BsArrowLeft className="tw-text-2xl tw-text-black" />
        </button>
        <button
          type="button"
          onClick={() => handleNextSlide()}
          className="tw-absolute tw-top-3/4 md:tw-top-1/2 tw-right-6 tw-z-10 tw-bg-transparent tw-bg-gray-200 tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1 tw-m-1 tw-ml-auto tw-inline-flex tw-items-center">
          <BsArrowRight className="tw-text-2xl tw-text-black" />
        </button>
        <div className="floating-image-container tw-absolute tw-w-[90vw] lg:tw-w-[80vw] xl:tw-w-[60vw] tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
          <img src={currentImage} alt="" className="w-full h-full" />
        </div>
      </div>
      <div data-aos="fade-up" className="gallery mb-5">
        <Slider ref={(c) => setSlider(c)} {...settings}>
          {images.map((it, idx) => (
            <div key={"img-" + it.uuid}>
              <div className="d-flex tw-px-3 w-100 h-100">
                <img
                  className="tw-mx-auto"
                  src={it.original_url.replace("http://localhost", config.serverUrl)}
                  alt="school"
                  onClick={() => handleImageClick(it.original_url.replace("http://localhost", config.serverUrl))}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Photos;
