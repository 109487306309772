import React, { FC, useState } from "react";
import NewsCard from "../../../components/NewsCard";
import PublicPagination from "../../../components/PublicPagination";
import { News } from "../../../models/news";


const NewsList: FC<{ news: News[] }> = ({ news }) => {

    const [currentPage, setCurrentPage] = useState(1)
    const limit = 9;

    const currentData = news.slice(
        (currentPage - 1) * limit,
        (currentPage - 1) * limit + limit
    );
    
    return (
        <div className="row">
            {currentData.map((article, idx) => (
                <div className="col-12 col-md-4" key={"news-card-" + idx}>
                    <NewsCard {...article} />
                </div>
            ))}
            <div className="bottom">
                <PublicPagination 
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    limit={limit}
                    totalRows={news.length}
                />
            </div>
        </div>
    )
}

export default NewsList
