import React, { FC } from "react";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { RegistrationRequest } from "../../../models/registration-request";
import { Link } from "react-router-dom";
import ActionsMenu from "../../../components/ActionsMenu";
import toast from "react-hot-toast";
import api from "../../../api";

interface IProps {
  requests: RegistrationRequest[];
  canEdit: boolean;
  requestUpdated: (id: number, status: string) => void;
}

const RequestsTable: FC<IProps> = ({ requests, canEdit, requestUpdated }) => {
  const t = useTranslation();

  const changeStatus = async (id: number, status: string) => {
    const toastId = toast.loading(t("LOADING"));
    const body = JSON.stringify({ status });

    try {
      await api.put("/registration_requests/status/" + id, body);
      toast.success(t("SUCCESS.DONE"), { id: toastId });
      requestUpdated(id, status);
    } catch (err: any) {
      toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
    }
  };

  return (
    <>
      <table className="db-table">
        <thead>
          <tr>
            <th scope="col">المدرسة</th>
            <th scope="col">{t("EMAIL")}</th>
            <th scope="col">{t("PHONE_NUMBER")}</th>
            <th scope="col">الحالة</th>
            {canEdit && <th scope="col"></th>}
          </tr>
        </thead>
        <tbody>
          {requests.map((req) => (
            <tr key={"req-" + req.id}>
              <td className="school-name">
                <Link target={"_blank"} to={"/admin/schools/" + req.school?.id}>
                  {req.school?.name}
                </Link>
              </td>
              <td>{req.email}</td>
              <td className="ltr">{req.phone}</td>
              <td className="d-fkex">
                <div className={`m-auto d-flex align-items-center justify-content-center status-badge ${req.status}`}>
                  {req.status === "refused" ? "مرفوض" : req.status === "accepted" ? "مقبول" : "قيد الانتظار"}
                </div>
              </td>
              {canEdit && (
                <td>
                  <ActionsMenu
                    actions={[
                      ...(req.status !== "accepted"
                        ? [
                            {
                              title: "قبول",
                              function: () => changeStatus(req.id, "accepted"),
                            },
                          ]
                        : []),
                      ...(req.status !== "refused"
                        ? [
                            {
                              title: "رفض",
                              function: () => changeStatus(req.id, "refused"),
                            },
                          ]
                        : []),
                    ]}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default RequestsTable;
