import React, { FC } from "react";
import { RegistrationRequest } from "../../../models/registration-request";
import Fplace from "../../../assets/icons/Fplace.png";
import Tplace from "../../../assets/icons/Tplace.png";
import Splace from "../../../assets/icons/Splace.png";

const Certs: FC<{ requests: RegistrationRequest[] }> = ({ requests }) => {
  return (
    <div className="db-card mb-4 !tw-px-4">
      <h4 className="mb-4">الشهادات</h4>
      <div className="tw-flex tw-flex-col tw-gap-3">
        {requests.length === 0 ? (
          <p className="no-certs">لا توجد اي شهادات مكتسبة حتى الان</p>
        ) : (
          requests.map((p) => (
            <div className="medal-card tw-flex tw-justify-between tw-items-center py-4 px-2" key={"medal-" + p.id}>
              <div className="tw-flex tw-items-center tw-gap-3">
                <img className="" src={p.rank === 1 ? Fplace : p.rank === 2 ? Splace : Tplace} alt="medal" />
                <div className="">
                  <p className="DegTitle tw-mb-2">
                    {p.rank === 1 ? "الشهادة الذهبية" : p.rank === 2 ? "الشهادة الفضية" : p.rank === 3 ? "الشهادة البرونزية" : ""}
                  </p>
                  <p className="">صالحة لمدة 3 سنوات</p>
                </div>
              </div>
              <div className="">
                <p className="Degyear">سنة {p.season.year}</p>
                <p className="staut" style={{ color: "black" }}>
                  نشطة
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Certs;
