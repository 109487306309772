import React, { FC, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import api from "../../api";
import { RootState } from "../../store";
import Icon from "./Icon";
import Logo from "./logo.svg";

interface SidebarItem {
    link: string;
    title: string;
    icon: string;
    badge?: number;
}

const Sidebar: FC = () => {

    const { pathname } = useLocation();

    const { user } = useSelector((state: RootState) => state.auth);
    const [registrationReqCount, setRegistrationReqCount] = useState(0);

    const getNewRegistrationRequestsCount = async () => {
        let { data } = await api.get("/registration_requests/pending_count");
        setRegistrationReqCount(data);
    };

    useEffect(() => {
        getNewRegistrationRequestsCount();
    }, [pathname]);

    let hasFullAccess = user!.type === 1 || user!.ministry === "HEALTH"

    return (
        <div className="admin-sidebar d-flex flex-column">
            <img src={Logo} alt="Logo" className="mx-auto mb-2" />
            <hr className="my-4" />
            <div className="px-4">
                {hasFullAccess && <Item title="لوحة التحكم" icon="dashboard" link="/admin/dashboard" />}
                {hasFullAccess && <Item title="إدارة المحتوى" icon="content" link="/admin/content" />}
                {hasFullAccess && <Item title="إدارة المواسم" icon="seasons" link="/admin/seasons" />}
                {hasFullAccess && <Item title="إدارة البريد الإلكتروني" icon="emails" link="/admin/emails" />}
                {hasFullAccess && <Item title="إدارة المشرفين" icon="admins" link="/admin/admins" />}
                <Item title="إدارة المدارس" icon="schools" link="/admin/schools" />
                {user!.ministry !== "EDUCATION" && <Item title="النتائج" icon="results" link="/admin/results" />}
                <Item
                    title="طلبات التسجيل الجديدة"
                    icon="new_reqs"
                    link="/admin/new_requests"
                    {...(registrationReqCount && { badge: registrationReqCount })}
                />
                <Item title="سجل طلبات التسجيل" icon="reqs_history" link="/admin/requests_history" />
                {hasFullAccess && <Item title="إدارة الأخبار" icon="content" link="/admin/news" />}
            </div>
        </div>
    );
};

const Item: FC<SidebarItem> = ({ title, icon, link, badge }) => {
    const { pathname } = useLocation();
    let active = pathname === link;

    return (
        <Link to={link} className={`side-item d-flex mb-4 ${active && "active"}`}>
            <div className="icon my-auto me-2">
                <Icon icon={icon} active={active} />
            </div>
            <div className="title my-auto">
                {title}
                {badge && <Badge bg="secondary">{badge}</Badge>}
            </div>
        </Link>
    );
};

export default Sidebar;
