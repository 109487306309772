import React, { FC, useState } from "react";
import Illustration from "../../../assets/illustrations/no_registered_schools.svg";
import PublicPagination from "../../../components/PublicPagination";
import SchoolCard from "../../../components/SchoolCard";
import { School } from "../../../models/school";


const Schools: FC<{ schools: School[] }> = ({ schools }) => {

    const [currentPage, setCurrentPage] = useState(1)
    const limit = 12;

    const currentData = schools.slice(
        (currentPage - 1) * limit,
        (currentPage - 1) * limit + limit
    );
    
    return (
        <div className="col-12 col-lg-9">
            <div className="row">
                {schools.length === 0 ? (
                    <div className="no-schools d-flex flex-column">
                    <img src={Illustration} className="mx-auto mb-3" alt="No registered schools" />
                    <p className="text-center mx-auto">لا توجد أي مدارس مشاركة </p>
                    </div>
                ) : (
                    currentData.map((school, idx) => (
                    <div className="col-6 col-md-4" key={`school-${idx}-${school.id}`}>
                        <SchoolCard {...school} />
                    </div>
                    ))
                )}
            </div>
            <div className="bottom">
                <PublicPagination 
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    limit={limit}
                    totalRows={schools.length}
                />
            </div>
        </div>
    )
}

export default Schools
