import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ContainSection.css";
import col1 from "../../../assets/images/eval_methods/col1.png";
import col2 from "../../../assets/images/eval_methods/col2.png";
import col3 from "../../../assets/images/eval_methods/col3.png";
import col4 from "../../../assets/images/eval_methods/col4.png";
import col5 from "../../../assets/images/eval_methods/col5.png";
import col6 from "../../../assets/images/eval_methods/col6.png";
import a7 from "../../../assets/images/eval_methods/5a7.png";
import a6 from "../../../assets/images/eval_methods/4a6.png";
import a5 from "../../../assets/images/eval_methods/3a5.png";

function Co1() {
  return (
    <section data-aos="fade-up" id="section1" className="tw-pb-20">
      <h3 data-aos="fade-up" className="secTitle tw-text-2xl">العصف الذهني</h3>
      <p data-aos="fade-up" className="secDesc">
        يعتبر العصف الذهني من أكثر التقنيات شيوعا و استخداما ويهدف إلى الحصول على أكبر عدد ممكن من الأفكار والآراء والكلمات المتعلقة بموضوع معين وينمي
        القدرات الإبداعية للمشاركين.
      </p>
      <div data-aos="fade-up" className="colcard">
        <Container>
          <Row>
            <Col xs={8}>
              <div data-aos="fade-up" className="tw-flex tw-gap-3 tw-translate-x-6">
                <img data-aos="fade-up" className="tw-w-6" src={a7} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">طريقة تنفيذ التقنية</h1>
              </div>
              <div data-aos="fade-up" className="whitegreen tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p  >تحضير الفريق من 8-12 مشاركاً</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>مراعاة وجود تجالس علمي و فكري بين المشاركين مع إمكانية وجود بعض الأشخاص من خارج اختصاص و عمل المجموعة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>طريقة الجلوس تسمح برؤية الجميع مع وجود المنسق في مكان يمكنه من إدارة الجلسة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>عرض الموضوع بواسطة سؤال واحد وواضح</p>
                  </li>
                </ul>
                <p data-aos="fade-up" >
                  <p  data-aos="fade-up" className="tw-underline tw-font-semibold">مثال :</p>
                  &nbsp; بماذا توحي الكلمة التي قالها لهم ، أو ما هو أول شيء يخطر على بالهم عند سماعهم الكلمة التي قالها
                </p>
                <ul>
                  <li data-aos="fade-up" >
                    <p>يقوم المدرب أو من يعاونه بكتابة كل ما يقوله المشاركون بدون إبداء أي ملاحظات أو رفض أية آراء أو حتى انتقادها</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تصنيف الأفكار المطروحة و مناقشتها و ترتيبها حسب الهدف من العمل</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تتراوح الفترة الزمنية لعملية العصف الذهني بين الثلاث والسبع دقائق على الأكثر</p>
                  </li>
                </ul>
              </div>

              <div data-aos="fade-up"  className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a6} alt="" />
                <h1 data-aos="fade-up"  className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">أهمية التقنية</h1>
              </div>
              <div  data-aos="fade-up" className="whiteyelow tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p>تسمح لجميع أعضاء المجموعة بالمشاركة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تشجع الجميع على التعبير وخاصة الذين يعتبرون آراءهم غير مهمة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تسمح للمدرب والمجموعة الحصول على كمية كبيرة من المعلومات</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تشجع عملية الإبداع والخيال</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تساعد في تحسين الأفكار و تطويرها من قبل المشاركين</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <img  data-aos="fade-up" className="c1img" src={col1} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function Co2() {
  return (
    <section id="section2" className="tw-pb-20">
      <h3 data-aos="fade-up" className="secTitle tw-text-2xl">عمل المجموعات</h3>
      <p  data-aos="fade-up" className="secDesc">
        ‎يعتبر عمل المجموعات نشاطا مهما في عملية التدريب حيث يقوم على مبدأ تقسيم المجموعة الكبيرة إلى مجموعات عمل صغيرة تناقش من خلالها كل مجموعة
        موضوعا محددا أو قد تشترك في تمرين معين. يتراوح عدد الأشخاص بالمجموعة الصغيرة من أربعة إلى ثمانية أفراد
      </p>
      <div className="colcard">
        <Container>
          <Row>
            <Col xs={8}>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a7} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">طريقة تنفيذ التقنية</h1>
              </div>
              <div className="whitegreen tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p>تقديم نبذة موجزة عن الموضوع الذي سيتم العمل عليه</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تحديد الهدف من عمل المجموعات</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> عرض طريقة العمل والمطلوب من كل مجموعة عمله والوقت المخصص له</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> توزيع المشاركين إلى مجموعات عمل حسب التخصص أو بطريقة عشوائية</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تحديد ميسر و مقرر لكل مجموعة عمل</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>بدء عمل المجموعات في الوقت المخصص</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> يعرض مقرر كل مجموعة نتائج العمل </p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> يبدأ النقاش ويقوم المدرب يإدارة النقاش</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تحلل المعلومات و تستخلص النتائج</p>
                  </li>
                </ul>
              </div>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a6} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">أهمية التقنية</h1>
              </div>
              <div className="whiteyelow tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p> تعزيز مهارات الاتصال و التواصل</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>يشعر المتدربون باستقلالهم عن المدرب حيث تقوم المجموعة بتحديد طريقة العمل التي تناسبها</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تنمية روح التعاون و العمل ضمن فريق</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تخلق روح المنافسة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>إكساب المتدربين تقنيات التحريك، صياغة الأفكار</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تضفي جواً من المرح</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <img  data-aos="fade-up" className="c2img" src={col2} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function Co3() {
  return (
    <section id="section3" className="tw-pb-20">
      <h3  data-aos="fade-up" className="secTitle tw-text-2xl">إلقاء المحاضرات</h3>
      <p  data-aos="fade-up" className="secDesc">
        هي إحدى طرق الأسلوب التلقيني و تعتمد على إلقاء الموضوع من قبل المدرب حيث تساعد هذه الطريقة في إيصال كم من المعلومات لعدد من المشاركين في وقت
        قصير
      </p>
      <div className="colcard">
        <Container>
          <Row>
            <Col xs={8}>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a7} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">طريقة تنفيذ التقنية</h1>
              </div>
              <div className="whitegreen tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p>يقوم المدرب بتحضير محتوى المحاضرة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> يقدم المدرب المحاضرة في الوقت المحدد ويفضل استخدام الوسائل السمعية البصرية</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>يسمح بالنقاش بعد المحاضرة</p>
                  </li>
                </ul>
              </div>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a6} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">أهمية التقنية</h1>
              </div>
              <div className="whiteyelow tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p>تساعد في إيصال كمية من المعلومات لعدد من المشاركين في وقت قصير</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> من السهل تحضيرها و تنظيمها</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تشجع على المناقشة</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <img  data-aos="fade-up" className="c3img" src={col3} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function Co4() {
  return (
    <section id="section4" className="tw-pb-20">
      <h3 data-aos="fade-up" className="secTitle tw-text-2xl">المناقشات الجماعية</h3>
      <p  data-aos="fade-up" className="secDesc">تعتمد هذه التقنية على طرح موضوع للنقاش و طرح أسئلة مرتبطة بالموضوع</p>
      <div className="colcard">
        <Container>
          <Row>
            <Col xs={8}>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a7} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">طريقة تنفيذ التقنية</h1>
              </div>
              <div className="whitegreen tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p> يقوم المدرب بتحضير الموضوع المراد مناقشته و الأسئلة التي سيتم طرحها للنقاش.</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> يحدد المدرب قواعد النقاش: الوقت ،الاستئذان قبل المشاركة في النقاش، الاستماع إلى كافة الآراء و احترامها</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> يعرض المدرب الموضوع على المجموعة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>يطرح المدرب الأسئلة و تتم مناقشتها</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تسجل الآراء و الأفكار التي يتم طرحها على لوحة قلابة ويمكن تصنيفها و ترتيبها حسب هدف الجلسة</p>
                  </li>
                </ul>
              </div>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a6} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">أهمية التقنية</h1>
              </div>
              <div className="whiteyelow tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p> تعزز المشاركة و تبادل الخبرات</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تساعد المجموعة على احترام و تقدير وجهات النظر المختلفة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تنمي مهارات الاتصال و التواصل </p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تسمح للمشاركين بالمشاركة ضمن الوقت المحدد</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <img  data-aos="fade-up" className="c4img" src={col4} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function Co5() {
  return (
    <section id="section5" className="tw-pb-20">
      <h3  data-aos="fade-up" className="secTitle tw-text-2xl">لعبة الدور</h3>
      <p  data-aos="fade-up" className="secDesc">
        ‎هو أسلوب تدريبي يجسد فيه المشاركون أدوارا معينة من أجل التعرف على مشكلات لها خصائص ومراحل محددة بنص مكتوب و تعتبر لعبة الدور من أهم التقنيات
        التدريبية لإيصال سلوك أو مهارة معينة مطلوب من المتدرب اكتسابها
      </p>
      <div className="colcard">
        <Container>
          <Row>
            <Col xs={8}>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img className="tw-w-6" src={a7} alt="" />
                <h1 className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">طريقة تنفيذ التقنية</h1>
              </div>
              <div className="whitegreen tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p>تتطلب تقلية لعبة الدور تحضيرا جيدا من قبل المتدربين</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تهيئة المجموعة جيدا من قبل المدرب قبل البدء بها</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> يتم توزيع النص الذي يصف الحالة المطلوب لعبها وتنشيط نقاش بالمجموعة حول الحالة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تنسجم المجموعة مع الوضع المطلوب تجسيده بلعبة الدور</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تقوم المجموعة بلعب الدور عندما تكون جاهزة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> اختيار مراقبين لمراقبة اللعبة وكتابة ملاحظات على ما تم إنجازه</p>
                  </li>
                </ul>
              </div>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a6} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">أهمية التقنية</h1>
              </div>
              <div className="whiteyelow tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p>تشجع على الإبداع والمشاركة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تساعد على اكتشاف نقاط القوة و الضعف في الأنماط السلوكية</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تنمي القدرة على التحكم في الأحاسيس و السيطرة على بعض المواقف</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تخلق روح المنافسة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تدرب على مواجهة التغيرات</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تؤثر على المستوى المعرفي و الوجداني و السلوكي</p>
                  </li>
                </ul>
              </div>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a5} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">دور المدرب</h1>
              </div>
              <div className="whiteyelow tw-mt-6 tw-mb-8">
                <ul className="graylist">
                  <li data-aos="fade-up" >
                    <p>تشجع على الإبداع والمشاركة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تساعد على اكتشاف نقاط القوة و الضعف في الأنماط السلوكية</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تنمي القدرة على التحكم في الأحاسيس و السيطرة على بعض المواقف</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تخلق روح المنافسة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> تدرب على مواجهة التغيرات</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تؤثر على المستوى المعرفي و الوجداني و السلوكي</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <img  data-aos="fade-up" className="c5img" src={col5} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function Co6() {
  return (
    <section id="section6" className="tw-pb-20">
      <h3  data-aos="fade-up" className="secTitle tw-text-2xl">دراسة الحالة</h3>
      <p  data-aos="fade-up" className="secDesc">
        هي إحدى تقنيات التدريب الناشطة وهي تقوم على اختيار وضع أو حالة أو مشكلة متصلة بالواقع المعاش لدى المشاركين، بهدف دراستها وتحليلها من قبلهم.
      </p>
      <div className="colcard">
        <Container>
          <Row>
            <Col xs={8}>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a7} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">طريقة تنفيذ التقنية</h1>
              </div>
              <div className="whitegreen tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p>تحديد الحالة مسبقا أو أثناء اللقاء مع المجموعة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> شرح التمرين و تحديد الفترة الزمنية لدراسة الحالة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>قراءة الحالة مع المجموعة و عرض الأسئلة المطلوب مناقشتها</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> دراسة الحالة إما فرديا أو ضمن المجموعة</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>عرض نتائج العمل</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p> مناقشة النتائج و تحليلها مع المجموعة</p>
                  </li>
                </ul>
              </div>
              <div className="tw-flex tw-gap-3 tw-translate-x-6">
                <img  data-aos="fade-up" className="tw-w-6" src={a6} alt="" />
                <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-xl tw-font-bold">أهمية التقنية</h1>
              </div>
              <div className="whiteyelow tw-mt-6 tw-mb-8">
                <ul>
                  <li data-aos="fade-up" >
                    <p> تساعد على حل مشكلة قريبة من الواقع الذي يعيشه المتدربون</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تستخدم المعارف والخبرات الموجودة عند المتدربين </p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تنمية مهارات التحليل باستعمال التفكير والتحليل المنطقي والعقلاني</p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تؤدي إلى تنظيم استخدام الخبرات السابقة لدى المشاركين </p>
                  </li>
                  <li data-aos="fade-up" >
                    <p>تؤدي إلى مشاركة الجميع وتبرز وجهات نظر عديدة</p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <img  data-aos="fade-up" className="c6img" src={col6} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function MCo1() {
  return (
    <section id="Msection1">
      <h3  data-aos="fade-up" className="secTitle tw-text-lg">العصف الذهني</h3>
      <p  data-aos="fade-up" className="secDesc">
        يعتبر العصف الذهني من أكثر التقنيات شيوعا و استخداما ويهدف إلى الحصول على أكبر عدد ممكن من الأفكار والآراء والكلمات المتعلقة بموضوع معين وينمي
        القدرات الإبداعية للمشاركين.
      </p>
      <div className="center-img">
        <img  data-aos="fade-up" className="c1img" src={col1} alt="" />
      </div>
      <div className="colcard">
        <Container>
          <Row>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a7} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">طريقة تنفيذ التقنية</h1>
            </div>
            <div className="whitegreen tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p>تحضير الفريق من 8-12 مشاركاً</p>
                </li>
                <li data-aos="fade-up" >
                  <p>مراعاة وجود تجالس علمي و فكري بين المشاركين مع إمكانية وجود بعض الأشخاص من خارج اختصاص و عمل المجموعة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>طريقة الجلوس تسمح برؤية الجميع مع وجود المنسق في مكان يمكنه من إدارة الجلسة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>عرض الموضوع بواسطة سؤال واحد وواضح</p>
                </li>
              </ul>
              <p  data-aos="fade-up" className="tw-text-[10pt] tw-translate-x-4">
                <p  data-aos="fade-up" className="tw-underline tw-font-semibold tw-text-[10pt]">مثال :</p>
                &nbsp; بماذا توحي الكلمة التي قالها لهم ، أو ما هو أول شيء يخطر على بالهم عند سماعهم الكلمة التي قالها
              </p>
              <ul>
                <li data-aos="fade-up" >
                  <p>يقوم المدرب أو من يعاونه بكتابة كل ما يقوله المشاركون بدون إبداء أي ملاحظات أو رفض أية آراء أو حتى انتقادها</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تصنيف الأفكار المطروحة و مناقشتها و ترتيبها حسب الهدف من العمل</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تتراوح الفترة الزمنية لعملية العصف الذهني بين الثلاث والسبع دقائق على الأكثر</p>
                </li>
              </ul>
            </div>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a6} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">أهمية التقنية</h1>
            </div>
            <div className="whiteyelow tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p>تسمح لجميع أعضاء المجموعة بالمشاركة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تشجع الجميع على التعبير وخاصة الذين يعتبرون آراءهم غير مهمة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تسمح للمدرب والمجموعة الحصول على كمية كبيرة من المعلومات</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تشجع عملية الإبداع والخيال</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تساعد في تحسين الأفكار و تطويرها من قبل المشاركين</p>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function MCo2() {
  return (
    <section id="Msection2" className="tw-pt-8">
      <h3  data-aos="fade-up" className="secTitle tw-text-lg">عمل المجموعات</h3>
      <p  data-aos="fade-up" className="secDesc">
        ‎يعتبر عمل المجموعات نشاطا مهما في عملية التدريب حيث يقوم على مبدأ تقسيم المجموعة الكبيرة إلى مجموعات عمل صغيرة تناقش من خلالها كل مجموعة
        موضوعا محددا أو قد تشترك في تمرين معين. يتراوح عدد الأشخاص بالمجموعة الصغيرة من أربعة إلى ثمانية أفراد
      </p>
      <div className="center-img">
        <img  data-aos="fade-up" className="c2img" src={col2} alt="" />
      </div>
      <div className="colcard">
        <Container>
          <Row>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a7} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">طريقة تنفيذ التقنية</h1>
            </div>
            <div className="whitegreen tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p>تقديم نبذة موجزة عن الموضوع الذي سيتم العمل عليه</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تحديد الهدف من عمل المجموعات</p>
                </li>
                <li data-aos="fade-up" >
                  <p> عرض طريقة العمل والمطلوب من كل مجموعة عمله والوقت المخصص له</p>
                </li>
                <li data-aos="fade-up" >
                  <p> توزيع المشاركين إلى مجموعات عمل حسب التخصص أو بطريقة عشوائية</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تحديد ميسر و مقرر لكل مجموعة عمل</p>
                </li>
                <li data-aos="fade-up" >
                  <p>بدء عمل المجموعات في الوقت المخصص</p>
                </li>
                <li data-aos="fade-up" >
                  <p> يعرض مقرر كل مجموعة نتائج العمل </p>
                </li>
                <li data-aos="fade-up" >
                  <p> يبدأ النقاش ويقوم المدرب يإدارة النقاش</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تحلل المعلومات و تستخلص النتائج</p>
                </li>
              </ul>
            </div>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a6} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">أهمية التقنية</h1>
            </div>
            <div className="whiteyelow tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p> تعزيز مهارات الاتصال و التواصل</p>
                </li>
                <li data-aos="fade-up" >
                  <p>يشعر المتدربون باستقلالهم عن المدرب حيث تقوم المجموعة بتحديد طريقة العمل التي تناسبها</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تنمية روح التعاون و العمل ضمن فريق</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تخلق روح المنافسة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>إكساب المتدربين تقنيات التحريك، صياغة الأفكار</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تضفي جواً من المرح</p>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function MCo3() {
  return (
    <section id="Msection3" className="tw-pt-8">
      <h3  data-aos="fade-up" className="secTitle tw-text-lg">إلقاء المحاضرات</h3>
      <p  data-aos="fade-up" className="secDesc">
        هي إحدى طرق الأسلوب التلقيني و تعتمد على إلقاء الموضوع من قبل المدرب حيث تساعد هذه الطريقة في إيصال كم من المعلومات لعدد من المشاركين في وقت
        قصير
      </p>
      <div className="center-img">
        <img  data-aos="fade-up" className="c3img" src={col3} alt="" />
      </div>
      <div className="colcard">
        <Container>
          <Row>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a7} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">طريقة تنفيذ التقنية</h1>
            </div>
            <div className="whitegreen tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p>يقوم المدرب بتحضير محتوى المحاضرة</p>
                </li>
                <li data-aos="fade-up" >
                  <p> يقدم المدرب المحاضرة في الوقت المحدد ويفضل استخدام الوسائل السمعية البصرية</p>
                </li>
                <li data-aos="fade-up" >
                  <p>يسمح بالنقاش بعد المحاضرة</p>
                </li>
              </ul>
            </div>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a6} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">أهمية التقنية</h1>
            </div>
            <div className="whiteyelow tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p>تساعد في إيصال كمية من المعلومات لعدد من المشاركين في وقت قصير</p>
                </li>
                <li data-aos="fade-up" >
                  <p> من السهل تحضيرها و تنظيمها</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تشجع على المناقشة</p>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function MCo4() {
  return (
    <section id="Msection4" className="tw-pt-8">
      <h3  data-aos="fade-up" className="secTitle tw-text-lg">المناقشات الجماعية</h3>
      <p  data-aos="fade-up" className="secDesc">تعتمد هذه التقنية على طرح موضوع للنقاش و طرح أسئلة مرتبطة بالموضوع</p>
      <div className="center-img">
        <img  data-aos="fade-up" className="c4img" src={col4} alt="" />
      </div>
      <div className="colcard">
        <Container>
          <Row>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a7} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">طريقة تنفيذ التقنية</h1>
            </div>
            <div className="whitegreen tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p> يقوم المدرب بتحضير الموضوع المراد مناقشته و الأسئلة التي سيتم طرحها للنقاش.</p>
                </li>
                <li data-aos="fade-up" >
                  <p> يحدد المدرب قواعد النقاش: الوقت ،الاستئذان قبل المشاركة في النقاش، الاستماع إلى كافة الآراء و احترامها</p>
                </li>
                <li data-aos="fade-up" >
                  <p> يعرض المدرب الموضوع على المجموعة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>يطرح المدرب الأسئلة و تتم مناقشتها</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تسجل الآراء و الأفكار التي يتم طرحها على لوحة قلابة ويمكن تصنيفها و ترتيبها حسب هدف الجلسة</p>
                </li>
              </ul>
            </div>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a6} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">أهمية التقنية</h1>
            </div>
            <div className="whiteyelow tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p> تعزز المشاركة و تبادل الخبرات</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تساعد المجموعة على احترام و تقدير وجهات النظر المختلفة</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تنمي مهارات الاتصال و التواصل </p>
                </li>
                <li data-aos="fade-up" >
                  <p>تسمح للمشاركين بالمشاركة ضمن الوقت المحدد</p>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function MCo5() {
  return (
    <section id="Msection5" className="tw-pt-8">
      <h3  data-aos="fade-up" className="secTitle tw-text-lg">لعبة الدور</h3>
      <p  data-aos="fade-up" className="secDesc">
        ‎هو أسلوب تدريبي يجسد فيه المشاركون أدوارا معينة من أجل التعرف على مشكلات لها خصائص ومراحل محددة بنص مكتوب و تعتبر لعبة الدور من أهم التقنيات
        التدريبية لإيصال سلوك أو مهارة معينة مطلوب من المتدرب اكتسابها
      </p>
      <div className="center-img">
        <img  data-aos="fade-up" className="c5img" src={col5} alt="" />
      </div>
      <div className="colcard">
        <Container>
          <Row>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a7} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">طريقة تنفيذ التقنية</h1>
            </div>
            <div className="whitegreen tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p>تتطلب تقلية لعبة الدور تحضيرا جيدا من قبل المتدربين</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تهيئة المجموعة جيدا من قبل المدرب قبل البدء بها</p>
                </li>
                <li data-aos="fade-up" >
                  <p> يتم توزيع النص الذي يصف الحالة المطلوب لعبها وتنشيط نقاش بالمجموعة حول الحالة</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تنسجم المجموعة مع الوضع المطلوب تجسيده بلعبة الدور</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تقوم المجموعة بلعب الدور عندما تكون جاهزة</p>
                </li>
                <li data-aos="fade-up" >
                  <p> اختيار مراقبين لمراقبة اللعبة وكتابة ملاحظات على ما تم إنجازه</p>
                </li>
              </ul>
            </div>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a6} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">أهمية التقنية</h1>
            </div>
            <div className="whiteyelow tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p>تشجع على الإبداع والمشاركة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تساعد على اكتشاف نقاط القوة و الضعف في الأنماط السلوكية</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تنمي القدرة على التحكم في الأحاسيس و السيطرة على بعض المواقف</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تخلق روح المنافسة</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تدرب على مواجهة التغيرات</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تؤثر على المستوى المعرفي و الوجداني و السلوكي</p>
                </li>
              </ul>
            </div>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img  data-aos="fade-up" className="tw-w-5" src={a5} alt="" />
              <h1  data-aos="fade-up" className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">دور المدرب</h1>
            </div>
            <div className="whiteyelow tw-mt-2 tw-mb-6">
              <ul className="graylist">
                <li data-aos="fade-up" >
                  <p>تشجع على الإبداع والمشاركة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تساعد على اكتشاف نقاط القوة و الضعف في الأنماط السلوكية</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تنمي القدرة على التحكم في الأحاسيس و السيطرة على بعض المواقف</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تخلق روح المنافسة</p>
                </li>
                <li data-aos="fade-up" >
                  <p> تدرب على مواجهة التغيرات</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تؤثر على المستوى المعرفي و الوجداني و السلوكي</p>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

function MCo6() {
  return (
    <section id="Msection6" className="tw-pt-8">
      <h3  data-aos="fade-up" className="secTitle tw-text-lg">دراسة الحالة</h3>
      <p  data-aos="fade-up" className="secDesc">
        هي إحدى تقنيات التدريب الناشطة وهي تقوم على اختيار وضع أو حالة أو مشكلة متصلة بالواقع المعاش لدى المشاركين، بهدف دراستها وتحليلها من قبلهم.
      </p>
      <div className="center-img">
        <img  data-aos="fade-up" className="c5img" src={col6} alt="" />
      </div>
      <div className="colcard">
        <Container>
          <Row>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img className="tw-w-5" src={a7} alt="" />
              <h1 className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">طريقة تنفيذ التقنية</h1>
            </div>
            <div className="whitegreen tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p>تحديد الحالة مسبقا أو أثناء اللقاء مع المجموعة</p>
                </li>
                <li data-aos="fade-up" >
                  <p> شرح التمرين و تحديد الفترة الزمنية لدراسة الحالة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>قراءة الحالة مع المجموعة و عرض الأسئلة المطلوب مناقشتها</p>
                </li>
                <li data-aos="fade-up" >
                  <p> دراسة الحالة إما فرديا أو ضمن المجموعة</p>
                </li>
                <li data-aos="fade-up" >
                  <p>عرض نتائج العمل</p>
                </li>
                <li data-aos="fade-up" >
                  <p> مناقشة النتائج و تحليلها مع المجموعة</p>
                </li>
              </ul>
            </div>
            <div className="tw-flex tw-gap-3 tw-px-0 tw-translate-x-3">
              <img className="tw-w-5" src={a6} alt="" />
              <h1 className="tw-text-[#3e3e3e] tw-text-base tw-font-bold">أهمية التقنية</h1>
            </div>
            <div className="whiteyelow tw-mt-2 tw-mb-6">
              <ul>
                <li data-aos="fade-up" >
                  <p> تساعد على حل مشكلة قريبة من الواقع الذي يعيشه المتدربون</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تستخدم المعارف والخبرات الموجودة عند المتدربين </p>
                </li>
                <li data-aos="fade-up" >
                  <p>تنمية مهارات التحليل باستعمال التفكير والتحليل المنطقي والعقلاني</p>
                </li>
                <li data-aos="fade-up" >
                  <p>تؤدي إلى تنظيم استخدام الخبرات السابقة لدى المشاركين </p>
                </li>
                <li data-aos="fade-up" >
                  <p>تؤدي إلى مشاركة الجميع وتبرز وجهات نظر عديدة</p>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export { Co1, Co2, Co3, Co4, Co5, Co6, MCo1, MCo2, MCo3, MCo4, MCo5, MCo6 };
