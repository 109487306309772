import React, { FC } from "react";
import { Link } from "react-router-dom";
import { School } from "../../models/school";
import SchoolImage from "../SchoolImage";
import "./school-card.scss";

const SchoolCard: FC<School> = ({ name, city, governorate, gender, id }) => {
  return (
    <div className="school-card mb-4 d-flex flex-column tw-shadow-sm">
      <div className="img-cont h-100 mx-auto px-3">
        {/* <img src={image?.original_url.replace("http://localhost", config.serverUrl) || SchoolImage} alt="School" className="m-auto mb-3" /> */}
        <SchoolImage className="m-auto mb-3" gender={gender} />
      </div>
      <div className="details">
        <h3 className="mb-2 w-100">
          <Link to={"/schools/" + id}>{name}</Link>
        </h3>
        <p className="mb-1 tw-flex tw-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.332" height="16.306" viewBox="0 0 14.332 16.306" className="me-2">
            <g id="layer1" transform="translate(-0.835 -1036.21)" opacity="0.43">
              <path
                id="path4926"
                d="M7.994,0a4.508,4.508,0,0,0-4.5,4.5.5.5,0,0,0,.023.158A8.434,8.434,0,0,0,5.049,8.9c.831,1.322,1.794,2.563,2.412,3.828a.5.5,0,0,0,.895.01,45.176,45.176,0,0,1,2.535-3.828,8.026,8.026,0,0,0,1.619-4.4.5.5,0,0,0-.033-.186A4.5,4.5,0,0,0,7.994,0Zm0,1a3.492,3.492,0,0,1,3.5,3.5.5.5,0,0,0,.01.107,7.229,7.229,0,0,1-1.447,3.75C9.369,9.4,8.591,10.471,7.92,11.588,7.283,10.476,6.549,9.4,5.9,8.365A7.345,7.345,0,0,1,4.51,4.506.5.5,0,0,0,4.5,4.418,3.488,3.488,0,0,1,7.994,1Zm0,2a1.5,1.5,0,1,0,1.5,1.5A1.508,1.508,0,0,0,7.994,3Zm0,1a.5.5,0,1,1-.5.5A.493.493,0,0,1,7.994,4Zm4.09,5.227a.5.5,0,0,0-.18.967,3.812,3.812,0,0,1,1.914,1.42,1.189,1.189,0,0,1-.092,1.426C13,14.075,10.691,14.993,8.053,15S3.071,14.121,2.3,13.082a1.2,1.2,0,0,1-.145-1.428,3.657,3.657,0,0,1,1.855-1.43A.5.5,0,1,0,3.629,9.3a4.52,4.52,0,0,0-2.352,1.883,2.313,2.313,0,0,0,.223,2.49C2.632,15.21,5.211,16.016,8.059,16s5.4-.837,6.488-2.391a2.306,2.306,0,0,0,.141-2.494,4.671,4.671,0,0,0-2.408-1.853A.5.5,0,0,0,12.084,9.227Z"
                transform="translate(0 1036.362)"
                stroke="#000"
                strokeWidth="0.3"
              />
            </g>
          </svg>
          محافظة {governorate}
        </p>
        <p className="mb-1 tw-flex tw-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.332" height="16.306" viewBox="0 0 14.332 16.306" className="me-2">
            <g id="layer1" transform="translate(-0.835 -1036.21)" opacity="0.43">
              <path
                id="path4926"
                d="M7.994,0a4.508,4.508,0,0,0-4.5,4.5.5.5,0,0,0,.023.158A8.434,8.434,0,0,0,5.049,8.9c.831,1.322,1.794,2.563,2.412,3.828a.5.5,0,0,0,.895.01,45.176,45.176,0,0,1,2.535-3.828,8.026,8.026,0,0,0,1.619-4.4.5.5,0,0,0-.033-.186A4.5,4.5,0,0,0,7.994,0Zm0,1a3.492,3.492,0,0,1,3.5,3.5.5.5,0,0,0,.01.107,7.229,7.229,0,0,1-1.447,3.75C9.369,9.4,8.591,10.471,7.92,11.588,7.283,10.476,6.549,9.4,5.9,8.365A7.345,7.345,0,0,1,4.51,4.506.5.5,0,0,0,4.5,4.418,3.488,3.488,0,0,1,7.994,1Zm0,2a1.5,1.5,0,1,0,1.5,1.5A1.508,1.508,0,0,0,7.994,3Zm0,1a.5.5,0,1,1-.5.5A.493.493,0,0,1,7.994,4Zm4.09,5.227a.5.5,0,0,0-.18.967,3.812,3.812,0,0,1,1.914,1.42,1.189,1.189,0,0,1-.092,1.426C13,14.075,10.691,14.993,8.053,15S3.071,14.121,2.3,13.082a1.2,1.2,0,0,1-.145-1.428,3.657,3.657,0,0,1,1.855-1.43A.5.5,0,1,0,3.629,9.3a4.52,4.52,0,0,0-2.352,1.883,2.313,2.313,0,0,0,.223,2.49C2.632,15.21,5.211,16.016,8.059,16s5.4-.837,6.488-2.391a2.306,2.306,0,0,0,.141-2.494,4.671,4.671,0,0,0-2.408-1.853A.5.5,0,0,0,12.084,9.227Z"
                transform="translate(0 1036.362)"
                stroke="#000"
                strokeWidth="0.3"
              />
            </g>
          </svg>
          ولاية {city}
        </p>
        <p className="tw-flex tw-items-center">
          {gender === "إناث" ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" className="me-2">
              <g id="Group_43458" data-name="Group 43458" transform="translate(3692 935.283)">
                <g id="Ellipse_561" data-name="Ellipse 561" transform="translate(-3689.782 -930.478)" fill="#fff" stroke="#a2a2a2" strokeWidth="1.3">
                  <ellipse cx="6.21" cy="7.098" rx="6.21" ry="7.098" stroke="none" />
                  <ellipse cx="6.21" cy="7.098" rx="5.56" ry="6.448" fill="none" />
                </g>
                <g id="Ellipse_562" data-name="Ellipse 562" transform="translate(-3692 -935.283)" fill="none" stroke="#a2a2a2" strokeWidth="1.3">
                  <ellipse cx="8.5" cy="9.5" rx="8.5" ry="9.5" stroke="none" />
                  <ellipse cx="8.5" cy="9.5" rx="7.85" ry="8.85" fill="none" />
                </g>
              </g>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" className="me-2">
              <g id="Group_43456" data-name="Group 43456" transform="translate(3939 13.276)">
                <g id="Ellipse_560" data-name="Ellipse 560" transform="translate(-3939 -13.276)" fill="#fff" stroke="#909090" strokeWidth="1.5">
                  <ellipse cx="7" cy="7.5" rx="7" ry="7.5" stroke="none" />
                  <ellipse cx="7" cy="7.5" rx="6.25" ry="6.75" fill="none" />
                </g>
                <g id="Rectangle_5548" data-name="Rectangle 5548" transform="translate(-3939 -13.276)" fill="#fff" stroke="#909090" strokeWidth="1.5">
                  <path d="M2,0H12a2,2,0,0,1,2,2V7a0,0,0,0,1,0,0H0A0,0,0,0,1,0,7V2A2,2,0,0,1,2,0Z" stroke="none" />
                  <path d="M2,.75H12A1.25,1.25,0,0,1,13.25,2V6.25a0,0,0,0,1,0,0H.75a0,0,0,0,1,0,0V2A1.25,1.25,0,0,1,2,.75Z" fill="none" />
                </g>
              </g>
            </svg>
          )}
          {gender}
        </p>
      </div>
    </div>
  );
};

export default SchoolCard;
