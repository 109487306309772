import React from "react";
import "./loadingspinner.scss";

type Props = {};

const LoadingSpinner = (props: Props) => {
  return (
    <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
      <div className="loader-5">
        <span></span>
      </div>
    </div>
  );
};

export default LoadingSpinner;
