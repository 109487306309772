import React, { FC } from 'react'
import Modal from 'react-bootstrap/Modal'
import useTranslation from '../../i18n/hooks/useTranslation'
import './confirm-modal.scss'

interface IProps{
    setShown: (modal:string) => void
    action: (args:any) => void
    title: string
    description?: string
}

const ConfirmationModal: FC<IProps> = ({ setShown, action, title, description }) => {

    const t = useTranslation()

    const handleClose = () => setShown("")

    return (
        <Modal show={true} onHide={handleClose} className="db-modal confirm-modal" centered={true}>
            <Modal.Body>
                <div className="title">{title}</div>
                {description && <div className="description mt-2">{description}</div>}    
                <div className="d-flex mt-4">
                    <button className="btn-c btn-green w-50 me-2" onClick={action} >{t("CONFIRM")}</button>
                    <button className="btn-c btn-cancel w-50" type="button" onClick={handleClose}>{t("CANCEL")}</button>
                </div>
            </Modal.Body>
            
        </Modal>
    );
}

export default ConfirmationModal;
