import React, { FC } from "react";
import { Link } from "react-router-dom";
import { News } from "../../models/news";
import "./news-card.scss";
import moment from "moment";
import "moment/locale/ar";
import config from "../../config";
moment.locale("ar");

const NewsCard: FC<News> = ({ title, created_at, id, cover }) => {
  return (
    <div
      className="news-card news-item mb-3 d-flex px-2 pb-2"
      style={{ backgroundImage: `url(${cover?.original_url.replace("http://localhost", config.serverUrl)})` }}>
      <div className="body w-100 mt-auto px-3 py-3">
        <Link to={"/news/" + id} className="title">
          {title}
        </Link>
        <NewsDate date={created_at} link={id} />
      </div>
    </div>
  );
};

export const NewsDate: FC<{ date: Date; link?: string | number }> = ({ date, link }) => {
  return (
    <div className="d-flex mt-3 mb-1">
      <svg xmlns="http://www.w3.org/2000/svg" width="16.601" height="16.602" viewBox="0 0 16.601 16.602" className="my-auto">
        <path
          id="Path_60807"
          data-name="Path 60807"
          d="M16.111,3.66h-1.66V2.83a.83.83,0,0,0-1.66,0v.83H7.81V2.83a.83.83,0,0,0-1.66,0v.83H4.49A2.49,2.49,0,0,0,2,6.15v9.961A2.49,2.49,0,0,0,4.49,18.6H16.111a2.49,2.49,0,0,0,2.49-2.49V6.15a2.49,2.49,0,0,0-2.49-2.49Zm.83,12.451a.83.83,0,0,1-.83.83H4.49a.83.83,0,0,1-.83-.83V10.3H16.941Zm0-7.47H3.66V6.15a.83.83,0,0,1,.83-.83H6.15v.83a.83.83,0,0,0,1.66,0V5.32h4.98v.83a.83.83,0,0,0,1.66,0V5.32h1.66a.83.83,0,0,1,.83.83Z"
          transform="translate(-2 -2)"
          fill="#909537"
        />
      </svg>
      <div className="date me-auto my-auto ms-2">{moment(date).format("LL")}</div>
      {link && (
        <Link to={"/news/" + link}>
          <svg
            id="Group_43527"
            data-name="Group 43527"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="13.998"
            viewBox="0 0 24 13.998"
            className="my-auto">
            <g id="Component_13_146" data-name="Component 13 – 146">
              <path
                id="Path_115"
                data-name="Path 115"
                d="M28,15a1,1,0,0,1-1,1H7.414l4.292,4.292a1,1,0,1,1-1.414,1.414l-6-6A.99.99,0,0,1,4,15v0a.974.974,0,0,1,.078-.382.99.99,0,0,1,.216-.324l6-6a1,1,0,1,1,1.414,1.414L7.414,14H27A1,1,0,0,1,28,15Z"
                transform="translate(-4 -8.001)"
                fill="#909537"
              />
            </g>
          </svg>
        </Link>
      )}
    </div>
  );
};

export default NewsCard;
