import React, { FC, InputHTMLAttributes } from 'react'
import './db-input.scss'


const DBInput: FC<InputHTMLAttributes<HTMLInputElement>> = ({ type, ...props }) => {
    return (
        <div className="db-input ps-2">
            <input type={type} {...props} />
        </div>
    )
}

export default DBInput