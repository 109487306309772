import React, { FC} from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../../components/Footer'
import MainNavbar from '../../../components/MainNavbar'
import Illustration from '../../../assets/illustrations/404.svg'
import './error404.scss'

const Error404: FC = () => {
    return (
        <div className="error-404-page">
            <header>
                <div className="container pt-2">
                    <MainNavbar />
                    <div className="d-flex flex-column h-100">
                        <img src={Illustration} alt="404" className="mt-5 mx-auto" />
                        <p className="mb-3 mx-auto">لا توجد اي صفحة هنا</p>
                        <Link to="/" className="go-home mx-auto">عودة الى الصفحة الرئيسية</Link>
                    </div>
                </div>
            </header>
            <Footer />
        </div>
    )
}

export default Error404