import React, { FC } from 'react'

const NumberControl: FC<{ value: any, setValue: Function, saveValue: Function }> = ({ value, setValue, saveValue }) => {

    return (
        <div className="d-flex flex-column number-control px-1">
            <svg className="mt-1 mb-auto" width="18" height="17" viewBox="0 0 18 17" onClick={() => {setValue(value+1);saveValue()}}>
                <g id="caret-up" transform="translate(0.22 -0.23)">
                    <rect id="Frame" width="18" height="17" transform="translate(-0.22 0.23)" fill="#c9c9c9" opacity="0"/>
                    <path id="caret-up-2" data-name="caret-up" d="M3071.343,3036.442l3.2,3.8a.98.98,0,0,1-.757,1.611h-6.8a.98.98,0,0,1-.757-1.611l3.2-3.8A1.248,1.248,0,0,1,3071.343,3036.442Z" transform="translate(-3061.61 -3030.147)" fill="#c9c9c9"/>
                </g>
            </svg>

            <svg className="mt-auto mb-1" width="18" height="17" viewBox="0 0 18 17" onClick={() => {setValue(value <= 1 ? value : value-1);saveValue()}}>
                <g id="caret-down" transform="translate(0.22 -0.329)">
                    <rect id="Frame" width="18" height="17" transform="translate(-0.22 0.329)" fill="#c9c9c9" opacity="0"/>
                    <path id="caret-down-2" data-name="caret-down" d="M3125.438,3041.411l-3.2-3.8a.98.98,0,0,1,.756-1.611h6.8a.98.98,0,0,1,.756,1.611l-3.2,3.8A1.248,1.248,0,0,1,3125.438,3041.411Z" transform="translate(-3117.61 -3027.17)" fill="#c9c9c9"/>
                </g>
            </svg>

        </div>
    )
}

export default NumberControl
