import React, { FC, useCallback, useEffect, useState } from "react";
import Header from "./Header";
import QuoteBlock from "../../../components/QuoteBlock";
import Footer from "../../../components/Footer";
import Filters from "./Filters";
import "./participating-schools.scss";
import api from "../../../api";
import { Season } from "../../../models/season";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import { School } from "../../../models/school";
import { Option } from "react-multi-select-component";
import Schools from "./Schools";

interface IFilters {
  season: Option;
  governorate: Option[];
  education_level: Option[];
  gender: Option[];
}

const ParticipatingSchools: FC = () => {
  const [selectedSchools, setSelectedSchools] = useState<School[]>([]);
  const [seasons, setSeasons] = useState<Season[] | null>(null);
  const [filters, setFilters] = useState<IFilters>({
    season: { label: "", value: "" },
    governorate: [],
    education_level: [],
    gender: [],
  });

  const getSeasons = async () => {
    let { data } = await api.get("/seasons");
    setSeasons(data);
  };

  const getSchoolsWithoutFilter = useCallback(
    (seasons: Season[]) => {
      let registrationRequests = [];
      let { season } = filters;

      if (season.value !== "" && season.value !== undefined) {
        registrationRequests = seasons.filter((s) => s.year === season.value)[0].registration_requests;
      } else {
        registrationRequests = seasons![0].registration_requests;
      }

      let allSchools = registrationRequests.filter((r) => r.status === "accepted").map((r) => r.school);
      return allSchools;
    },
    [filters]
  );

  const getSchoolsWithFilter = useCallback(
    (seasons: Season[]) => {
      let { governorate, gender, education_level } = filters;
      let schoolsWithFilter = getSchoolsWithoutFilter(seasons);

      if (governorate.length > 0)
        schoolsWithFilter = schoolsWithFilter.filter((s) => governorate.findIndex((gov) => gov.value === s.governorate) > -1);

      if (gender.length > 0) {
        schoolsWithFilter = schoolsWithFilter.filter((s) => gender.findIndex((gender) => gender.value === s.gender) > -1);
      }

      if (education_level.length > 0)
        schoolsWithFilter = schoolsWithFilter.filter((s) => education_level.some((l) => s.education_level!.includes(l.value)));

      return schoolsWithFilter;
    },
    [filters, getSchoolsWithoutFilter]
  );

  useEffect(() => {
    if (!seasons) {
      getSeasons();
    } else {
      setSelectedSchools(getSchoolsWithFilter(seasons));
    }
  }, [seasons, getSchoolsWithoutFilter, getSchoolsWithFilter]);

  return (
    <div className="participating-schools-page" style={{ backgroundColor: "#F2F2F2" }}>
      <Header />
      <div className="container">
        <div className="d-flex w-100 mb-5">
          <QuoteBlock
            text="المدارس المعززة للصحة هي المدرسة التي تسعى بشكل دائم الى تحسين قدراتها لتأمين بيئة صحية داعمة للعيش و التعلم والعمل"
            className="mx-auto"
          />
        </div>
        <div id="scrollTo"></div>

        {!seasons ? (
          <LoadingSpinner />
        ) : (
          <>
            <h2 className="mt-4 mb-5 color-green">قائمة المدارس المشاركة لسنة {filters.season.value || seasons[0].year}</h2>
            <div className="row mb-5">
              <div className="col-12 col-lg-3 mb-4">
                <Filters selectedSchools={selectedSchools} seasons={seasons} filters={filters} setFilters={setFilters} />
              </div>
              <Schools schools={selectedSchools} />
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ParticipatingSchools;
