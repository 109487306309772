import React, { FC, useEffect, useState } from "react";
import api from "../../../api";
import { RegistrationRequest } from "../../../models/registration-request";
import "./registrationrequests.scss";
import { Link } from "react-router-dom";
import { Season } from "../../../models/season";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import RequestsList from "./RequestsList";

interface IProps {
  dashboardVersion?: boolean;
}

const RegistrationRequests: FC<IProps> = ({ dashboardVersion = false }) => {

  const [requests, setRequests] = useState<RegistrationRequest[] | null>(null);
  const [currentSeason, setCurrentSeason] = useState<Season | null>(null);

  const getRequests = async () => {
    let { data } = await api.get(!dashboardVersion ? "/registration_requests" : "/registration_requests/latest");
    setRequests(data.filter((r: RegistrationRequest) => r.status === "pending"));
  };
  const getCurrentSeason = async () => {
    let { data } = await api.get("/seasons/current");
    let { season } = data;
    setCurrentSeason(season || {});
  };

  const requestHandled = (id: string | number) => {
    setRequests(requests!.filter((r) => r.id !== id));
  };

  useEffect(() => {
    if (!currentSeason) getCurrentSeason();
    if (!requests) getRequests();
    // eslint-disable-next-line
  });

  if (!requests || !currentSeason) return <LoadingSpinner />;
  return (
    <div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-6 tw-mb-6">
        <div className="tw-flex tw-w-full tw-justify-between md:tw-justify-start tw-items-center">
          <div className="font-weight-bolder tw-text-xl md:tw-text-2xl">آخر طلبات التسجيل</div>
          {/* <div className="sub-title tw-text-lg md:tw-text-xl ms-3">{requests.length} طلب جديد </div> */}
        </div>
        <div className="d-flex gap-2 tw-whitespace-nowrap">
          {!dashboardVersion ? (
            <>
              <h5>النتائج للعام الدراسي</h5>
              <h5 className="text-green">{currentSeason.year || ""}</h5>
            </>
          ) : (
            <div className="show-all-link tw-text-sm md:tw-text-base tw-whitespace-nowrap">
              <Link to="/admin/new_requests">عرض جميع الطلبات</Link>
            </div>
          )}
        </div>
      </div>
      <RequestsList requests={requests} requestHandled={requestHandled} dashboardVersion={dashboardVersion} />
    </div>
  );
};

export default RegistrationRequests;
