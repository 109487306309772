import React, { FC, useEffect, useState } from "react";
import api from "../../../api";
import "./content.scss";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import EmailCard from "./EmailCard";
import { EmailContent } from "../../../models/email-content";

const ManageEmails: FC = () => {

  const [emails, setEmails] = useState<EmailContent[] | null>(null);

  const getEmailObj = (name: string) => emails!.find(e => e.name === name)

  const getEmails = async () => {
    let { data } = await api.get("/emails_content");
    setEmails(data);
  };

  useEffect(() => {
    if (!emails) getEmails();
  }, [emails]);

  if (!emails) return <LoadingSpinner />;
  return (
    <div className="manage-emails-page">
      <h1 className="mb-5">إدارة البريد الإلكتروني</h1>
      <EmailCard displayName="عند طلب التسجيل" emailData={getEmailObj("reg_req_submitted")!} setEmails={setEmails} />
      <EmailCard displayName="بعد الموافقة على طلب التسجيل" emailData={getEmailObj("reg_req_accepted")!} setEmails={setEmails} />
      <EmailCard displayName="عند رفض تسجيل المدرسة" emailData={getEmailObj("reg_req_rejected")!} setEmails={setEmails} />
      <EmailCard displayName="عند الاعلان عن النتائج" emailData={getEmailObj("results_pulished")!} setEmails={setEmails} />
    </div>
  );
};

export default ManageEmails;
