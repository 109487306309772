import React, { FC, useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";

import Error404 from "./views/Public/Error404";
import HomePage from "./views/Public/HomePage";
import LoginPage from "./views/Auth/Login";
import About from "./views/Public/About";

import Dashboard from "./views/Admin/Dashboard";
import ManageSchools from "./views/Admin/ManageSchools";
import RegistrationRequests from "./views/Admin/RegistrationRequests";
import RegisterSchool from "./views/Schools/RegisterSchool";
import RegistrationRequestsHistory from "./views/Admin/RegistrationRequestsHistory";
import ManageAdmins from "./views/Admin/ManageAdmins";
import Content from "./views/Admin/ManageContent";
import PublishResults from "./views/Admin/PublishResults";
import ManageSeasons from "./views/Admin/ManageSeasons";
import EvalMethods from "./views/Public/EvalMethods";
import Results from "./views/Public/Results";
import EvalForms from "./views/Public/EvalForms";
import SchoolDetails from "./views/Schools/SchoolDetails";
import ParticipatingSchools from "./views/Schools/ParticipatingSchools";
import LatestNews from "./views/News/LatestNews";
import NewsDetails from "./views/News/NewsDetails";
import AddSchools from "./views/Admin/AddSchools";
import ManageNews from "./views/News/ManageNews";
import ResetPassword from "./views/Auth/ResetPassword";
import SchoolProfile from "./views/Admin/SchoolProfile";
import ManageEmails from "./views/Admin/ManageEmails";
import Supporters from "./views/Public/Supporters";

const preloader = document.getElementById("preloader-div");

declare global {
  interface Window {
      ga: Function;
  }
}

const RouterRoutes: FC = () => {
  const location = useLocation();
  const [newLocation, setNewLocation] = useState(location);

  
  useEffect(() => {
    if (typeof window.ga === 'function') {
      window.ga('set', 'page', location.pathname + location.search);
      window.ga('send', 'pageview');
    }
  }, [location])
  

  useEffect(() => {
    // Route changed, run transition CSS animation
    preloader?.classList.remove("hide-preloader");
    preloader?.classList.add("show-preloader");
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      setNewLocation(location);
      setTimeout(() => {
        preloader?.classList.remove("show-preloader");
        preloader?.classList.add("hide-preloader");
        document.body.style.overflow = "initial";
      }, 1000);
    }, 400); // 300ms CSS animation
  }, [location]);

  return (
    <Routes location={newLocation}>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/password/reset/:token" element={<ResetPassword />} />

      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/evaluation_forms" element={<EvalForms />} />
      <Route path="/supporters" element={<Supporters />} />
      <Route path="/evaluation_methods" element={<EvalMethods />} />
      <Route path="/results" element={<Results />} />
      <Route path="/news" element={<LatestNews />} />
      <Route path="/news/:id" element={<NewsDetails />} />

      <Route path="/participating_schools" element={<ParticipatingSchools />} />
      <Route path="/register_school" element={<RegisterSchool />} />
      <Route path="/schools/:id" element={<SchoolDetails />} />

      <Route path="/admin/new_requests" element={<PrivateRoute component={RegistrationRequests} />} />
      <Route path="/admin/requests_history" element={<PrivateRoute component={RegistrationRequestsHistory} />} />
      <Route path="/admin/dashboard" element={<PrivateRoute component={Dashboard} />} />
      <Route path="/admin/schools" element={<PrivateRoute component={ManageSchools} />} />
      <Route path="/admin/emails" element={<PrivateRoute component={ManageEmails} />} />
      <Route path="/admin/schools/:id" element={<PrivateRoute component={SchoolProfile} />} />
      <Route path="/admin/news" element={<PrivateRoute component={ManageNews} />} />
      <Route path="/admin/schools/add" element={<PrivateRoute component={AddSchools} />} />
      <Route path="/admin/content" element={<PrivateRoute component={Content} />} />
      <Route path="/admin/results" element={<PrivateRoute component={PublishResults} />} />
      <Route path="/admin/seasons" element={<PrivateRoute component={ManageSeasons} />} />
      <Route path="/admin/admins" element={<PrivateRoute component={ManageAdmins} />} />

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default RouterRoutes;
