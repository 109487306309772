import React, { FC, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import useTranslation from '../../../i18n/hooks/useTranslation'
import api from '../../../api';
import DBInput from '../../../components/DBInput';

interface IProps{
    setShown: (value: boolean) => void
    shown: boolean
    id: string|number
}

const UpdatePasswordModal: FC<IProps> = ({setShown, shown, id }) => {

    const t = useTranslation()

    
    const initState = {
        password: "",
        confirmPassword: ""
    }

    const [data, setData] = useState<any>(initState);

    const handleClose = () => setShown(false)

    const handleChange = (event: React.ChangeEvent<any>) => {
        event.persist()
        setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }))
    }

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        let { password, confirmPassword } = data

        if(password !== confirmPassword)
            return toast.error(t("AUTH.PASSWORDS_DONT_MATCH"))

        const toastId = toast.loading(t('LOADING'))
        const body = JSON.stringify({ password })

        try {
            await api.put('/users/admins/'+ id +'/password/', body)
            toast.success(t('SUCCESS.PASSOWRD_UPDATED'), { id: toastId })
            setShown(false)
        } catch (err: any) {
            toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED") , {
                id: toastId,
            })
        }
    }

    return (
        <Modal show={shown} onHide={handleClose} className="db-modal" centered={true}>
            <Form onSubmit={submitHandler}>
                <Modal.Body>
                    <div className="title">{t("AUTH.CHANGE_PASSWORD")}</div>   

                    <div className="mb-3">
                        <label htmlFor="password" className="mb-2">{t("AUTH.PASSWORD")}</label>
                        <DBInput type="password" placeholder={t("AUTH.PASSWORD")} name="password" id="password"
                            value={data.password} onChange={handleChange} required />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="confirmPassword" className="mb-2">{t("AUTH.CONFIRM_PASSWORD")}</label>
                        <DBInput type="password" placeholder={t("AUTH.CONFIRM_PASSWORD")} name="confirmPassword" id="confirmPassword"
                            value={data.confirmPassword} onChange={handleChange} required />
                    </div>
                    <div className="d-flex">
                        <button className="btn-c btn-green w-80 me-2" type="submit" disabled={!data.password || !data.confirmPassword}>{t("UPDATE")}</button>
                        <button className="btn-c btn-cancel w-20" type="button" onClick={handleClose}>{t("CANCEL")}</button>
                    </div>
                </Modal.Body>
            </Form>

        </Modal>
    )
}

export default UpdatePasswordModal