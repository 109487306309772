import React, { FC } from 'react'
import LineChart from '../../../components/LineChart'
import { RegistrationRequest } from '../../../models/registration-request'

const ParticipationsChart: FC<{participations: RegistrationRequest[]}> = ({ participations }) => {
    return (
        <div className="db-card chart mb-4 px-2">
            <h4 className="mx-4">الاداء خلال السنوات</h4>
            {participations.length > 0 ?
                <LineChart 
                    pointTitle={"النقاط"}
                    categories={participations.map(p => p.season?.year.split("-")[1])}
                    data={participations.map(p => p.score!)}
                />
            :
            <p className="no-certs mx-4 mt-5">لم تشارك المدرسة في أي نسخة</p>
            }
            
        </div>
    )
}

export default ParticipationsChart