import React, { FC, useEffect, useState } from "react";
import useTranslation from "../../../i18n/hooks/useTranslation";
import AboutIco from "./about_icon.png";
import Footer from "../../../components/Footer";
import Header from "./Header";
import "./home.scss";

import { Link } from "react-router-dom";
import Schools from "./Schools";
import Winners from "./Winners";
import Photos from "./Photos";
import { Content } from "../../../models/content";
import api from "../../../api";
import HowItWorks from "./HowItWorks";
import NewsSection from "./NewsSection";
import { News } from "../../../models/news";
import { Season } from "../../../models/season";
import { BsArrowLeft } from "react-icons/bs";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import "../../../assets/animation/btn-anim.scss"

const LandingPage: FC = () => {
  const t = useTranslation();

  const [content, setContent] = useState<Content | null>(null);
  const [news, setNews] = useState<News[] | null>(null);
  const [currentSeason, setCurrentSeason] = useState<Season | null>(null);
  const [lastResults, setLastResults] = useState<Season | null>(null);

  const getContent = async () => {
    let { data } = await api.get("/content");
    setContent(data);
  };

  const getNews = async () => {
    let { data } = await api.get("/news?limit=3");
    setNews(data);
  };

  const getCurrentSeason = async () => {
    let { data } = await api.get("/seasons/current");
    let res = await api.get("/seasons/last_results");
    setCurrentSeason(data.season);
    setLastResults(res.data);
  };

  useEffect(() => {
    if (!content) getContent();
    if (!currentSeason) getCurrentSeason();
    if (!news) getNews();
  }, [content, news, currentSeason]);

  return (
    <div className="landing-page hoverable ">
      
      {!content || !news || !currentSeason || !lastResults ? (
        <div className="py-5 my-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Header season={currentSeason?.year} lastResultsYear={lastResults?.year!} />
          <div className="bg-light pb-1">
          <div className="container pt-5 px-4 tw-px-5">
            <h3 data-aos="fade-up" className="tw-mb-10 pt-5 md:tw-mb-14 tw-text-xl lg:tw-text-2xl">{t("HOME.ABOUT_THE_INTIATIVE_TITLE")}</h3>
            <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row sm:tw-flex-col-reverse xsm:tw-flex-col-reverse md:tw-flex-col-reverse md:tw-gap-16 lg:tw-gap-32 tw-mb-28">
              <div className="tw-basis-10/12">
                <p className=""data-aos="fade-up">{content.about_ar}</p>
                <div className="see-more-c tw-mt-5"data-aos="fade-up">
                  <Link to="/about" className="see-more more-btn-ani tw-flex tw-items-center tw-gap-3">
                    {t("HOME.SEE_MORE")}
                    <BsArrowLeft className="tw-text-xl more-arr-btn" />
                  </Link>
                </div>
              </div>
              <div className="tw-basis-2/12 tw-w-2/6 tw-self-center tw-my-6 md:tw-mb-0">
                <img src={AboutIco} className="" data-aos="fade-up"alt="about" />
              </div>
            </div>
            <Schools season={currentSeason?.year} schools={currentSeason?.registration_requests_count!} />
          </div>
          {lastResults.year && <Winners season={lastResults} />}
          </div>
          <HowItWorks />
          <div style={{ background: "#FAFAFA" }}>
            <div className="container px-4 pt-5">
              {news.length > 0 && <NewsSection news={news} />}
              <h3 className="tw-mb-14 tw-text-xl lg:tw-text-2xl">{t("HOME.PHOTOS")}</h3>
            </div>
            {content.gallery && <Photos images={content.gallery} />}
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default LandingPage;
