import React, { FC, useState } from "react";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { Content } from "../../../models/content";
import api from "../../../api";
import toast from "react-hot-toast";

const Form: FC<{ content: Content; setContent: Function }> = ({ content, setContent }) => {
  const t = useTranslation();
  let { about_ar, about_en, health_promo_ar, health_promo_en } = content;
  const [data, setData] = useState({
    about_ar,
    about_en,
    health_promo_ar,
    health_promo_en,
  });

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    const toastId = toast.loading(t("LOADING"));
    const body = JSON.stringify(data);

    try {
      let { data } = await api.put("/content", body);
      setContent(data.content);
      toast.success(t("SUCCESS.CONTENT_UPDATED"), { id: toastId });
    } catch (err: any) {
      toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.persist();
    setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }));
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="db-card mb-4">
        <h4 className="mb-3">نبذة عن المبادرة</h4>
        <textarea className="db-textarea" id="about_ar" name="about_ar" rows={7} value={data.about_ar} onChange={handleChange} required></textarea>
        <div className="d-flex mt-3">
          <button type="submit" disabled={about_ar === data.about_ar} className="ms-auto btn-green tw-text-sm md:tw-text-base">
            تعديل المحتوى
          </button>
        </div>
      </div>

      <div className="db-card mb-4">
        <h4 className="mb-3">مفھوم “تعزیز الصحة”</h4>
        <textarea
          className="db-textarea"
          id="health_promo_ar"
          name="health_promo_ar"
          rows={7}
          value={data.health_promo_ar}
          onChange={handleChange}
          required></textarea>
        <div className="d-flex mt-3">
          <button type="submit" disabled={health_promo_ar === data.health_promo_ar} className="ms-auto btn-green tw-text-sm md:tw-text-base">
            تعديل المحتوى
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
