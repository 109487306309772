import React, { FC } from 'react';

interface IProps{
    direction: "left"|"right"
    disabled?: boolean
    onClick: (args:any) => void
}

const Arrow: FC<IProps> = ({ disabled, direction, onClick }) => {

    let color = disabled ? "#c0bdcc" : "#909537"
    return (
        <div className="my-auto mx-2" style={{ cursor: disabled ? "not-allowed" : "pointer" }}>
            <div className={`page-arrow ${!disabled ? "active" : ""}`} style={{ pointerEvents: disabled ? "none" : "auto" }} onClick={onClick}>
                {direction === "right" ?
                <svg xmlns="http://www.w3.org/2000/svg" width="14.984" height="11.96" viewBox="0 0 14.984 11.96">
                    <path id="next" d="M1313.324,1546.792a.648.648,0,0,1-.059-.869l.059-.068,4.329-4.187H1305.68a.661.661,0,0,1-.083-1.319l.083,0H1319.3a.681.681,0,0,1,.629.409.648.648,0,0,1-.088.652l-.061.066-5.495,5.32a.7.7,0,0,1-.954.008l-.009-.008Zm3.1-7.642-.068-.057-3.035-2.936a.649.649,0,0,1,0-.936.7.7,0,0,1,.895-.062l.071.06,3.03,2.933a.651.651,0,0,1,0,.937.7.7,0,0,1-.893.062Z" transform="translate(-1305 -1535.024)" fill={color}/>
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="14.984" height="11.96" viewBox="0 0 14.984 11.96">
                    <g id="prev" transform="translate(-1155.017 -1535.024)">
                        <path id="next" d="M1161.676,1546.792a.648.648,0,0,0,.059-.869l-.059-.068-4.329-4.187h11.974a.661.661,0,0,0,.083-1.319l-.083,0H1155.7a.682.682,0,0,0-.629.409.648.648,0,0,0,.088.652l.061.066,5.495,5.32a.7.7,0,0,0,.954.008l.009-.008Zm-3.1-7.642.068-.057,3.035-2.936a.649.649,0,0,0,0-.936.7.7,0,0,0-.895-.062l-.071.06-3.03,2.933a.651.651,0,0,0,0,.937.7.7,0,0,0,.893.062Z" fill={color}/>
                    </g>
                </svg>}
            </div>
        </div>

    )
}

export default Arrow;