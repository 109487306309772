import React, { FC} from 'react'
import Illustration from '../../../assets/illustrations/NoResults.svg'


const NoResult: FC = () => {
    
    return (
        <div data-aos="fade-up" className="no-result-card d-flex flex-column mt-4 pt-4">
            <img src={Illustration} className="mx-auto" alt="No results" />
            <p className='mx-auto'>لا توجد أي نتائج</p>
        </div>
    )
}

export default NoResult