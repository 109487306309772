import React, { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../api";
import ConfirmDelete from "../../../components/ConfirmDelete";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import ExportCSVButton from "../../../components/ExportCSVButton";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { School } from "../../../models/school";
import AddSchoolModal from "./AddSchoolModal";
import SchoolsTable from "./SchoolsTable";
import UpdateSchoolModal from "./UpdateSchoolModal";

const ManageSchools: FC = () => {
  const t = useTranslation();

  const [schools, setSchools] = useState<School[] | null>(null);
  const [idToDelete, setIdToDelete] = useState<number | string>("");
  const [search, setSearch] = useState("");
  const [schoolToUpdate, setSchoolToUpdate] = useState<School | null>(null);
  const [shownModal, setShownModal] = useState("");

  const getSchools = async () => {
    let { data } = await api.get("/schools");
    setSchools(data);
  };

  const schoolAdded = (school: School) => {
    setShownModal("");
    setSchools([school, ...schools!]);
  };

  const schoolUpdated = (product: any) => {
    setShownModal("");
    let newSchools = JSON.parse(JSON.stringify(schools));
    let index = newSchools.findIndex((el: any) => el.id === product.id);
    newSchools[index] = product;
    setSchools(newSchools);
  };

  const deleteSchool = async () => {
    let id = idToDelete;
    await api.delete("/schools/" + id);
    toast.success(t("SUCCESS.DELETED"));
    setSchools(schools!.filter((s) => s.id !== id));
    setShownModal("");
  };

  const openDeleteSchool = async (id: number | string) => {
    setIdToDelete(id);
    setShownModal("delete");
  };

  const openUpdateSchool = async (school: School) => {
    setSchoolToUpdate(school);
    setShownModal("update");
  };

  const filterSchools = (schools: School[]) => {
    if (!search) return schools;
    return schools!.filter((s) => s.name.toLowerCase().includes(search));
  };

  useEffect(() => {
    if (!schools) getSchools();
  }, [schools]);

  if (!schools) return <LoadingSpinner />;

  return (
    <div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-6 tw-mb-6">
        <div className="tw-flex tw-w-full tw-justify-between md:tw-justify-start tw-items-center">
          <h1 className="tw-text-lg md:tw-text-2xl">{t("MANAGE_SCHOOLS.TITLE")}</h1>
        </div>
        <div className="tw-flex">
          <div className="table-search my-auto d-flex px-2">
            <input type="text" className="my-auto me-auto" placeholder="بحث" value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
            <svg xmlns="http://www.w3.org/2000/svg" width="19.457" height="19.457" viewBox="0 0 19.457 19.457" className="my-auto">
              <g id="Layer_2" data-name="Layer 2" opacity="0.1">
                <path
                  id="search"
                  d="M22.138,20.6,18.463,16.94a8.558,8.558,0,0,0,1.826-5.295,8.645,8.645,0,1,0-8.645,8.645,8.558,8.558,0,0,0,5.295-1.826L20.6,22.138A1.085,1.085,0,1,0,22.138,20.6ZM5.161,11.645a6.484,6.484,0,1,1,6.484,6.484,6.484,6.484,0,0,1-6.484-6.484Z"
                  transform="translate(-3 -3)"
                />
              </g>
            </svg>
          </div>
          <ExportCSVButton
            headers={["مستوى التعليم", "المدينة", "المحافظة", "الجنس", "المدرسة", "المعرف"]}
            data={schools.map(({ id, governorate, city, education_level, gender, name }) => [education_level, city, governorate, gender, name, id])}
            file="المدارس"
          />
          <button
            className="btn-green tw-py-0 tw-px-2 md:tw-px-4 tw-text-xs md:tw-text-base tw-whitespace-nowrap"
            onClick={() => setShownModal("add")}>
            {t("MANAGE_SCHOOLS.ADD_SCHOOL")}
          </button>
        </div>
      </div>

      <div className="tw-overflow-x-scroll">
        <SchoolsTable schools={filterSchools(schools)} openDeleteSchool={openDeleteSchool} openUpdateSchool={openUpdateSchool} />
      </div>

      {shownModal === "add" && <AddSchoolModal setShown={setShownModal} shown={shownModal} schoolAdded={schoolAdded} />}

      {shownModal === "update" && (
        <UpdateSchoolModal setShown={setShownModal} shown={shownModal} school={schoolToUpdate!} schoolUpdated={schoolUpdated} />
      )}

      {shownModal === "delete" && <ConfirmDelete setShown={setShownModal} shown={shownModal} handleDelete={deleteSchool} />}
    </div>
  );
};

export default ManageSchools;
