import moment from "moment";
import React, { FC } from "react";
import { Season } from "../../../../models/season";
import Closed from "./Closed";
import NotStarted from "./NotStarted";
import Started from "./Started";

interface IProps {
  seasonUpdated: Function;
  season: Season;
  openModal: Function;
  openUpdateModal: Function;
}

const CurrentSeason: FC<IProps> = ({ season, openModal, seasonUpdated, openUpdateModal }) => {
  let endDate, startDate, hasStarted, hasEnded;
  if (season) {
    endDate = moment(season.endDate);
    startDate = moment(season.startDate);
    hasStarted = moment().diff(startDate, "minutes") >= 0;
    hasEnded = moment().diff(endDate, "minutes") >= 0;
  }

  return (
    <div className="db-card mb-4">
      {season ? (
        <div className="current-season tw-grid tw-grid-cols-1 lg:tw-grid-cols-12">
          {!hasStarted && <NotStarted season={season} seasonUpdated={seasonUpdated} />}
          {hasStarted && !hasEnded && <Started season={season} seasonUpdated={seasonUpdated} openUpdateModal={openUpdateModal} />}
          {hasEnded && <Closed openModal={openModal} season={season} seasonUpdated={seasonUpdated} />}
        </div>
      ) : (
        <>
          <h4 className="mb-3">الموسم الحالي</h4>
          <div className="no-season d-flex flex-column">
            <p className="my-auto mx-auto mb-3">لم يتم اضافة اي موسم حتى الان</p>
            <button className="btn-green-o mb-auto mx-auto" onClick={() => openModal()} style={{ width: "fit-content" }}>
              إضافة موسم
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CurrentSeason;
