import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import NewsCard from "../../../components/NewsCard";
import useTranslation from "../../../i18n/hooks/useTranslation";
import Header from "./Header";
import ArrLeft from "../../../assets/icons/green-left-arrow.svg";
import ArrRight from "../../../assets/icons/black-right-arrow.svg";
import "./news-details.scss";
import { News } from "../../../models/news";
import api from "../../../api";
import { NewsDate } from "../../../components/NewsCard/NewsCard";
import config from "../../../config";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import { ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import parseHTMLtoJSX from "html-react-parser";

const LatestNews: FC = () => {
  const t = useTranslation();

  let { id } = useParams<{ id: string }>();

  const [article, setArticle] = useState<News | null>(null);
  const [similarNews, setSimilarNews] = useState<News[] | null>(null);

  const getNews = async (id: string) => {
    let res1 = await api.get(`/news/${id}`);
    let res2 = await api.get(`/news/${id}/similar`);

    let savedBody;
    try {
      // This news card already using react-draft-wysiwyg
      const rawContentState = JSON.parse(res1.data.body);
      const markup = draftToHtml(rawContentState);
      savedBody = parseHTMLtoJSX(markup);
    } catch (e) {
      // This news card is using the old plain text editor
      const rawContentState = convertToRaw(ContentState.createFromText(res1.data.body!));
      const markup = draftToHtml(rawContentState);
      savedBody = parseHTMLtoJSX(markup);
    }
    res1.data.body = savedBody;

    setArticle(res1.data);
    setSimilarNews(res2.data);
  };

  useEffect(() => {
    getNews(id!);
  }, [id]);

  return (
    <div className="news-details-page">
      <Header title={t("NEWS.TITLE")} />
      {!article || !similarNews ? (
        <div className="mt-4 pt-3">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="container px-4 position-relative" style={{ marginTop: -80, zIndex: 99 }}>
          <div
            className="news-bg w-100"
            style={{ backgroundImage: `url(${article.cover?.original_url.replace("http://localhost", config.serverUrl)})` }}></div>
          <div className="news-item d-flex mb-5">
            <div className="body mx-auto">
              <div className="mb-3">
                <NewsDate date={article.created_at} />
              </div>
              <div className="news-title mb-3">{article.title}</div>

              <div className="content">
                {/* {article.body?.split("\r\n\r\n").map((line) => (
                  <span key={line}>{line}</span>
                ))} */}
                {article.body}
              </div>
              {similarNews.length > 0 && (
                <div className="d-flex mt-5 togglers">
                  <img src={ArrRight} className="my-auto me-2" alt="left" />
                  <Link to={`/news/${similarNews[0].id}`} className="my-auto me-auto prev">
                    المقال السابق
                  </Link>

                  <Link to={`/news/${similarNews[1]?.id || similarNews[0].id}`} className="my-auto me-2 next">
                    المقال التالي
                  </Link>
                  <img src={ArrLeft} alt="left" className="my-auto" />
                </div>
              )}
            </div>
          </div>
          {similarNews.length > 0 && (
            <div className="other-news">
              <h2 className="mt-4 mb-4">{t("NEWS.LATEST_UPDATES")}</h2>
              <div className="row">
                {similarNews.map((article, idx) => (
                  <div className="col-12 col-md-4" key={"news-card-" + idx}>
                    <NewsCard {...article} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      <Footer />
    </div>
  );
};

export default LatestNews;
