import React, { FC, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import "./detailedschoolschart.scss";

import { IRegistrationsEachWeekDay } from "../../../store/types";
import moment from "moment";
import useTranslation from "../../../i18n/hooks/useTranslation";
import ChartToggler from "../../ChartToggler";

interface IProps {
  baseColor: string;
  startDate: Date;
  lightColor: string;
  registrationEachDay: IRegistrationsEachWeekDay[] | undefined;
  registrationEachWeek: IRegistrationsEachWeekDay[] | undefined;
}

const DetailedSchoolsChart: FC<IProps> = ({ baseColor, lightColor, registrationEachDay, registrationEachWeek, startDate }) => {
  const t = useTranslation();
  const [isDaily, setIsDaily] = useState(true);

  useEffect(() => {
    if (registrationEachDay && registrationEachWeek) {
      const element = document.getElementById("kt_stats_widget_yearly_schools_chart");

      if (!element) {
        return;
      }

      const options = getChartOption(isDaily ? registrationEachDay : registrationEachWeek, isDaily, baseColor, lightColor, startDate);
      const chartnewUsers = new ApexCharts(element, options);
      chartnewUsers.render();
      return function cleanUp() {
        chartnewUsers.destroy();
      };
    }
    // eslint-disable-next-line
  }, [isDaily, registrationEachDay, registrationEachWeek]);

  const conditionalGraphRender = () => {
    if (
      (isDaily && registrationEachDay && registrationEachDay?.length > 0) ||
      (!isDaily && registrationEachWeek && registrationEachWeek?.length > 0)
    ) {
      return (
        <div>
          <div id="kt_stats_widget_yearly_schools_chart" className="card-rounded-bottom" style={{ height: "250px" }}></div>
          <div className="tw-hidden card-rounded-bottom tw-items-center tw-justify-center" style={{ height: "250px" }}>
            <span className="tw-text-xl tw-text-gray-200 tw-font-bold">{t("DASHBOARD.NO_DATA")}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div id="kt_stats_widget_yearly_schools_chart" className="tw-hidden card-rounded-bottom" style={{ height: "250px" }}></div>
          <div className="tw-flex card-rounded-bottom tw-items-center tw-justify-center" style={{ height: "250px" }}>
            <span className="tw-text-xl tw-text-gray-200 tw-font-bold">{t("DASHBOARD.NO_DATA")}</span>
          </div>
        </div>
      );
    }
  };
  return (
    <div className={`card card-custom py-4 px-4 db-shadow mt-3`} style={{maxHeight: 320, border: 0, borderRadius: 15}}>
      <div className="card-body p-0">
        <ChartToggler
          titles={["يوم","أسبوع"]}
          selected={isDaily ? "يوم" : "أسبوع"}
          setSelected={(title: string) => setIsDaily(title === "يوم")}
        />
        {conditionalGraphRender()}
      </div>
    </div>
  );
};


const getWeekNumber = (number: any, startDate: Date) => {
  let diff = moment(startDate).diff(moment("2022-01-01"), 'weeks')
  let result = parseInt(number) - diff
  return result < 0 ? result + 52 : result
}

function getChartOption(data: IRegistrationsEachWeekDay[], isMonthly: boolean, baseColor: string, lightColor: string, startDate: Date) {
  var options = {
    responsive: [
      {
        breakpoint: 640,
        options: {
          dataLabels: {
            // enabled: true,
            // style: {
            //   fontSize: ".8rem",
            // },
          },
          grid: {
            padding: {
              left: 15,
              right: 15, // Also you may want to increase this (based on the length of your labels)
              bottom: 10,
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: ".7rem",
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          xaxis: {
            labels: {
              rotate: 80,
            },
          },
        },
      },
    ],
    series: [
      {
        name: "عدد المدارس",
        data: data.map((item) => item.count),
      },
    ],
    chart: {
      type: "area",
      height: 250,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
    },
    fill: {
      // type: "solid",
      // opacity: 1,
      type: "gradient",
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.55,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.6,
        opacityTo: 0,
        // stops: [25, 50, 100],
        stops: [0, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      // colors: [layoutProps.colorsThemeBasePrimary],
      colors: [baseColor],
    },
    grid: {
      padding: {
        left: 25,
        right: 25, // Also you may want to increase this (based on the length of your labels)
      },
    },
    xaxis: {
      categories: data.map((item) => (isMonthly ? moment(item.day).locale("en").format("MM/DD") : "الأسبوع" + getWeekNumber(item.week, startDate))),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        style: {
          // colors: layoutProps.colorsGrayGray500,
          colors: "#C0BDCC",
          fontSize: ".8rem",
          fontWeight: 500,
          fontFamily: "Cairo",
        },
        offsetY: -15,
        // formatter: function (val: number) {
        //   return t("SEASON") + " " + val;
        // },
      },
      crosshairs: {
        show: true,
        position: "front",
        stroke: {
          color: lightColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: "Cairo",
        },
      },
    },
    yaxis: {
      min: -1,
      max: Math.max(...data.map((item) => item.count)) + 1,
      labels: {
        show: true,
        style: {
          colors: lightColor,
          fontSize: "12px",
          fontFamily: "Cairo",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    colors: [baseColor],
    markers: {
      colors: [baseColor],
      strokeWidth: 1
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}: any) {
        return `
          <div class="chart-tooltip">
            <div class="title mb-2">المدارس المشاركة</div>
            <div class="count" style="color: ${baseColor}">${series[seriesIndex][dataPointIndex]}</div>
          </div>
        `
      }
    }
  };
  return options;
}

export default DetailedSchoolsChart;
