import React, { useState, FC } from 'react'
import { Container } from 'react-bootstrap'
import MaleG from '../../../assets/icons/MaleG.png'
import FemaleG from '../../../assets/icons/FemaleG.svg'
import seeit from '../../../assets/icons/seeit.png'
import Fplace from '../../../assets/icons/Fplace.png'
import Tplace from '../../../assets/icons/Tplace.png'
import Splace from '../../../assets/icons/Splace.png'
import Up from '../../../assets/icons/uparr.png'
import Down from '../../../assets/icons/bottom_arr.svg'
import searchIcon from '../../../assets/icons/searchIcon.png'
import { Season } from '../../../models/season'
import { RegistrationRequest } from '../../../models/registration-request'
import SelectSearch from 'react-select-search'
import { Link } from 'react-router-dom'
import { School } from '../../../models/school'
import NoResult from './NoResult'
import SchoolImage from '../../../components/SchoolImage'

interface IProps{
    seasons: Season[]
    requests: RegistrationRequest[]
    filters: any
    setFilters: Function
}

const Tables: FC<IProps> = ({ seasons, requests, setFilters, filters }) =>{

    const [page, setPage] = useState(1)

    const handleChange = (event: React.ChangeEvent<any>) => {
        event.persist()
        setFilters((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }))
    }

    return(
        <Container>
            <div className="TablesSection">
                <div className="d-flex flex-wrap">
                    <h3 data-aos="fade-up" className="me-auto my-auto pe-3">نتائج المدارس المشاركة</h3>
                    <div data-aos="fade-up" className="d-flex m-sm flex-wrap">
                        <div data-aos="fade-up" className="SearchSec me-3 px-3 my-auto d-flex">
                            <input data-aos="fade-up" type='text' className="my-auto" name="name" placeholder="البحث"
                                onChange={handleChange} value={filters.name} />
                            <img data-aos="fade-up" src={searchIcon} alt="search" className="my-auto" />
                        </div>
                        <SelectSearch data-aos="fade-up"
                            className={"SelectSec my-auto d-block"}
                            onChange={(...args: any) => setFilters((prevData: any) => ({ ...prevData, season: args[0] }))}
                            options={seasons.map(season => ({ name: season.year, value: season.year }))}
                            value={filters.season || seasons[0]?.year || ""}
                        />
                    </div>
                    
                    {/* <select className='' name="season" id="season" value={filters.season} onChange={handleChange}>
                        {seasons.map(season => 
                            <option value={season.year} key={season.year}>{season.year}</option>
                        )}
                    </select> */}
                </div>
                {requests.length === 0 ?
                    <NoResult />
                    :
                    <div data-aos="fade-up" className="table-cont mb-4" style={{ position: "relative", zIndex: -1 }}>
                        <div className="wrapper">
                            <table >
                                <tbody>
                                    <tr >
                                        <th>المرتبة</th>
                                        <th>المدرسة</th>
                                        <th>المحافظة</th>
                                        <th>النقاط</th>
                                        <th>السنة</th>
                                        <th>التقدم</th>
                                        <th></th>
                                    </tr>
                                    {requests.map(({school, score, last_year_rank, rank}, idx) =>
                                        <tr key={"res-" + idx}>
                                            <td className={"place" }>
                                                {idx < 3 ? <img src={idx === 0 ? Fplace : idx === 1 ? Splace : Tplace} alt="place" /> 
                                                    : <p className="m-auto">{idx + 1}</p> }
                                            </td>
                                            <td>
                                                <SchoolSmallCard {...school} />
                                            </td>
                                            <td>
                                                <p className="mb-0">محافظة {school.governorate}</p>
                                                <p className="mb-0">ولاية {school.city}</p>
                                            </td>
                                            <td className='Point'><p>{score}</p></td>
                                            <td><p>{filters.season || seasons[0].year}</p></td>
                                            <td className='Addit'>
                                                {!last_year_rank ?
                                                    <p className="uparrADD">-</p>
                                                    :
                                                last_year_rank !== rank ? 
                                                    <div className="d-flex">
                                                        <img 
                                                            className='uparr me-1 my-auto ms-auto' 
                                                            src={last_year_rank > rank ? Up : Down} 
                                                            style={{ transform: `translateY(${idx % 2 === 0 ? "-" : "" }50%)` }}
                                                            alt="up" 
                                                        />
                                                        <p className='uparrADD me-auto'>
                                                            {Math.abs(last_year_rank - rank)}
                                                            {last_year_rank > rank ? "+" : "-"}
                                                        </p>
                                                    </div>
                                                    : <p className="uparrADD">0</p>
                                                }
                                            </td>
                                            <td className='seeit'>
                                                <Link to={"/schools/" + school.id }>
                                                    <img className='icon' src={seeit} alt="seeit" />
                                                </Link>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

                {requests.length > 0 && <div className='btn-12'>
                    {Array(1).fill(0).map((it,idx) =>
                        <p 
                            key={"page-" + idx}
                            onClick={() => setPage(idx + 1)}
                            className={`me-3 ${page === idx + 1 ? "active" : ""}`}
                        >
                            {idx + 1 }
                        </p>
                    )}
                </div>}
            </div>
        </Container>

    )
}

export default Tables

const SchoolSmallCard: FC<School> = ({ name, gender, image }) =>{
    return(
        <div className='SchoolSmallCard d-flex'>
            {/* <img src={image?.original_url.replace("http://localhost", config.serverUrl) || SchoolImage } alt="school" className="school-img me-3" /> */}
            <SchoolImage className="school-img me-3" gender={gender} />
            <div className='details'>
                <h4>{name}</h4>
                <div className="d-flex">
                    <img src={gender === "ذكور" ? MaleG : FemaleG} alt="male" className="my-auto me-2" />
                    <p className="my-auto">{gender}</p>
                </div>
            </div>
        </div>
    );
}