import React, { FC, useEffect, useState } from "react";
import useTranslation from "../../../i18n/hooks/useTranslation";
import Footer from "../../../components/Footer";
import Header from "./Header";
import "./eval-forms.scss";
import { Content } from "../../../models/content";
import api from "../../../api";
import config from "../../../config";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";

const EvalMethods: FC = () => {
  const t = useTranslation();

  const [content, setContent] = useState<Content | null>(null);

  const getContent = async () => {
    let { data } = await api.get("/content");
    setContent(data);
  };

  useEffect(() => {
    if (!content) getContent();
  }, [content]);

  return (
    <div className="eval-forms-page">
      <Header title={t("EVAL_FORMS.TITLE")} />
      <div className="container px-1">
        <div className="files-card">
          <h3 className="mb-4">{t("EVAL_FORMS.EVAL_FILES")}</h3>

          {!content ? (
            <LoadingSpinner />
          ) : (
            content.forms.map((file, idx) => (
              <div className="file-item d-flex px-3" key={file.uuid}>
                <div className="name my-auto me-auto ">{file.name}</div>
                <div className="show my-auto d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 24.641 14.08" className="my-auto">
                    <g id="Group_43511" data-name="Group 43511" transform="translate(-2 -7.98)">
                      <path
                        id="Path_60794"
                        data-name="Path 60794"
                        d="M14.32,7.98a15.173,15.173,0,0,0-8.6,2.473A7.841,7.841,0,0,0,2,16.78a.88.88,0,0,0,1.76,0A6.052,6.052,0,0,1,6.726,11.9,13.5,13.5,0,0,1,14.32,9.74a13.516,13.516,0,0,1,7.6,2.16A6.051,6.051,0,0,1,24.88,16.78a.88.88,0,0,0,1.76,0,7.839,7.839,0,0,0-3.722-6.327A15.167,15.167,0,0,0,14.32,7.98Z"
                        transform="translate(0 0)"
                        fill="#6b6b6b"
                      />
                      <path
                        id="Path_60795"
                        data-name="Path 60795"
                        d="M15.4,13.98a4.4,4.4,0,1,0,4.4,4.4A4.413,4.413,0,0,0,15.4,13.98Zm0,1.76a2.64,2.64,0,1,1-2.64,2.64A2.627,2.627,0,0,1,15.4,15.74Z"
                        transform="translate(-1.08 -0.72)"
                        fill="#6b6b6b"
                      />
                    </g>
                  </svg>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={file.original_url.replace("http://localhost", config.serverUrl)}
                    className="my-auto ms-2">
                    {t("COMMON.SHOW")}
                  </a>
                </div>
                <div className="seperator my-auto mx-3"></div>
                <div className="download my-auto d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 22.222" className="my-auto">
                    <path
                      id="Path_60816"
                      data-name="Path 60816"
                      d="M19.667,9.778H17.444a1.05,1.05,0,0,0-1.111,1.111A1.05,1.05,0,0,0,17.444,12h2.222a1.05,1.05,0,0,1,1.111,1.111v7.778A1.05,1.05,0,0,1,19.667,22H6.333a1.05,1.05,0,0,1-1.111-1.111V13.111A1.05,1.05,0,0,1,6.333,12H8.556a1.05,1.05,0,0,0,1.111-1.111A1.05,1.05,0,0,0,8.556,9.778H6.333A3.274,3.274,0,0,0,3,13.111v7.778a3.274,3.274,0,0,0,3.333,3.333H19.667A3.274,3.274,0,0,0,23,20.889V13.111A3.274,3.274,0,0,0,19.667,9.778ZM8.889,16.111l3.333,3.333a1.074,1.074,0,0,0,1.556,0l3.333-3.333a1.1,1.1,0,1,0-1.556-1.556L14.111,16V3.111a1.111,1.111,0,1,0-2.222,0V16l-1.444-1.444a1.1,1.1,0,0,0-1.556,1.556Z"
                      transform="translate(-3 -2)"
                      fill="#909537"
                    />
                  </svg>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={file.original_url.replace("http://localhost", config.serverUrl)}
                    className="my-auto ms-2">
                    {t("EVAL_FORMS.SAVE_FILE")}
                  </a>
                </div>
              </div>
            ))
          )}

          {content && (
            <a href={config.serverUrl + "/api/content/download_forms"}>
              <button className="btn-orange mt-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.666" height="19.532" viewBox="0 0 22.666 21.532" className="me-2">
                  <path
                    id="Path_60820"
                    data-name="Path 60820"
                    d="M14.795,15.295l-.329.34V12.7a1.133,1.133,0,1,0-2.267,0v2.935l-.329-.34A1.138,1.138,0,0,0,10.262,16.9l2.267,2.267a1.133,1.133,0,0,0,.374.238,1.065,1.065,0,0,0,.861,0,1.133,1.133,0,0,0,.374-.238L16.4,16.9a1.138,1.138,0,0,0-1.609-1.609ZM21.266,5.9H14.149l-.363-1.133A3.4,3.4,0,0,0,10.568,2.5H5.4A3.4,3.4,0,0,0,2,5.9V20.632a3.4,3.4,0,0,0,3.4,3.4H21.266a3.4,3.4,0,0,0,3.4-3.4V9.3A3.4,3.4,0,0,0,21.266,5.9ZM22.4,20.632a1.133,1.133,0,0,1-1.133,1.133H5.4a1.133,1.133,0,0,1-1.133-1.133V5.9A1.133,1.133,0,0,1,5.4,4.767h5.168a1.133,1.133,0,0,1,1.077.771L12.256,7.4a1.133,1.133,0,0,0,1.077.771h7.933A1.133,1.133,0,0,1,22.4,9.3Z"
                    transform="translate(-2 -2.5)"
                    fill="#fff"
                  />
                </svg>
                {t("EVAL_FORMS.SAVE_ALL_FILES")}
              </button>
            </a>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EvalMethods;
