// @ts-nocheck
import React from "react"

const Toggle = React.forwardRef(({children, onClick }, ref) => (
  <div
    ref={ref}
    className="notif-toggle my-auto"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    >
    {children && <div className="notifs-count d-flex">
      <span className="m-auto">{children}</span>
    </div>}
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g id="bell" transform="translate(-119.535 -179.535)">
        <rect id="Frame" width="30" height="30" transform="translate(119.535 179.535)" fill="#a6a6a6" opacity="0"/>
        <path id="bell-2" data-name="bell" d="M133.848,205.869a3.308,3.308,0,0,1-2.88-1.662.909.909,0,0,1,1.57-.916,1.559,1.559,0,0,0,2.621,0,.909.909,0,0,1,1.57.916A3.308,3.308,0,0,1,133.848,205.869Zm10.508-4.149a.91.91,0,0,0-.108-.961,11.166,11.166,0,0,1-2.23-6.1V190.42a8.17,8.17,0,0,0-16.34,0v4.245a11.166,11.166,0,0,1-2.23,6.1.909.909,0,0,0,.71,1.475h19.38A.909.909,0,0,0,144.356,201.721ZM127.5,194.665V190.42a6.353,6.353,0,1,1,12.706,0v4.245a12,12,0,0,0,1.657,5.753h-16.02A12,12,0,0,0,127.5,194.665Z" transform="translate(0.687 0.475)" fill="#a6a6a6"/>
      </g>
    </svg>
  </div>

))

export default Toggle;
