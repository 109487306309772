import React, { FC } from "react";
import { RegistrationRequest } from "../../../models/registration-request";
import awardicon from "../../../assets/icons/awardicon.png";

const Participations: FC<{ requests: RegistrationRequest[] }> = ({ requests }) => {
  return (
    <div className="db-card participations d-flex flex-column mb-4">
      <div className="d-flex mb-5">
        <h4 className="my-auto me-auto">المشاركات</h4>
        <h4 className="my-auto" style={{ color: "#EB9C3F" }}>
          مشاركات {requests.length}
        </h4>
      </div>
      {requests.length === 0 ? (
        <p className="no-certs">لم تشارك المدرسة في أي نسخة</p>
      ) : (
        requests.map((req) => (
          <div className="row mb-4" key={"participation-" + req.id}>
            <div className="col-6 tw-text-base lg:tw-text-lg tw-whitespace-nowrap">سنة {req.season.year}</div>
            <div className="col-6 tw-text-sm lg:tw-text-lg tw-whitespace-nowrap">
              {req.rank === 1
                ? "الشهادة الذهبية"
                : req.rank === 2
                ? "الشهادة الفضية"
                : req.rank === 3
                ? "الشهادة البرونزية"
                : req.rank < 10
                ? `من أفضل ${req.rank} مدارس`
                : "-"}
              {req.rank < 10 && <img className="aw-icon" alt="award" src={awardicon} />}
            </div>
          </div>
        ))
      )}
      {requests.length > 4 && <div className="show-all mt-auto">عرض جميع المشاركات</div>}
    </div>
  );
};

export default Participations;
