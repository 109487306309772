export const governorates = [
  "مسندم",
  "مسقط",
  "شمال الشرقيه",
  "جنوب الشرقيه",
  "البريمى",
  "ظفار",
  "شمال الباطنة",
  "الداخلية",
  "الظاهرة",
  "جنوب الباطنة",
  "الوسطى",
];

export const levels = ["حلقة أولى", "حلقة ثانية", "ما بعد الأساسي"];

export const genders = ["ذكور", "إناث", "مشترك"];
