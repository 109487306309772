import React, { FC, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import RouterRoutes from "./RouterRoutes";
import ScrollToTop from "./utils/ScrollToTop";
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-251031680-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const Router: FC = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RouterRoutes />
    </BrowserRouter>
  );
};

export default Router;
