import { ThunkAction } from 'redux-thunk'
import toast from 'react-hot-toast'

import api, { createInstance, setAccessToken } from '../../api'
import { AuthAction, LOGIN, LOG_OUT, LogInData, SET_LOADING, SET_USER } from '../types'
import i18n from '../../i18n'
import { RootState } from '..'


// Log in
export const login: any = (data: LogInData): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        const toastId = toast.loading(i18n.t('LOADING'))
        const body = JSON.stringify(data)
        try{
            let res = await api.post('/auth/login', body)
            setAccessToken(res.data.token)
            createInstance(res.data.token)
            toast.success(i18n.t('SUCCESS.LOGIN'), { id: toastId })
            dispatch({
                type: LOGIN,
                payload: res.data
            })
        } catch(err: any){
            toast.error(i18n.t(err?.response?.data.message || 'ERROR.ERROR_OCCURED'), {
                id: toastId
            })
        }
    }
}

// Log out
export const logout: any = (): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        const toastId = toast.loading(i18n.t('LOADING'))
        try{
            await api.get('/auth/logout')
            toast.success(i18n.t('SUCCESS.LOGOUT'), { id: toastId })
            dispatch({
                type: LOG_OUT
            })
        } catch(err: any){
            toast.error(i18n.t(err?.response?.data.message || 'ERROR.ERROR_OCCURED'), {
                id: toastId
            })
        }
    }
}

// forgotPassword
export const forgotPassword: any = (email: string): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        const toastId = toast.loading(i18n.t('LOADING'))
        const body = JSON.stringify({ email })

        try{
            await api.post('/auth/password/forgot', body)
            toast.success(i18n.t('SUCCESS.FORGOT_PASS_EMAIL_SENT'), { id: toastId })
        } catch(err: any){
            toast.error(i18n.t(err?.response?.data.message || 'ERROR.ERROR_OCCURED'), {
                id: toastId
            })
        }
    }
}

// Reset password
export const resetPassword: any = (data: {token: string, password: string}, cb: Function): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        const toastId = toast.loading(i18n.t('LOADING'))
        const body = JSON.stringify(data)

        try{
            await api.post('/auth/password/reset', body)
            toast.success(i18n.t('SUCCESS.PASSOWRD_UPDATED'), { id: toastId })
            cb()
        } catch(err: any){
            toast.error(i18n.t(err?.response?.data.message || 'ERROR.ERROR_OCCURED'), {
                id: toastId
            })
        }
    }
}
// Check token & load user
export const loadUser: Function = (): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        try {
            let res = await api.get('/auth/user')
            dispatch({
                type: SET_USER,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: SET_LOADING,
                payload: false
            })
        }
    }
}