import React, { FC, useState } from 'react'
import { School } from '../../../models/school'
import useTranslation from '../../../i18n/hooks/useTranslation'
import Pagination from '../../../components/Pagination'
import SchoolImage from '../../../components/SchoolImage'
import ActionsMenu from '../../../components/ActionsMenu'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

interface IProps {
    schools: School[]
    openDeleteSchool: (id: string|number) => void
    openUpdateSchool: (school: School) => void
}

const SchoolsTable: FC<IProps> = ({ schools, openDeleteSchool, openUpdateSchool }) => {
    
    const t = useTranslation()
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const currentData = schools.slice(
        (currentPage - 1) * limit,
        (currentPage - 1) * limit + limit
    );
    
    return (
        <>
            <table className="db-table schools-table mb-4">
                <thead>
                    <tr>
                        <th scope="col">المعرف</th>
                        <th scope="col">{t("THE_SCHOOL")}</th>
                        <th scope="col">{t("GENDER")}</th>
                        <th scope="col">{t("THE_GOVERNORATE")}</th>
                        <th scope="col">{t("CITY")}</th>
                        <th scope="col">{t("EDUCATION_LEVEL")}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {currentData.map(school =>
                        <tr key={"school-" + school.id}>
                            <td>{school.id}</td>
                            <td>
                                <SchoolImage style={{ width: 50, height: 50}} gender={school.gender} />
                                <Link to={"/admin/schools/" + school.id} className="ms-2" style={{ fontWeight: "bold" }}>{school.name}</Link>
                            </td>
                            <td>{school.gender}</td>
                            <td>{school.governorate}</td>
                            <td>{school.city}</td>
                            <td>{school.education_level}</td>
                            <td>
                                <ActionsMenu 
                                    actions={[
                                        {
                                            title: "تفاصيل",
                                            function: () => navigate(`/admin/schools/${school.id}`)
                                        },
                                        {
                                            title: t("DELETE"),
                                            function: () => openDeleteSchool(school.id!)
                                        },
                                        {
                                            title: t("UPDATE"),
                                            function: () => openUpdateSchool(school)
                                        }
                                    ]}
                                />
                            </td>
                        </tr>
                    )}
                    
                </tbody>
            </table>
            <div className="bottom">
                <Pagination 
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    limit={limit}
                    setLimit={setLimit}
                    totalRows={schools.length}
                />
            </div>
            <div className="py-5 my-5"></div>
        </>
    )
}

export default SchoolsTable