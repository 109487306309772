import React, { useState, FC, FormEvent } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import useTranslation from "../../i18n/hooks/useTranslation";
import Logo from "./logo.png";
import Logo_mobile from "./logo.svg";
import "./navbar.scss";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import i18n from "../../i18n";

const MainNavbar: FC = () => {
  const t = useTranslation();
  const lang = i18n.language;

  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleBurgerMenuOpen = (e: FormEvent) => {
    e.preventDefault();
    setMenuOpen((o) => !o);
  };

  return (
    <Navbar collapseOnSelect variant="dark" expand="lg" className="main-navbar">
      <Navbar.Brand href="/" className="logo me-4">
        <img src={Logo} alt="Logo" />
      </Navbar.Brand>
      {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
      <button type="button" aria-label="Toggle navigation" className="navbar-toggler collapsed" onClick={handleBurgerMenuOpen}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <Navbar.Collapse id="responsive-navbar-nav" className="d-none d-lg-block">
        <Nav className="mr-auto">
          <Nav.Item>
            <Link to="/" className={`nav-link me-1 ${pathname === "/" && "active"}`}>
              {t("NAV_LINKS.HOME")}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/about" className={`nav-link me-1 ${pathname === "/about" && "active"}`}>
              {t("NAV_LINKS.ABOUT_THE_INTIATIVE")}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/evaluation_forms" className={`nav-link me-1 ${pathname === "/evaluation_forms" && "active"}`}>
              {t("NAV_LINKS.EVAL_FORMS")}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/supporters" className={`nav-link me-1 ${pathname === "/supporters" && "active"}`}>
              {t("NAV_LINKS.SUPPORTERS")}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/evaluation_methods" className={`nav-link me-1 ${pathname === "/evaluation_methods" && "active"}`}>
              {t("NAV_LINKS.EVAL_METHODS")}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/participating_schools" className={`nav-link me-1 ${pathname === "/participating_schools" && "active"}`}>
              {t("NAV_LINKS.REGISTERED_SCHOOLS")}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/results" className={`nav-link ${pathname === "/results" && "active"}`}>
              {t("NAV_LINKS.RESULTS")}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/news" className={`nav-link ${pathname === "/news" && "active"}`}>
              {t("NAV_LINKS.NEWS")}
            </Link>
          </Nav.Item>
        </Nav>
        <Nav className="ms-auto">
          <Nav.Item>
            <Link to="/register_school">
              <button className="btn-trans-white">سجل المدرسة</button>
            </Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
      <div id="mobile-menu" className={`d-block d-lg-none shadow-lg ${menuOpen ? "show" : ""}`}>
        <button
          type="button"
          aria-label="Toggle navigation"
          className="navbar-toggler tw-absolute tw-top-[1.2em] tw-left-[0.4em] collapsed"
          onClick={handleBurgerMenuOpen}>
          <IoIosCloseCircleOutline className="close-icon" />
        </button>
        <div className="mobile-header d-block d-lg-none">
          <a href="/" className="mobile-logo">
            <img src={Logo_mobile} alt="Logo" />
          </a>
          <hr className="position-absolute" />
        </div>
        <Nav className="mr-auto">
          <Nav.Item>
            <Link to="/" className={`d-flex justify-content-between align-items-center nav-link me-1 ${pathname === "/" && "active"}`}>
              {t("NAV_LINKS.HOME")}
              {lang === "ar" ? <BsChevronLeft /> : <BsChevronRight />}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/about" className={`d-flex justify-content-between align-items-center nav-link me-1 ${pathname === "/about" && "active"}`}>
              {t("NAV_LINKS.ABOUT_THE_INTIATIVE")}
              {lang === "ar" ? <BsChevronLeft /> : <BsChevronRight />}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/evaluation_forms"
              className={`d-flex justify-content-between align-items-center nav-link me-1 ${pathname === "/evaluation_forms" && "active"}`}>
              {t("NAV_LINKS.EVAL_FORMS")}
              {lang === "ar" ? <BsChevronLeft /> : <BsChevronRight />}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/supporters"
              className={`d-flex justify-content-between align-items-center nav-link me-1 ${pathname === "/supporters" && "active"}`}>
              {t("NAV_LINKS.SUPPORTERS")}
              {lang === "ar" ? <BsChevronLeft /> : <BsChevronRight />}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/evaluation_methods"
              className={`d-flex justify-content-between align-items-center nav-link me-1 ${pathname === "/evaluation_methods" && "active"}`}>
              {t("NAV_LINKS.EVAL_METHODS")}
              {lang === "ar" ? <BsChevronLeft /> : <BsChevronRight />}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/participating_schools"
              className={`d-flex justify-content-between align-items-center nav-link me-1 ${pathname === "/participating_schools" && "active"}`}>
              {t("NAV_LINKS.REGISTERED_SCHOOLS")}
              {lang === "ar" ? <BsChevronLeft /> : <BsChevronRight />}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/results" className={`d-flex justify-content-between align-items-center nav-link ${pathname === "/results" && "active"}`}>
              {t("NAV_LINKS.RESULTS")}
              {lang === "ar" ? <BsChevronLeft /> : <BsChevronRight />}
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/news" className={`d-flex justify-content-between align-items-center nav-link ${pathname === "/news" && "active"}`}>
              {t("NAV_LINKS.NEWS")}
              {lang === "ar" ? <BsChevronLeft /> : <BsChevronRight />}
            </Link>
          </Nav.Item>
        </Nav>
      </div>
    </Navbar>
  );
};

export default MainNavbar;
