import React, { FC } from "react";
import { Media } from "../../models/media";
import PDFIcon from "../../assets/icons/pdf.svg";
import DeleteBtn from "../../assets/icons/times.svg";
import "./file-card.scss";
import moment from "moment";

const FileCard: FC<Media & { deleteFile: Function; idx: number }> = ({ original_url, deleteFile, collection_name, idx, name, created_at }) => {
  return (
    <div className="file-card me-4 mb-4">
      <div className="icon-cont d-flex tw-relative">
        <img src={PDFIcon} className="m-auto" alt="pdf file" />
        <img src={DeleteBtn} className="close" alt="delete" onClick={() => deleteFile(idx, collection_name)} />
      </div>
      <div className="details mx-3 pt-3">
        <div className="name">{name}</div>
        <div className="created_at">تم رفع الملف في {moment(created_at).format("L")}</div>
      </div>
    </div>
  );
};

export default FileCard;
