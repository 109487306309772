import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../api";
import ConfirmDelete from "../../../components/ConfirmDelete";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { News } from "../../../models/news";
import AddNewsModal from "./AddNewsModal";
import NewsTable from "./NewsTable";
import "./managenews.scss";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";

const ManageNews: FC = () => {
  const t = useTranslation();

  const [news, setNews] = useState<News[] | null>(null);
  const [idToDelete, setIdToDelete] = useState<number | string>("");
  const [articleToUpdate, setArticleToUpdate] = useState<News | null>(null);
  const [shownModal, setShownModal] = useState("");

  const getNews = async () => {
    let { data } = await api.get("/news");
    setNews(data);
    setArticleToUpdate(data[0]);
  };

  const newsAdded = (newArticle: News) => {
    setShownModal("");
    setNews([newArticle, ...news!]);
  };

  const newsUpdated = (article: any) => {
    setShownModal("");
    let newArticles = JSON.parse(JSON.stringify(news));
    let index = newArticles.findIndex((el: any) => el.id === article.id);
    newArticles[index] = article;
    setNews(newArticles); // TODO: DELETE
  };

  const deleteNews = async () => {
    let id = idToDelete;
    await api.delete("/news/" + id);
    toast.success(t("SUCCESS.DELETED"));
    setNews(news!.filter((s) => s.id !== id));
    setShownModal("");
  };

  const handleDeleteButton = async (id: number | string) => {
    setIdToDelete(id);
    setShownModal("delete");
  };

  const handleEditButton = async (article: News) => {
    setArticleToUpdate(article);
    setShownModal("update");
  };

  const toggleModal = () => {
    setShownModal((o) => (o === "add" ? "" : "add"));
  };

  useEffect(() => {
    if (!news) getNews();
  }, [news]);

  if (!news) return <LoadingSpinner />;

  return (
    <div className="manage-news-page">
      <div className="d-flex justify-content-between mb-5">
        {shownModal === "add" || shownModal === "update" ? (
          <h1 className="tw-text-lg md:tw-text-2xl">{t("MANAGE_NEWS.MANAGE_NEWS")}</h1>
        ) : (
          <h1 className="tw-text-lg md:tw-text-2xl">{t("MANAGE_NEWS.TITLE")}</h1>
        )}
        <button className="btn my-auto btn-primary green px-4" onClick={() => toggleModal()}>
          {t("MANAGE_NEWS.ADD_NEWS")}
        </button>
      </div>
      <AddNewsModal setShown={setShownModal} shown={shownModal} newsAdded={newsAdded} newsUpdated={newsUpdated} articleToUpdate={articleToUpdate} />
      {shownModal === "add" || (shownModal === "update" && <h1 className="tw-text-lg md:tw-text-2xl mb-5">{t("MANAGE_NEWS.TITLE")}</h1>)}

      <NewsTable news={news} handleDeleteButton={handleDeleteButton} handleEditButton={handleEditButton} />

      {/* {shownModal === "add" && <AddNewsModal setShown={setShownModal} shown={shownModal} newsAdded={newsAdded} />} */}

      {/* {shownModal === "update" && (
        <UpdateNewsModal setShown={setShownModal} shown={shownModal} article={articleToUpdate!} newsUpdated={newsUpdated} />
      )} */}

      {shownModal === "delete" && <ConfirmDelete setShown={setShownModal} shown={shownModal} handleDelete={deleteNews} />}
    </div>
  );
};

export default ManageNews;
