import React, { FC, useEffect } from 'react';
import SelectSearch from 'react-select-search';
import Arrow from './Arrow';
import './style.scss'

interface IProps{
    entityName?: string;
    currentPage: number;
    totalRows: number;
    limit: number;
    setLimit: Function;
    setCurrentPage: Function;
}
const LEFT_PAGE = "..."
const RIGHT_PAGE = "..."
const pageNeighbours = 2

const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

const Pagination: FC<IProps> = ({ currentPage, totalRows, limit, setLimit, setCurrentPage, entityName = "مدارس" }) => {

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }, [currentPage]);

    
    const totalPages = Math.ceil(totalRows / limit)
    
    const fetchPageNumbers = () => {
        const totalNumbers = pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;
    
        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
    
            let pages: any[] = range(startPage, endPage);
    
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = totalPages - endPage > 1;
            const spillOffset = totalNumbers - (pages.length + 1);
    
            switch (true) {
                case hasLeftSpill && !hasRightSpill: {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }
                case hasLeftSpill && hasRightSpill:
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, totalPages];
        }
        return range(1, totalPages);
    };

    const pages = fetchPageNumbers() || []

    if(currentPage < 5 && pages[1] === "..."){
        pages.splice(1,1)
    }
    return (
        <div className="pagination-bottom d-flex mt-1 mb-3">
            <nav className="my-auto me-4 d-flex">
                <Arrow direction='right' disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)} />
                {pages.reverse().map((page, index) => 
                    <span className={`page-item mx-2 my-auto ${page === currentPage ? "active" : ""} ${page === "..." ? "disabled" : ""}`} 
                        onClick={() => setCurrentPage(page === "..." ? currentPage : page )}  key={"page-" + index}>
                        <a className="page-link" href="#!">{page}</a>
                    </span>
                )}
                <Arrow direction='left' disabled={currentPage ===  1} onClick={() => setCurrentPage(currentPage - 1)} />
            </nav>
            <SelectSearch
                className={"SelectSec my-auto"}
                onChange={(...args: any) => {
                    setLimit(Number( args[0]));
                    setCurrentPage(1);
                }}
                options={[
                    { value: 10, name: " أظهر 10" + entityName }, 
                    { value: 25, name: "أظهر 25 " + entityName}, 
                    { value: 50, name: " أظهر 50" + entityName},
                    { value: 100, name: " أظهر 100" + entityName}
                ]}
                value={limit.toString()}
            />
        </div>
    );
}

export default Pagination;