import React, { FC } from "react";
import QuoteBlock from "../../../components/QuoteBlock";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { Co1, Co2, Co3, Co4, Co5, Co6, MCo1, MCo2, MCo3, MCo4, MCo5, MCo6 } from "./ContainSection";
import Header from "./Header";
import "./eval-methods.scss";
import { Tabsection, MTabsection } from "./Tabs";
import Footer from "../../../components/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css'

const EvalMethods: FC = () => {
  AOS.init();
  const t = useTranslation();

  return (
    <div className="eval-methods-page ">
      <Header title={t("EVAL_METHODS.TITLE")} />
      <div className="container ">
        <div className="d-flex w-100 mb-5 px-4">
          <QuoteBlock text={t("EVAL_METHODS.QUOTE")} className="mx-auto" />
        </div>
        <div className="row">
          <div className="col-md-3">
            <Tabsection />
          </div>
          <div className="col-md-9 des-co px-4">
            <Co1 />
            <Co2 />
            <Co3 />
            <Co4 />
            <Co5 />
            <Co6 />
          </div>
          <MTabsection />
          <div className="res-co px-4 tw-my-10 tw-transition-all tw-duration-300">
            <MCo1 />
            <MCo2 />
            <MCo3 />
            <MCo4 />
            <MCo5 />
            <MCo6 />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EvalMethods;
