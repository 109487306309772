//@ts-nocheck
import React, { FC, useState } from 'react'
import useTranslation from '../../../i18n/hooks/useTranslation'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import api from '../../../api'
import toast from 'react-hot-toast'
import { EmailContent } from '../../../models/email-content';

const EmailCard: FC<{displayName: string, emailData: EmailContent, setEmails: Function}> = ({ displayName, emailData, setEmails }) => {
    const t = useTranslation()
    

    const [title, setTitle] = useState<string>(emailData.title)
    const [htmlBody, setHtmlBody] = useState<any>()

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        const toastId = toast.loading(t('LOADING'))
        const body = JSON.stringify({ 
            title,  
            body: htmlBody
        })

        try {
            let { data } = await api.put('/emails_content/' + emailData.id, body)
            setEmails(data)
            toast.success(t('SUCCESS.CONTENT_UPDATED'), { id: toastId })
        } catch (err: any) {
            toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED") , {
                id: toastId,
            })
        }
    }
    
    return (
        <form onSubmit={submitHandler} className="mb-5">
            <h6 className="mb-3">{displayName}</h6>
            <div className="db-card mb-4">
                <label className="label">العنوان</label>
                <input type="text" placeholder={displayName} value={title} className="input"
                    onChange={(e) => setTitle(e.target.value)} required />
                <label className="label">المحتوى</label>
                <div className="db-textarea">
                    <CKEditor
                        className="db-textarea"
                        editor={ ClassicEditor }
                        data={emailData.body}
                        config={ {
                            removePlugins: [ 'Heading' ],
                            language: {
                                ui: 'ar',
                                content: 'ar',
                            }
                        }}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setHtmlBody(data);
                        }}
                    />
                </div>
                <div className="d-flex mt-3">
                    <button type="submit" className="ms-auto btn-green">حفظ التغيرات</button>
                </div>
            </div>
        </form>
    )
}

export default EmailCard