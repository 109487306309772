import React, { FC } from "react";
import "./chart-toggler.scss";

interface IProps {
  titles: string[];
  selected: string;
  setSelected: (title: string) => void;
}

const ChartToggler: FC<IProps> = ({ titles, selected, setSelected }) => {
  return (
    <div className="chart-toggler d-flex">
      {titles.map(t => 
        <div className={`t-item ${t === selected ? "active" : ""}`} onClick={() => setSelected(t)}>
          {t}
        </div>
      )}
    </div>
  );
};

export default ChartToggler;
