import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import awardicon from "../../../assets/icons/awardicon.png";
import Fplace from "../../../assets/icons/Fplace.png";
import Tplace from "../../../assets/icons/Tplace.png";
import Splace from "../../../assets/icons/Splace.png";

import { RegistrationRequest } from "../../../models/registration-request";

export const SchoolDet: React.FC<{ requests: RegistrationRequest[] }> = ({ requests }) => {
  let participations = requests.filter((req) => req.status === "accepted" && req.rank);
  let certs = participations.filter((p) => p.rank <= 3);

  return (
    <div className="SchoolDet tw-shadow-sm">
      <div className="tw-text-lg tw-font-bold tw-mb-8">مشاركات المدرسة في مبادرة تعزيز الصحة المدرسية</div>
      <Row className="tw-mb-12">
        <Col xs={12} md={9} className="rightsidecard">
          <table className="awardtable mb-4">
            <tbody>
              {participations.map((p) => (
                <tr key={"partip-" + p.season.id}>
                  <td>سنة {p.season.year}</td>
                  <td className="award">
                    {p.rank === 1
                      ? "الشهادة الذهبية"
                      : p.rank === 2
                      ? "الشهادة الفضية"
                      : p.rank === 3
                      ? "الشهادة البرونزية"
                      : p.rank < 10
                      ? `من أفضل ${p.rank} مدارس`
                      : ""}

                    {p.rank < 10 && <img className="aw-icon" alt="award" src={awardicon} />}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
        <Col xs={12} md={3} className="leftsidecard d-flex">
          <div className="SUB-card m-auto">
            <h3>{participations.length} مشاركات</h3>
          </div>
        </Col>
      </Row>
      <div className="tw-text-lg tw-font-bold tw-mb-6"> الشهادات</div>
      {certs.length === 0 ? (
        <p className="no-certs">لا توجد اي شهادات مكتسبة حتى الان</p>
      ) : (
        certs.map((p) => (
          <div className="card" key={"medal-" + p.id}>
            <Container>
              <Row>
                <Col className="d-flex" xs={2}>
                  <img className="carddegicon m-auto" src={p.rank === 1 ? Fplace : p.rank === 2 ? Splace : Tplace} alt="medal" />
                </Col>
                <Col className="carddegright d-flex" xs={5}>
                  <div className="m-auto">
                    <Row>
                      <p className="DegTitle">
                        {p.rank === 1 ? "الشهادة الذهبية" : p.rank === 2 ? "الشهادة الفضية" : p.rank === 3 ? "الشهادة البرونزية" : ""}
                      </p>
                    </Row>
                    <Row>
                      <p>صالحة لمدة 3 سنوات</p>
                    </Row>
                  </div>
                </Col>
                <Col className="carddegleft d-flex" xs={5}>
                  <div className="my-auto">
                    <p className="Degyear">سنة {p.season.year}</p>
                    <Row>
                      <p className="staut">نشطة</p>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ))
      )}
    </div>
  );
};
