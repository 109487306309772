import React, { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../api";
import ConfirmDelete from "../../../components/ConfirmDelete";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { Season } from "../../../models/season";
import AddSeasonModal from "./AddSeason";
import SeasonsTable from "./SeasonsTable";
import "./manage-seasons.scss";
import CurrentSeason from "./CurrentSeasons";
import UpdateSeason from "./UpdateSeason";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";

const ManageSeasons: FC = () => {
  const t = useTranslation();

  const [seasons, setSeasons] = useState<Season[] | null>(null);
  const [idToDelete, setIdToDelete] = useState<number | string>("");
  const [shownModal, setShownModal] = useState("");
  const [showWarningModal, setShowWarningModal] = useState(false);

  const getSeasons = async () => {
    let { data } = await api.get("/seasons");
    setSeasons(data);
  };

  const seasonAdded = () => {
    setShownModal("");
    setSeasons(null);
  };

  const deleteSeason = async () => {
    let id = idToDelete;
    await api.delete("/seasons/" + id);
    toast.success(t("SUCCESS.DELETED"));
    setSeasons(seasons!.filter((s) => s.id !== id));
    setShownModal("");
  };

  const openDeleteSeason = async (id: number | string) => {
    setIdToDelete(id);
    setShownModal("delete");
  };

  useEffect(() => {
    if (!seasons) getSeasons();
  }, [seasons]);

  const handleAddSeason = () => {
    // TODO: Oussama : what should we test here, how to know that there is an "active" season ?
    if (seasons && seasons[0].resultsPublished) {
      setShowWarningModal(true);
    } else {
      setShownModal("add");
    }
  };

  if (!seasons) return <LoadingSpinner />;

  return (
    <div>
      <div
        id="popup-modal"
        className={`${
          showWarningModal ? "" : "tw-hidden"
        } tw-bg-black tw-bg-opacity-90 tw-overflow-y-auto tw-overflow-x-hidden tw-fixed tw-top-0 tw-right-0 tw-left-0 md:tw-inset-0 tw-h-modal md:tw-h-full`}>
        <div className="tw-relative tw-top-[50%] tw-left-0 tw-max-w-xl tw-mr-[50%] tw-translate-x-1/2 -tw-translate-y-1/2 tw-bg-white tw-rounded-lg tw-shadow">
          <button
            type="button"
            onClick={() => setShowWarningModal(false)}
            className="tw-absolute tw-top-3 tw-right-2.5 tw-text-gray-400 tw-bg-transparent hover:tw-bg-gray-200 hover:tw-text-gray-900 tw-rounded-lg tw-text-sm tw-p-1.5 tw-ml-auto tw-inline-flex tw-items-center">
            <svg aria-hidden="true" className="tw-w-5 tw-h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
            </svg>
            <span className="tw-sr-only">Close modal</span>
          </button>
          <div className="tw-p-6 tw-text-center">
            <svg
              aria-hidden="true"
              className="tw-mx-auto tw-mb-8 tw-w-14 tw-h-14 tw-text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <h3 className="tw-mb-5 tw-text-lg tw-font-normal tw-text-gray-500">
              يوجد حاليًا موسم آخر نشط، إذا أضفت موسمًا جديدًا الآن فسيتم إغلاق الموسم السابق تلقائيا، هل أنت متأكد من أنك تريد المتابعة؟
            </h3>
            <div className="tw-flex tw-justify-center gap-3 w-full">
              <button
                type="button"
                onClick={() => {
                  setShowWarningModal(false);
                  setShownModal("add");
                }}
                className="btn-c btn-green w-80 me-2">
                إضافة موسم جديد
              </button>
              <button type="button" onClick={() => setShowWarningModal(false)} className="btn-c btn-cancel w-20">
                إلغاء
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex mb-4">
        <h1 className="my-auto me-auto tw-text-lg md:tw-text-2xl">{t("MANAGE_SEASONS.TITLE")}</h1>
        <button className="btn-green my-auto tw-text-sm md:tw-text-lg" onClick={() => handleAddSeason()}>
          {t("MANAGE_SEASONS.ADD_SEASON")}
        </button>
      </div>
      <CurrentSeason
        season={seasons[0]}
        openModal={() => setShownModal("add")}
        openUpdateModal={() => setShownModal("updateEndDate")}
        seasonUpdated={getSeasons}
      />
      <SeasonsTable seasons={seasons.slice(1)} openDeleteSeason={openDeleteSeason} seasonUpdated={getSeasons} />

      {shownModal === "add" && <AddSeasonModal setShown={setShownModal} shown={shownModal} seasonAdded={seasonAdded} />}

      {shownModal === "updateEndDate" && <UpdateSeason setShown={setShownModal} shown={shownModal} season={seasons[0]} seasonUpdated={getSeasons} />}

      {shownModal === "delete" && <ConfirmDelete setShown={setShownModal} shown={shownModal} handleDelete={deleteSeason} />}
    </div>
  );
};

export default ManageSeasons;
