import React, { FC, useState, useEffect } from "react";
import Navbar from "./Navbar";
import "./dashboard-layout.scss";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";

const DashboardLayout: FC<{ children: JSX.Element }> = ({ children }) => {
  const location = useLocation();

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [sideBarOverlap, setSideBarOverlap] = useState(false);

  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }
  const toggleSideBar = () => {
    setSideBarOpen((s) => !s);
  };

  const switchSideBar = (open: boolean) => {
    setSideBarOpen(open);
  };

  useEffect(() => {
    if (dimensions && dimensions.width < 1280) {
      switchSideBar(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (dimensions.width < 1280) {
      if (!sideBarOverlap) setSideBarOverlap(true);
      if (sideBarOpen) switchSideBar(false);
    } else {
      if (sideBarOverlap) setSideBarOverlap(false);
      if (!sideBarOpen) switchSideBar(true);
    }
    // eslint-disable-next-line
  }, [dimensions]);

  useEffect(() => {
    if (sideBarOverlap) {
      if (sideBarOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "initial";
      }
    }
  }, [sideBarOpen, sideBarOverlap]);

  return (
    <div className={`admin-dashboard-layout ${sideBarOpen ? "" : "sidebar-closed"} ${sideBarOverlap ? "sidebar-overlap" : ""}`}>
      <Navbar toggleSideBar={toggleSideBar} />
      <div className="content">
        <Sidebar />
        <main>{children}</main>
      </div>
    </div>
  );
};

export default DashboardLayout;
