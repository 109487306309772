import React, { FC, useEffect, useState } from "react";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { Content } from "../../../models/content";
import api from "../../../api";
import Form from "./Form";
import "./content.scss";
import toast from "react-hot-toast";
import AddEvalFile from "./AddEvalFile";
import EvalFiles from "./EvalFiles";
import Images from "./Images";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";

const ManageContent: FC = () => {
  const t = useTranslation();

  const [content, setContent] = useState<Content | null>(null);
  const [shownModal, setShownModal] = useState("");

  const uploadFile = async (e: React.FormEvent<HTMLInputElement>) => {
    const toastId = toast.loading(t("LOADING") + "...");
    try {
      const formData = new FormData();
      formData.append("file", e.currentTarget.files![0]);
      formData.append("collection", "gallery");
      formData.append("name", "gallery");
      let { data } = await api.post("/content/files", formData, {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let precentage = Math.floor((loaded * 100) / total);
          if (precentage < 100) {
            toast.loading(t("LOADING") + "... " + precentage + "%", { id: toastId });
          }
        },
      });
      setContent(data.content);
      toast.success(t("SUCCESS.FILE_UPLOADED"), { id: toastId });
    } catch (err: any) {
      toast.error(t(err?.response?.data.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
    }
  };

  const deleteFile = async (index: number, collection: string) => {
    const toastId = toast.loading(t("LOADING") + "...");
    try {
      await api.delete(`/content/files/${collection}/${index}`);
      setContent(null);
      toast.success(t("SUCCESS.DELETED"), { id: toastId });
    } catch (err: any) {
      toast.error(t(err?.response?.data.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
    }
  };

  const getContent = async () => {
    let { data } = await api.get("/content");
    setContent(data);
  };

  useEffect(() => {
    if (!content) getContent();
  }, [content]);

  if (!content) return <LoadingSpinner />;

  return (
    <div>
      {shownModal === "add" && <AddEvalFile setShown={setShownModal} shown={shownModal} setContent={setContent} />}
      <h1 className="mb-4 tw-text-lg md:tw-text-2xl">إدارة المحتوى</h1>
      <Form content={content} setContent={setContent} />

      <EvalFiles deleteFile={deleteFile} forms={content.forms} setShownModal={setShownModal} />
      <Images deleteFile={deleteFile} gallery={content.gallery} uploadFile={uploadFile} />
    </div>
  );
};

export default ManageContent;
