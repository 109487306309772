import axios from 'axios'
import config from './config'

let ACCESS_TOKEN: null = null
let api = createInstance(ACCESS_TOKEN)

export function createInstance(accessToken: string | null) {
    return axios.create({
        baseURL: config.apiBaseUrl,
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${accessToken}`,
        }
    })
}

export function setAccessToken(accessToken: null) {
    ACCESS_TOKEN = accessToken
    api = createInstance(accessToken)
}
  
export function getAccessToken() {
    return ACCESS_TOKEN || localStorage.getItem('token')
}

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers!.Authorization = `Bearer ${token}`
    }
    return config
})

export default api