import moment from 'moment'
import React, { FC } from 'react'
import ActionsMenu from '../../../components/ActionsMenu'
import useTranslation from '../../../i18n/hooks/useTranslation'
import { User } from '../../../models/user'

interface IProps {
    admins: User[]
    openDeleteAdmin: (id: string|number) => void
    openUpdatePassword: (id: string|number) => void
}

const AdminsTable: FC<IProps> = ({ admins, openDeleteAdmin, openUpdatePassword }) => {
    
    const t = useTranslation()

    return (
        <table className="db-table admins-table">
            <thead>
                <tr>
                <th scope="col">id</th>
                <th scope="col">{t("NAME")}</th>
                <th scope="col">{t("TITLE")}</th>
                <th scope="col">الايميل</th>
                <th scope="col">{t("MINISTRY")}</th>
                <th scope="col">{t("LAST_LOGIN")}</th>
                <th scope="col">{t("CREATED_AT")}</th>
                <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {admins.map(admin =>
                    <tr key={"admin-" + admin.id}>
                        <td>{admin.id}</td>
                        <td>{admin.name}</td>
                        <td>{admin.title}</td>
                        <td>{admin.email}</td>
                        <td>{t(admin.ministry)}</td>
                        <td>{admin.last_login ? moment(admin.last_login).locale("en").format('HH:mm DD/MM/YYYY') : "-"}</td>
                        <td>{moment(admin.created_at).locale("en").format('DD/MM/YYYY')}</td>
                        <td>
                            <ActionsMenu 
                                actions={[
                                    {
                                        title: t("DELETE"),
                                        function: () => openDeleteAdmin(admin.id)
                                    },
                                    {
                                        title: t("AUTH.CHANGE_PASSWORD"),
                                        function: () => openUpdatePassword(admin.id)
                                    }
                                ]}
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default AdminsTable