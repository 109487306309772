import React, { FC, useEffect } from "react";
import ApexCharts from "apexcharts";

interface IProps {
  pointTitle: string;
  categories: string[];
  data: number[];
}

const LineChart: FC<IProps> = ({ pointTitle, categories, data }) => {
  useEffect(() => {
    if (data) {
      const element = document.getElementById("kt_line_chart");

      if (!element) {
        return;
      }

      const options = getChartOption(data, pointTitle, categories);
      const chartnewUsers = new ApexCharts(element, options);
      chartnewUsers.render();
      return function cleanUp() {
        chartnewUsers.destroy();
      };
    }
    // eslint-disable-next-line
  }, [data]);

  return <div id="kt_line_chart" className="card-rounded-bottom tw-h-full"></div>;
};
function getChartOption(data: number[], pointTitle: string, categories: string[]) {
  var options = {
    series: [
      {
        name: pointTitle,
        data,
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0.1,
        stops: [0, 100],
      },
    },
    grid: {
      show: false,
      padding: {
        left: 20,
        right: 20, // Also you may want to increase this (based on the length of your labels)
      },
    },
    chart: {
      type: "area",
      height: "300px",
      fontFamily: "Montserrat, sans-serif",
      background: "#fff",
      foreColor: "#C0BDCC",
      toolbar: {
        show: false,
      },
    },
    colors: ["#909537"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      type: "category",
      categories,
    },
  };
  return options;
}
export default LineChart;
