// @ts-nocheck
import React, { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import './actions-menu.scss'

interface Action{
    title: string;
    function: Function;
}

interface IProps {
    actions: Action[]
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <svg width="18" height="6" viewBox="0 0 18 6"
        style={{ cursor: "pointer"}}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}>
        <g id="icon_more_copy_3" data-name="icon more copy 3" transform="translate(-923 -757)">
            <path id="icon_more" data-name="icon more" d="M937,759a2,2,0,1,1,2,2A2,2,0,0,1,937,759Zm-7,0a2,2,0,1,1,2,2A2,2,0,0,1,930,759Zm-7,0a2,2,0,1,1,2,2A2,2,0,0,1,923,759Z" fill="#c0bdcc"/>
        </g>
    </svg>

));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  
      return (
        <div
          ref={ref}
          style={style}
          className={className + " actions-menu"}
          aria-labelledby={labeledBy}
        >
            {children}
        </div>
      );
    },
  );
const ActionsMenu: FC<IProps> = ({ actions }) => {
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" >
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu}>
                {
                    actions.map(action => 
                        <div className="action" key={action.title} onClick={action.function}>{action.title}</div>
                    )
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default ActionsMenu;