import moment from "moment";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Season } from "../../../../models/season";

const Closed: FC<{ season: Season; seasonUpdated: Function; openModal: Function }> = ({ season, seasonUpdated, openModal }) => {
  let participating_schools = season.registration_requests.filter((r) => r.status === "accepted").length;

  return (
    <>
      <div className="lg:tw-col-span-8">
        <h4 className="mb-0">
          الموسم الحالي <span className="green">{season.year}</span>
        </h4>
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-6">
          <div className="lg:tw-col-span-4 d-flex details-card tw-pt-6">
            <div className="icon orange d-flex me-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                <g id="award-check" transform="translate(-3508 -1492)">
                  <rect id="Frame" width="45" height="45" transform="translate(3508 1492)" fill="#eb9c3f" opacity="0" />
                  <path
                    id="award-check-2"
                    data-name="award-check"
                    d="M3538.835,1503.976l-.6-.6a.631.631,0,0,1-.184-.443v-.84a5.47,5.47,0,0,0-5.465-5.466h-.84a.63.63,0,0,1-.442-.184l-.6-.6a5.473,5.473,0,0,0-7.732,0l-.6.6a.63.63,0,0,1-.442.184h-.84a5.47,5.47,0,0,0-5.465,5.466v.84a.623.623,0,0,1-.184.441l-.6.6a5.475,5.475,0,0,0,0,7.731l.6.6a.631.631,0,0,1,.184.443v.84a5.467,5.467,0,0,0,3.437,5.066l-2.955,10.365a1.408,1.408,0,0,0,1.877,1.691l5.19-2.08a9.839,9.839,0,0,1,7.32,0l5.193,2.081a1.423,1.423,0,0,0,.523.1,1.408,1.408,0,0,0,1.354-1.792l-2.955-10.365a5.467,5.467,0,0,0,3.437-5.066v-.84a.623.623,0,0,1,.184-.441l.6-.6v0A5.475,5.475,0,0,0,3538.835,1503.976Zm-7.29,22.041a12.662,12.662,0,0,0-9.409,0l-2.529,1.014,2.274-7.98h.057a.63.63,0,0,1,.442.184l.6.6a5.473,5.473,0,0,0,7.732,0l.6-.6a.63.63,0,0,1,.442-.184h.057l2.274,7.98Zm5.3-16.3-.6.6a3.406,3.406,0,0,0-1.009,2.43v.84a2.654,2.654,0,0,1-2.653,2.653h-.84a3.458,3.458,0,0,0-2.432,1.009l-.6.6a2.721,2.721,0,0,1-3.754,0l-.6-.6a3.458,3.458,0,0,0-2.432-1.009h-.84a2.654,2.654,0,0,1-2.653-2.653v-.84a3.413,3.413,0,0,0-1.009-2.432l-.6-.6a2.658,2.658,0,0,1,0-3.752l.6-.6a3.406,3.406,0,0,0,1.009-2.43v-.84a2.654,2.654,0,0,1,2.653-2.653h.84a3.458,3.458,0,0,0,2.432-1.009l.6-.6a2.721,2.721,0,0,1,3.754,0l.6.6a3.458,3.458,0,0,0,2.432,1.009h.84a2.654,2.654,0,0,1,2.653,2.653v.84a3.413,3.413,0,0,0,1.009,2.432l.6.6A2.661,2.661,0,0,1,3536.847,1509.72Zm-5.261-5.372a1.407,1.407,0,0,1,0,1.99l-5,5a1.408,1.408,0,0,1-1.988,0l-2.5-2.5a1.405,1.405,0,1,1,1.987-1.988l1.506,1.506,4.007-4.007A1.4,1.4,0,0,1,3531.586,1504.348Z"
                    transform="translate(4.595 1.97)"
                    fill="#eb9c3f"
                  />
                </g>
              </svg>
            </div>
            <div className="details">
              <div className="title mb-1">المدارس المشاركة</div>
              <div className="count">{participating_schools}</div>
            </div>
          </div>
          <div className="lg:tw-col-span-4 d-flex details-card lg:tw-pt-6">
            <div className="icon d-flex me-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="30.278" height="30.277" viewBox="0 0 30.278 30.277">
                <path
                  id="Path_62388"
                  data-name="Path 62388"
                  d="M28.493,5.028H23.951V2.757a.757.757,0,1,0-1.514,0V5.028H11.84V2.757a.757.757,0,1,0-1.514,0V5.028H5.784A3.789,3.789,0,0,0,2,8.812v19.68a3.789,3.789,0,0,0,3.785,3.785H28.493a3.788,3.788,0,0,0,3.785-3.785V8.812A3.788,3.788,0,0,0,28.493,5.028Zm2.271,23.465a2.273,2.273,0,0,1-2.271,2.271H5.784a2.273,2.273,0,0,1-2.271-2.271V15.625h27.25Zm0-14.382H3.513v-5.3A2.273,2.273,0,0,1,5.784,6.542h4.542V8.812h0a.756.756,0,0,0,.757.756h0a.756.756,0,0,0,.756-.757V6.542h10.6V8.812h0a.756.756,0,0,0,.757.756h0a.756.756,0,0,0,.756-.757V6.542h4.542a2.273,2.273,0,0,1,2.271,2.271Z"
                  transform="translate(-2 -2)"
                  fill="#909537"
                />
              </svg>
            </div>
            <div className="details">
              <div className="title mb-1">حالة الموسم</div>
              <div className="state mb-1">{season.resultsPublished ? "تم نشر النتائج" : "فترة التقييم"}</div>
              <div className="date">
                {moment(season.resultsPublished ? season.resultsPublishedAt : season.endDate)
                  .locale("en")
                  .format("DD/MM/YYYY HH:mm")}
              </div>
            </div>
          </div>
          <div className="lg:tw-col-span-4">
            <div className="fz-14 fw-600">تم بدأ الموسم</div>
            <div className="date mb-1">{moment(season.created_at).locale("en").format("DD/MM/YYYY HH:mm")}</div>

            <div className="fz-14 fw-600">التسجيل مفتوح</div>
            <div className="date mb-1">{moment(season.startDate).locale("en").format("DD/MM/YYYY HH:mm")}</div>

            <div className="fz-14 fw-600">بداية فترة التقييم</div>
            <div className="date mb-1">{moment(season.endDate).locale("en").format("DD/MM/YYYY HH:mm")}</div>
          </div>
        </div>
      </div>
      {season.resultsPublished ? (
        <div className="lg:tw-col-span-4 tw-border-t tw-border-t-[#707070]/20 lg:tw-border-t-0 lg:tw-border-r lg:tw-border-r-[#707070]/20 lg:tw-px-4">
          <h4 className="green fz-16 mb-3 mt-2">تم نشر النتائج</h4>
          <div className="mb-1">
            <span className="fz-14 fw-600 me-2">تم النشر بتاريخ :</span>
            <span className="fz-14 fw-600" style={{ color: "#B5B5B5" }}>
              {moment(season.resultsPublishedAt).locale("en").format("DD/MM/YYYY")}
            </span>
          </div>
          <div className="mb-1">
            <span className="fz-14 fw-600 me-2">التوقيت : </span>
            <span className="fz-14 fw-600" style={{ color: "#B5B5B5" }}>
              {moment(season.resultsPublishedAt).locale("en").format("HH:mm")}
            </span>
          </div>
          <div className="mb-3">
            <span className="fz-14 fw-600 me-2">بواسطة :</span>
            <span className="fz-14 fw-600" style={{ color: "#B5B5B5" }}>
              {season.user?.name}
            </span>
          </div>

          <Link to="/results" target="_blank" className="link orange me-3">
            عرض النتائج{" "}
          </Link>
          <span className="link" onClick={() => openModal()}>
            إضافة موسم جديد
          </span>
        </div>
      ) : (
        <div className="lg:tw-col-span-4 tw-border-t tw-border-t-[#707070]/20 lg:tw-border-t-0 lg:tw-border-r lg:tw-border-r-[#707070]/20 lg:tw-px-4">
          <h4 className="green fz-16 mb-3 mt-4 tw-text-lg md:tw-text-2xl">في إنتظار التقييم</h4>
          <div className="rated-schools mb-3">
            <span className="waiting">{season.registration_requests.filter((r) => r.score! > 0).length}</span> <span className="total">/</span>{" "}
            <span className="total">{participating_schools}</span> <span>مدرسة</span>
          </div>
          <Link to="/admin/results">
            <button className="btn-green-o">إدخال تقيم المدارس</button>
          </Link>
        </div>
      )}
    </>
  );
};

export default Closed;
