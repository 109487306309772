import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { School } from "../../../models/school";
import api from "../../../api";
import Overview from "./Overview";
import Participations from "./Participations";
import ParticipationsChart from "./ParticipationsChart";
import Certs from "./Certs";
import "./school-profile.scss";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";

const SchoolProfile: FC = () => {
  let { id } = useParams<{ id: string }>();

  const [school, setSchool] = useState<School | null>(null);

  const getSchool = async (id: string) => {
    let { data } = await api.get("/schools/" + id);
    setSchool(data);
  };

  useEffect(() => {
    if (!school) getSchool(id!);
  }, [school, id]);

  if (!school) return <LoadingSpinner />;

  let participations = school.registration_requests.filter((req) => req.status === "accepted" && req.rank);
  let certs = participations.filter((p) => p.rank <= 3);

  return (
    <div className="school-profile-page">
      <div className="tw-grid tw-col-span-12">
        <div className="tw-col-span-12">
          <Overview school={school} />
        </div>
        <div className="tw-col-span-12 lg:tw-col-span-6">
          <Participations requests={participations} />
        </div>
        <div className="tw-col-span-12 lg:tw-col-span-6 ms-lg-4">
          <ParticipationsChart participations={participations} />
        </div>
        <div className="tw-col-span-12">
          <Certs requests={certs} />
        </div>
      </div>
    </div>
  );
};

export default SchoolProfile;
