import React, { FC } from "react";
import WinnersImg from "../../../assets/images/winners.png";
import Rwinners from "../../../assets/images/Rwinners.png";
import FPICON from "../../../assets/images/FPICON.png";
import SPICON from "../../../assets/images/SPICON.png";
import TPICON from "../../../assets/images/TPICON.png";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { Season } from "../../../models/season";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
const Winners: FC<{ season: Season }> = ({ season }) => {
  const t = useTranslation();

  let { registration_requests } = season;

  return (
    <div className="HomeSection3 container tw-px-5 tw-mt-36 md:tw-mt-0 tw-mb-28">
      <div data-aos="fade-up" className="d-flex flex-row align-self-center justify-content-between mb-3">  
      {season && (
        <h3 data-aos="fade-up" className="tw-mb-14 tw-text-xl lg:tw-text-2xl ">
          {t("HOME.WINNERS_TITLE").replace("##", season.year)} <span className="green-sub">{season.year}</span>
        </h3>
      )}
      <div className="more-btn-anii ">
      <Link to="/results" className="see-more  tw-hidden tw-mb-14 lg:tw-flex tw-items-center">
        {t("HOME.SHOW_ALL_RESULTS")} <BsArrowLeft className="tw-mr-2 more-arr-btnn tw-text-xl color-green" />
      </Link>
      </div>
      
      </div>
 
      <div data-aos="fade-up" className="DHomeSection3Container">
        <img src={WinnersImg} className="winnersimg" alt="winner" />
        <div className="winnersContiner">
          <div className="item SecondPlace">
            <Link to={"/schools/" + registration_requests[1]?.school.id} target="_blank">
              <h5>{registration_requests[1]?.school.name}</h5>
            </Link>
            <p className="SPlace">المركز الثاني</p>
            <p className="certificate">الشهادة الفضية</p>
          </div>

          <div className="item FirsrPlace">
            <Link to={"/schools/" + registration_requests[0]?.school.id} target="_blank">
              <h5>{registration_requests[0]?.school?.name}</h5>
            </Link>
            <p className="SPlace">المركز الأول</p>
            <p className="certificate">الشهادة الذهبية</p>
          </div>

          <div className="item ThirdPlace">
            <Link to={"/schools/" + registration_requests[2]?.school.id} target="_blank">
              <h5>{registration_requests[2]?.school.name}</h5>
            </Link>
            <p className="SPlace">المركز الثالث</p>
            <p className="certificate">الشهادة البرونزية</p>
          </div>
        </div>
      </div>
      <div className="RHomeSection3Container">
        <img src={Rwinners} className="Rwinners" alt="r winner" />
        <table>
          <tbody>
            <tr>
              <td className="FirsrPlace">
                <Link to={"/schools/" + registration_requests[0]?.school.id} target="_blank">
                  <h5>{registration_requests[0]?.school.name}</h5>
                </Link>
                <p>المركز الاول</p>
              </td>
              <td>
                <img src={FPICON} alt="first place" className="icon" />
              </td>
            </tr>
            <tr>
              <td className="SecondPlace">
                <Link to={"/schools/" + registration_requests[1]?.school.id} target="_blank">
                  <h5>{registration_requests[1]?.school.name}</h5>
                </Link>
                <p>المركز الثاني </p>
              </td>
              <td>
                <img src={SPICON} alt="second place" className="icon" />
              </td>
            </tr>
            <tr>
              <td className="ThirdPlace">
                <Link to={"/schools/" + registration_requests[2]?.school.id} target="_blank">
                  <h5>{registration_requests[2]?.school.name}</h5>
                </Link>
                <p>المركز الثالث</p>
              </td>
              <td>
                <img src={TPICON} alt="third place" className="icon" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Link to="/results" className="see-more tw-flex lg:tw-hidden tw-items-center mt-5">
        {t("HOME.SHOW_ALL_RESULTS")} <BsArrowLeft className="tw-mr-2 tw-text-xl color-green" />
      </Link>
    </div>
  );
};

export default Winners;
