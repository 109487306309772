import React, { FC} from 'react'
import config from '../../config'
import { Media } from '../../models/media'
import DeleteBtn from '../../assets/icons/times.svg'
import './Image-file-card.scss'
import moment from 'moment'

const ImageFileCard: FC<Media & {openDeleteFile:Function, idx:number}> = ({ original_url, openDeleteFile, idx, file_name, created_at }) => {
    return (
        <div className="img-file-card me-4 mb-4">
            <div className="img" style={{ background: `url(${original_url.replace("http://localhost", config.serverUrl)}), #C0BDCC` }}>
                <img src={DeleteBtn} alt="delete" onClick={() => openDeleteFile(idx)} />
            </div>
            <div className="d-flex flex-column px-3" style={{height: 86}}>
                <div className="name mt-3">{file_name}</div>
                <div className="created-at">Uploaded {moment(created_at).locale("en").fromNow()}</div>
            </div>
        </div>
    )
}

export default ImageFileCard