import React, { FC, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import { User } from '../../../models/user';
import useTranslation from '../../../i18n/hooks/useTranslation'
import api from '../../../api';
import DBInput from '../../../components/DBInput';
import SelectSearch from 'react-select-search';

interface IProps{
    setShown: (modal:string) => void
    shown: string
    adminAdded: (admin: User) => void
}

const AddAdminModal: FC<IProps> = ({setShown, shown, adminAdded}) => {

    const t = useTranslation()

    const initState = {
        title: "",
        name: "",
        email: "",
        ministry: "",
        password: ""
    }

    const [data, setData] = useState(initState);

    const handleClose = () => setShown("")

    const handleChange = (event: React.ChangeEvent<any>) => {
        event.persist()
        setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }))
    }

    const submitHandler = async (e: React.FormEvent) => {
        e.preventDefault()
        const toastId = toast.loading(t('LOADING'))
        const body = JSON.stringify(data)

        try {
            let res = await api.post('/users/admins', body)
            toast.success(t('SUCCESS.ADMIN_CREATED'), { id: toastId })
            adminAdded(res.data.admin)
        } catch (err: any) {
            toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED") , {
                id: toastId,
            })
        }
    }

    return (
        <Modal show={shown === "add"} onHide={handleClose} className="db-modal" centered={true}>
            <Form onSubmit={submitHandler}>
                <Modal.Body>
                    <div className="title">إضافة مشرف</div>   

                    <div className="mb-3">
                        <label htmlFor="title" className="mb-2">{t("TITLE")}</label>
                        <DBInput type="title" placeholder={t("TITLE")} name="title" id="title"
                            value={data.title} onChange={handleChange} required />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="name" className="mb-2">{t("NAME")}</label>
                        <DBInput type="name" placeholder={t("NAME")} name="name" id="name"
                            value={data.name} onChange={handleChange} required />
                    </div>

                    <div className="mb-3">
                        <label className="mb-2">{t("MINISTRY")}</label>
                        <SelectSearch
                            className={"SelectSec db-select"}
                            onChange={(...args: any) => setData((prevData: any) => ({ ...prevData, ministry: args[0] }))}
                            options={[{ name: "الصحة", value: "HEALTH"}, {name: "التعليم", value: "EDUCATION"}]}
                            placeholder={t("CHOOSE_MINISTRY")}
                            value={data.ministry}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="email" className="mb-2">{t("AUTH.EMAIL")}</label>
                        <DBInput type="email" placeholder={t("AUTH.EMAIL")} name="email" id="email"
                            value={data.email} onChange={handleChange} required />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="password" className="mb-2">{t("AUTH.PASSWORD")}</label>
                        <DBInput type="password" minLength={6} placeholder={t("AUTH.PASSWORD")} name="password" id="password"
                            value={data.password} onChange={handleChange} required />
                    </div>
                    <div className="d-flex">
                        <button className="btn-c btn-green w-80 me-2" type="submit">{t("ADD")}</button>
                        <button className="btn-c btn-cancel w-20" type="button" onClick={handleClose}>{t("CANCEL")}</button>
                    </div>
                </Modal.Body>
            </Form>
        </Modal>
    )
}

export default AddAdminModal