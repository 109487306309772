import React, { FC, useState, useRef, useEffect } from "react";
import toast from "react-hot-toast";
import { News } from "../../../models/news";
import useTranslation from "../../../i18n/hooks/useTranslation";
import api from "../../../api";
import moment from "moment";
import config from "../../../config";
import { Media } from "../../../models/media";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface IProps {
  setShown: (modal: string) => void;
  shown: string;
  newsAdded: (news: News) => void;
  newsUpdated: (news: News) => void;
  articleToUpdate: News | null;
}

const AddNewsModal: FC<IProps> = ({ setShown, shown, newsAdded, articleToUpdate, newsUpdated }) => {
  const inputUploadRef = useRef<HTMLInputElement>(null);
  const t = useTranslation();

  const initState = {
    title: "",
    body: EditorState.createEmpty(),
  };

  const [data, setData] = useState<any>(initState);
  const [file, setFile] = useState<Blob | Media | null>(null);

  const handleChange = (event: React.ChangeEvent<any>) => {
    event.persist();
    setData((prevData: any) => ({ ...prevData, [event.target.name]: event.target.value }));
  };
  const handleEditorChange = (editorState: EditorState) => {
    setData((prevData: any) => ({ ...prevData, body: editorState }));
  };

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) setFile(e.target.files[0]);
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    const toastId = toast.loading(t("LOADING"));
    if (!file) return toast.error("الرجاء إدخال صورة", { id: toastId });
    // Convert Rich text to JavaScript object
    const contentState = data.body.getCurrentContent();
    //
    if (shown === "add") {
      // Add news
      const formData = new FormData();
      formData.append("cover", file! as Blob);

      for (const property in data) {
        if (property === "body") {
          formData.append(property, JSON.stringify(convertToRaw(contentState)));
        } else {
          formData.append(property, data[property]);
        }
      }

      try {
        await api.post("/news", formData);
        toast.success(t("MANAGE_NEWS.NEWS_ADDED"), { id: toastId });
        window.location.reload();
      } catch (err: any) {
        toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED"), {
          id: toastId,
        });
      }
    } else if (shown === "update" && articleToUpdate) {
      // TODO: Update news
      const formData = new FormData();

      // Check if the user uploaded a new cover photo (otherwise just use the old one)
      if (!isMedia(file)) {
        formData.append("cover", file! as Blob);
      }

      for (const property in data) {
        if (property === "body") {
          formData.append(property, JSON.stringify(convertToRaw(contentState)));
        } else {
          formData.append(property, data[property]);
        }
      }

      try {
        let res = await api.post("/news/" + articleToUpdate.id, formData);
        toast.success(t("MANAGE_NEWS.NEWS_UPDATED"), { id: toastId });
        newsUpdated(res.data.news);
        resetModal();
      } catch (err: any) {
        toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED"), {
          id: toastId,
        });
      }
    }
  };

  useEffect(() => {
    if (shown === "update" && articleToUpdate) {
      let savedBody;
      try {
        // This news card already using react-draft-wysiwyg
        savedBody = EditorState.createWithContent(convertFromRaw(JSON.parse(articleToUpdate.body!)));
      } catch (e) {
        // This news card is using the old plain text editor
        savedBody = EditorState.createWithContent(ContentState.createFromText(articleToUpdate.body!));
      }
      setData({
        title: articleToUpdate.title,
        body: savedBody,
      });
      if (articleToUpdate.cover) {
        setFile(articleToUpdate.cover);
      }
    }
  }, [shown, articleToUpdate]);

  const resetModal = () => {
    setData(initState);
    setFile(null);
    setShown("");
  };

  function isMedia(object: any): object is Media {
    return "original_url" in object;
  }

  return (
    <div className={`card mb-5 ${shown === "add" || shown === "update" ? "" : "hidden"}`}>
      <form className="news-container" onSubmit={submitHandler}>
        <div className="right">
          <label htmlFor="news-title">{t("MANAGE_NEWS.NEWS_TITLE")}</label>
          <input
            type="text"
            placeholder={t("MANAGE_NEWS.NEWS_TITLE")}
            id="news-title"
            name="title"
            value={data.title}
            onChange={handleChange}
            required
          />
          <label htmlFor="news-body">{t("MANAGE_NEWS.NEWS_BODY")}</label>
          {/* <textarea
            rows={12}
            placeholder={t("MANAGE_NEWS.NEWS_BODY")}
            id="news-body"
            name="body"
            value={data.body}
            onChange={handleChange}
            required
          /> */}
          <Editor
            editorState={data.body}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleEditorChange}
            placeholder={t("MANAGE_NEWS.NEWS_BODY")}
          />
          <div className="submit-buttons">
            <button className="submit-button" type="submit">
              {t(shown === "update" ? "UPDATE" : "ADD")}
            </button>
            <button onClick={() => resetModal()}>{t("CANCEL")}</button>
          </div>
        </div>
        <hr className="separator"></hr>
        <div className="left" style={{ overflow: "hidden" }}>
          <label>{t("MANAGE_NEWS.NEWS_COVER")}</label>
          <div className="news-item">
            <div
              className={`news-image ${!file ? "upload-image" : ""}`}
              onClick={() => inputUploadRef.current?.click()}
              style={{
                backgroundImage: file
                  ? !isMedia(file)
                    ? `linear-gradient(to top, rgba(0,0,0,0.3) -10%, rgba(0,0,0,0) 30%), url(${URL.createObjectURL(file as Blob)})`
                    : `linear-gradient(to top, rgba(0,0,0,0.3) -10%, rgba(0,0,0,0) 30%), url(${(file as Media)?.original_url.replace(
                        "http://localhost",
                        config.serverUrl
                      )})`
                  : `linear-gradient(to top, rgba(0,0,0,0.3) -10%, rgba(0,0,0,0) 30%)`,
              }}>
              <input ref={inputUploadRef} type="file" accept="image/*" name="upload-file" id="upload-file" onChange={uploadImage} />
            </div>
            <div className="info">
              <p className="title">{data?.title}</p>
              <span className="upload-time">تم رفع الملف في {moment(data.created_at).format("L")}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewsModal;
