import React, { FC } from 'react'
import useTranslation from '../../../i18n/hooks/useTranslation'
import { RegistrationRequest } from '../../../models/registration-request'
import TableRow from './TableRow'

interface IProps {
    sorted: RegistrationRequest[]
    search: string
    activeSeason: number
    updateMode: boolean
    updateValue: (id:number, score: number) => void
}

const ResultsTable: FC<IProps> = ({ sorted, updateValue, search, updateMode }) => {
    
    const t = useTranslation()
    
    let filtered = sorted.filter(r => r.school.name.includes(search))

    return (
        <>
            <table className="db-table publish-results-table">
                <thead>
                    <tr>
                        <th scope="col">{t("RESULTS.RANK")}</th>
                        <th scope="col">{t("THE_SCHOOL")}</th>
                        <th scope="col" className="text-center">{t("RESULTS.SCORE")}</th>
                        <th scope="col" className="text-center">{t("RESULTS.MEDAL")}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {filtered.map((req, idx) =>
                        <TableRow 
                            key={"rank-" + idx + "-req-" + req.id } 
                            idx={req.idx!} 
                            updateValue={updateValue}
                            updateMode={updateMode}
                            sorted={sorted} 
                        />
                    )}
                </tbody>
            </table>
            {/* {season.isCurrent && !season.resultsPublished ? <button className="btn btn-success" onClick={publish}>{t("RESULTS.PUBLISH")}</button>: <></>} */}
        </>
    )
}



export default ResultsTable