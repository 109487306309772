import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../api";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import Footer from "../../../components/Footer";
import { NewsDate } from "../../../components/NewsCard/NewsCard";
import QuoteBlock from "../../../components/QuoteBlock";
import config from "../../../config";
import useTranslation from "../../../i18n/hooks/useTranslation";
import { News } from "../../../models/news";
import Header from "./Header";
import "./latest-news.scss";
import NewsList from "./NewsList";
import draftToHtml from "draftjs-to-html";

const LatestNews: FC = () => {
  const t = useTranslation();

  const [news, setNews] = useState<News[] | null>(null);

  const getNews = async () => {
    let { data } = await api.get("/news");
    setNews(data);
  };

  useEffect(() => {
    if (!news) getNews();
  }, [news]);

  return (
    <div className="latest-news-page">
      <Header title={t("NEWS.TITLE")} />
      <div className="container px-4">
        <div className="d-flex w-100 mb-5">
          <QuoteBlock
            text="المدارس المعززة للصحة هي المدرسة التي تسعى بشكل دائم الى تحسين قدراتها لتأمين بيئة صحية داعمة للعيش و التعلم والعمل"
            className="mx-auto"
          />
        </div>
        {!news ? (
          <LoadingSpinner />
        ) : news.length === 0 ? (
          <></>
        ) : (
          <>
            <h2 className="mt-4 mb-4">{t("NEWS.LATEST_UPDATES")}</h2>
            <div className="latest-news news-item tw-mb-28 md:tw-mb-12 d-flex">
              <div className="bg" style={{ backgroundImage: `url(${news[0].cover?.original_url.replace("http://localhost", config.serverUrl)})` }} />
              <div className="body p-3 my-auto ms-auto">
                <Link to={"/news/" + news[0].id} className="title">
                  {news[0].title}
                </Link>
                <div className="content mt-1">{draftToHtml(JSON.parse(news[0].body!)).replace(/<[^>]*>?/gm, '')}</div>
                <NewsDate date={news[0].created_at} link={news[0].id} />
              </div>
            </div>
            {news.length > 1 && (
              <>
                <h2 className="py-4" id="scrollTo">{t("NEWS.ALL_NEWS")}</h2>
                <NewsList news={news.slice(1)} />
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default LatestNews;
