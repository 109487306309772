import React from "react"

const Icon: React.FC<{type: string}> = ({type}) => {

    let background = type === "reg_closed" ? "#EB9C3F33" : 
      type === "results_published" ? "#FED96533" :
      type === "24hours_before_closing" ? "#EB9C3F33"  : "#90953733"

    return (
      <div className="icon d-flex" style={{ background }}>
        { type === "24hours_before_closing" ? 
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="clock" transform="translate(-3340 -2148)">
              <rect id="Frame" width="16" height="16" transform="translate(3340 2148)" fill="#eb9c3f" opacity="0"/>
              <path id="clock-2" data-name="clock" d="M3348.417,2149.25a7.167,7.167,0,1,0,7.167,7.167A7.175,7.175,0,0,0,3348.417,2149.25Zm0,13.333a6.167,6.167,0,1,1,6.167-6.167A6.173,6.173,0,0,1,3348.417,2162.583Zm2.353-4.52a.5.5,0,1,1-.707.707l-2-2a.5.5,0,0,1-.147-.353v-3.333a.5.5,0,0,1,1,0v3.126Z" transform="translate(-0.417 -0.417)" fill="#eb9c3f"/>
            </g>
          </svg>
          :
          type === "reg_closed" ? 
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="door-closed" transform="translate(-624 -404)">
              <rect id="Frame" width="16" height="16" transform="translate(624 404)" fill="#eb9c3f" opacity="0"/>
              <path id="door-closed-2" data-name="door-closed" d="M638.75,418.25h-1.167v-10.5a1.5,1.5,0,0,0-1.5-1.5h-6.667a1.5,1.5,0,0,0-1.5,1.5v10.5H626.75a.5.5,0,0,0,0,1h12a.5.5,0,0,0,0-1Zm-9.833,0v-10.5a.5.5,0,0,1,.5-.5h6.667a.5.5,0,0,1,.5.5v10.5Zm6.513-5.5a.668.668,0,1,1-.673-.667h.007A.667.667,0,0,1,635.43,412.75Z" transform="translate(-0.75 -0.75)" fill="#eb9c3f"/>
            </g>
          </svg>
          :
          type === "results_published" ?
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="certificate" transform="translate(-3172 -1492)">
              <rect id="Frame" width="16" height="16" transform="translate(3172 1492)" fill="#fed965" opacity="0"/>
              <path id="certificate-2" data-name="certificate" d="M3179.917,1504.083a.5.5,0,0,1-.5.5h-3.667a2.255,2.255,0,0,1-2.5-2.5v-5.333a2.255,2.255,0,0,1,2.5-2.5h8a2.255,2.255,0,0,1,2.5,2.5.5.5,0,0,1-1,0c0-1.051-.449-1.5-1.5-1.5h-8c-1.051,0-1.5.449-1.5,1.5v5.333c0,1.051.449,1.5,1.5,1.5h3.667A.5.5,0,0,1,3179.917,1504.083Zm.5-6.5h-4a.5.5,0,0,0,0,1h4a.5.5,0,0,0,0-1Zm-2,3.667a.5.5,0,0,0,0-1h-2a.5.5,0,0,0,0,1Zm8.738,1.423-.141.142.005.186a1.458,1.458,0,0,1-.783,1.287l.663,2.325a.5.5,0,0,1-.481.637.506.506,0,0,1-.186-.036l-1.231-.493a2.168,2.168,0,0,0-1.611,0l-1.23.493a.5.5,0,0,1-.667-.6l.663-2.325a1.46,1.46,0,0,1-.783-1.287v-.2l-.136-.129a1.464,1.464,0,0,1,0-2.068l.142-.142-.005-.185a1.464,1.464,0,0,1,1.462-1.463h.2l.127-.136a1.5,1.5,0,0,1,2.069,0l.141.141.187,0a1.464,1.464,0,0,1,1.462,1.463v.2l.135.129A1.463,1.463,0,0,1,3187.155,1502.673Zm-1.5,3.234-.385-1.352-.043.045a1.461,1.461,0,0,1-2.068,0l-.043-.043-.385,1.35.284-.115a3.168,3.168,0,0,1,2.354,0Zm.792-4.595-.142-.141a.979.979,0,0,1-.288-.695v-.2a.463.463,0,0,0-.462-.463h-.2a.975.975,0,0,1-.7-.289l-.14-.14a.472.472,0,0,0-.655,0l-.141.141a.979.979,0,0,1-.695.288h-.2a.463.463,0,0,0-.462.463v.2a.976.976,0,0,1-.288.694l-.143.142a.464.464,0,0,0,0,.654l.142.142a.972.972,0,0,1,.288.693v.2a.463.463,0,0,0,.462.463h.2a.977.977,0,0,1,.693.287l.143.143a.473.473,0,0,0,.653,0l.143-.142a.972.972,0,0,1,.693-.287h.2a.463.463,0,0,0,.462-.463v-.2a.974.974,0,0,1,.287-.693l.143-.143A.464.464,0,0,0,3186.448,1501.312Zm-.643.327a1.611,1.611,0,1,1-1.611-1.611A1.613,1.613,0,0,1,3185.805,1501.639Zm-1,0a.611.611,0,1,0-.611.611A.611.611,0,0,0,3184.805,1501.639Z" transform="translate(-0.417 -0.75)" fill="#ffd03c"/>
            </g>
          </svg>
          :
          <svg id="school" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <rect id="Frame" width="16" height="16" fill="#909537" opacity="0"/>
            <path id="school-2" data-name="school" d="M1500.417,2096.25a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,1500.417,2096.25Zm0,4a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,1500.417,2100.25Zm6.667,6.333h-.167v-5.167a1.5,1.5,0,0,0-1.5-1.5h-.5v-2.785l.557.369a.5.5,0,1,0,.552-.833l-4.777-3.165a1.5,1.5,0,0,0-1.663,0l-4.779,3.166a.5.5,0,0,0,.552.833l.557-.369v2.785h-.5a1.5,1.5,0,0,0-1.5,1.5v5.167h-.167a.5.5,0,0,0,0,1h13.333a.5.5,0,0,0,0-1Zm-7.5,0v-2.167a.833.833,0,0,1,1.667,0v2.167Zm2.667,0v-2.167a1.833,1.833,0,0,0-3.667,0v2.167h-3.667v-5.167a.5.5,0,0,1,.5-.5h1a.5.5,0,0,0,.5-.5v-3.948l3.223-2.135a.5.5,0,0,1,.556,0l3.221,2.134v3.948a.5.5,0,0,0,.5.5h1a.5.5,0,0,1,.5.5v5.167Zm-5-3.833a.5.5,0,1,1-.5-.5A.5.5,0,0,1,1497.25,2102.75Zm0,1.667a.5.5,0,1,1-.5-.5A.5.5,0,0,1,1497.25,2104.416Zm7.333-1.667a.5.5,0,1,1-.5-.5A.5.5,0,0,1,1504.583,2102.75Zm0,1.667a.5.5,0,1,1-.5-.5A.5.5,0,0,1,1504.583,2104.416Z" transform="translate(-1492.417 -2092.417)" fill="#909537"/>
          </svg>
          }
      </div>
    )
    
}

export default Icon
