import { FC, useState } from "react";
import { News } from "../../../models/news";
import config from "../../../config";
import Pagination from "../../../components/Pagination";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";

interface IProps {
  news: News[];
  handleEditButton: (news: News) => void;
  handleDeleteButton: (id: string | number) => void;
}

const NewsTable: FC<IProps> = ({ news, handleEditButton, handleDeleteButton }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const currentData = news.slice((currentPage - 1) * limit, (currentPage - 1) * limit + limit);

  return (
    <div className="card">
      <div className="news-grid">
        {currentData.map((article) => (
          <div key={"article-" + article.id} className="news-item">
            <div
              className="news-image"
              style={{
                backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.3) -10%, rgba(0,0,0,0) 30%), url(${article.cover?.original_url.replace(
                  "http://localhost",
                  config.serverUrl
                )})`,
              }}>
              <div className="news-image-options">
                <div onClick={() => handleEditButton(article)}>
                  <FaRegEdit />
                </div>
                <div onClick={() => article.id && handleDeleteButton(article.id)}>
                  <RiDeleteBin5Line />
                </div>
              </div>
            </div>
            <div className="info">
              <p className="title">{article?.title}</p>
              <span className="upload-time">تم رفع الملف في {moment(article.created_at).format("L")}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="bottom ms-5">
        {news.length > 0 && (
          <Pagination entityName="أخبار" currentPage={currentPage} setCurrentPage={setCurrentPage} limit={limit} setLimit={setLimit} totalRows={news.length} />
        )}
      </div>
    </div>
  );
};

export default NewsTable;
