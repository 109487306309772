import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {  RouteProps, Navigate, useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import { RootState } from '../store';

interface Props extends RouteProps {
    component: FC;
}

const PrivateRoute: FC<Props> = ({ component: Component }) => {
    const { authenticated, user } = useSelector((state: RootState) => state.auth);
    const location = useLocation()
    const prefix = location.pathname.split("/")[2]

    if(!authenticated)
        return <Navigate to="/login" />

    if(user!.ministry === "EDUCATION" && !['schools','requests_history','new_requests'].includes(prefix))
        return <Navigate to="/admin/schools" />

    return (
        <DashboardLayout>
            <Component />
        </DashboardLayout>
    );
}

export default PrivateRoute;