import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import SchoolImage from '../../../components/SchoolImage'
import { RegistrationRequest } from '../../../models/registration-request'
import Fplace from "../../../assets/icons/Fplace.png"
import Tplace from "../../../assets/icons/Tplace.png"
import Splace from "../../../assets/icons/Splace.png"

const LastWinners: FC<{winners: RegistrationRequest[], year: string}> = ({ winners, year }) => {
    return (
        <div className="col-12 mt-5">
          <div className={`font-weight-bolder tw-text-xl md:tw-text-2xl mb-3`}>المدارس الحائزة على شهادات نشطة</div>
          <div className="w-100">
            <div style={{overflowX: "auto"}}>
                <table className="db-table">
                    <thead>
                        <tr>
                            <th scope="col">المدرسة</th>
                            <th scope="col">الشهادة</th>
                            <th scope="col">الموسم</th>
                        </tr>
                    </thead>
                    <tbody>
                        {winners.map(({school, rank, season}) => 
                            <tr>
                                <td>
                                    <div className="d-flex">
                                        <SchoolImage gender={school.gender} className="tw-w-16 lg:tw-w-20 tw-h-16 lg:tw-h-20" />
                                        <div className="details ms-3">
                                            <Link to={"/admin/schools/" + school.id}>
                                                <h4 className="tw-mb-2 tw-text-base lg:tw-text-lg">{school.name}</h4>
                                            </Link>
                                            <div className="location tw-mb-2 tw-text-sm lg:tw-text-md" style={{ color: "#8B8B8BCC" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.332" height="16.306" viewBox="0 0 14.332 16.306" className="me-2">
                                                <g id="layer1" transform="translate(-0.835 -1036.21)" opacity="0.43">
                                                    <path
                                                    id="path4926"
                                                    d="M7.994,0a4.508,4.508,0,0,0-4.5,4.5.5.5,0,0,0,.023.158A8.434,8.434,0,0,0,5.049,8.9c.831,1.322,1.794,2.563,2.412,3.828a.5.5,0,0,0,.895.01,45.176,45.176,0,0,1,2.535-3.828,8.026,8.026,0,0,0,1.619-4.4.5.5,0,0,0-.033-.186A4.5,4.5,0,0,0,7.994,0Zm0,1a3.492,3.492,0,0,1,3.5,3.5.5.5,0,0,0,.01.107,7.229,7.229,0,0,1-1.447,3.75C9.369,9.4,8.591,10.471,7.92,11.588,7.283,10.476,6.549,9.4,5.9,8.365A7.345,7.345,0,0,1,4.51,4.506.5.5,0,0,0,4.5,4.418,3.488,3.488,0,0,1,7.994,1Zm0,2a1.5,1.5,0,1,0,1.5,1.5A1.508,1.508,0,0,0,7.994,3Zm0,1a.5.5,0,1,1-.5.5A.493.493,0,0,1,7.994,4Zm4.09,5.227a.5.5,0,0,0-.18.967,3.812,3.812,0,0,1,1.914,1.42,1.189,1.189,0,0,1-.092,1.426C13,14.075,10.691,14.993,8.053,15S3.071,14.121,2.3,13.082a1.2,1.2,0,0,1-.145-1.428,3.657,3.657,0,0,1,1.855-1.43A.5.5,0,1,0,3.629,9.3a4.52,4.52,0,0,0-2.352,1.883,2.313,2.313,0,0,0,.223,2.49C2.632,15.21,5.211,16.016,8.059,16s5.4-.837,6.488-2.391a2.306,2.306,0,0,0,.141-2.494,4.671,4.671,0,0,0-2.408-1.853A.5.5,0,0,0,12.084,9.227Z"
                                                    transform="translate(0 1036.362)"
                                                    stroke="#000"
                                                    strokeWidth="0.3"
                                                    />
                                                </g>
                                                </svg>
                                                محافظة {school.governorate} - ولاية {school.city}
                                            </div>
                                            <div className="tw-text-sm lg:tw-text-md" style={{ color: "#3E3E3E" }}>
                                                {school.gender}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="tw-flex tw-items-center tw-gap-3" style={{ minWidth: 210 }}>
                                        <img src={rank === 1 ? Fplace : rank === 2 ? Splace : Tplace} alt="medal" className="me-2" style={{ width: 50 }} />
                                        <div>
                                            <p className="DegTitle tw-mb-2 tw-text-md">
                                                {rank === 1 ? "الشهادة الذهبية" : rank === 2 ? "الشهادة الفضية" : rank === 3 ? "الشهادة البرونزية" : ""}
                                            </p>
                                            <p className="tw-text-md">صالحة لمدة 3 سنوات</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="Degyear tw-text-md">سنة {year}</p>
                                    <p className="staut tw-text-md" style={{ color: "black" }}>
                                    نشطة
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
          </div>
        </div>
    )
}

export default LastWinners