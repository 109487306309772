import React, { FC, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import Router from "./Router";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import { loadUser } from "./store/actions/authActions";

const preloader = document.getElementById("preloader-div");

const App: FC = () => {
  const { checkingToken } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  if (checkingToken) {
    preloader?.classList.remove("hide-preloader");
    preloader?.classList.add("show-preloader");
    document.body.style.overflow = "hidden";
    return <></>
  } else {
    // 1s delay to make the animation better if the connection is fast / website is cashed
    setTimeout(() => {
      preloader?.classList.remove("show-preloader");
      preloader?.classList.add("hide-preloader");
      document.body.style.overflow = "initial";
    }, 1000);
  }

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Router />
    </>
  );
};

export default App;
