import React, { FC, useEffect, useState } from "react";
import Header from "./Header";
import "./about.scss";
import QuoteBlock from "../../../components/QuoteBlock";
import Partners from "./Partners";
import Footer from "../../../components/Footer";
import Results from "./Results";
import { Content } from "../../../models/content";
import api from "../../../api";
import AboutCards from "./AboutCards";
import HealthPromo from "./HealthPromo";
import Logo from "./logo.svg";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";
import AOS from 'aos';
import 'aos/dist/aos.css'

const About: FC = () => {
  AOS.init();
  const [content, setContent] = useState<Content | null>(null);

  const getContent = async () => {
    let { data } = await api.get("/content");
    setContent(data);
  };

  useEffect(() => {
    if (!content) getContent();
  }, [content]);

  return (
    <div className="about-page">
      <Header />
      {!content ? (
        <div className="py-5 my-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="container px-4">
            <div className="d-flex w-100 mb-5">
              <QuoteBlock
                text="المدارس المعززة للصحة هي المدرسة التي تسعى بشكل دائم الى تحسين قدراتها لتأمين بيئة صحية داعمة للعيش و التعلم والعمل"
                className="mx-auto "
              />
            </div>
            <h2 data-aos="fade-up" className="mt-5 mb-3">نبذه عن المبادره</h2>
            <div data-aos="fade-up" className="about-logo d-flex justify-content-center align-items-center">
              <img src={Logo} alt="" className="" />
            </div>
            <p data-aos="fade-up" className="mb-5 lh-lg">{content.about_ar}</p>
            <AboutCards />
            <Partners />
            <HealthPromo />
            <div style={{ clear: "both" }}></div>
            <Results />
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export default About;
