import React, { FC } from 'react'
import readXlsxFile from 'read-excel-file'
import api from '../../../api'

const parseName = (fullName: string) => {
    let firstIndex = fullName.lastIndexOf("(")
    let lastIndex = fullName.lastIndexOf(")")

    let idx = fullName.indexOf("للتعليم")
    let idx2 = fullName.indexOf("للبنين")

    let name = "مدرسة " + fullName.substring(0, idx > 0 ? idx : firstIndex - 1)
    if(idx2 > 0)
        name = name.substring(0, idx2)

    let level = fullName.substring(firstIndex + 1, lastIndex ?? fullName.length) || "1-3"
    let [p1, p2] = level.split("-")
    let from = parseInt(p1.trim())
    let to = parseInt(p2.trim())
    let levels = []
    if(from <= 4)
        levels.push("حلقة أولى")
    
    if(to > 4)
        levels.push("حلقة ثانية")
    
    if(to > 10)
        levels.push("ما بعد الأساسي")
    
    return [name, levels.join("،")]
}

const AddSchools: FC = () => {

    const addSchool = async (body: any) => {
        const formData = new FormData()

        for (const property in body) 
            formData.append(property, body[property])

        try {
            await api.post('/schools', formData)
        } catch (err: any) {
            // console.log(err)
        }
    }

    const handler = async (e: any) => {
        let rows: any = await readXlsxFile(e.currentTarget.files[0])
        for (const row of rows) {
            let [ id, fullName, governorate, city, gender] = row
            console.log("Adding school " + id)
            let [name, education_level] = parseName(fullName)
            await addSchool({ 
                id,
                name, education_level, 
                governorate: governorate.replace("محافظة", "").trim(), 
                city, gender 
            })
        }
    }
      
    return (
        <div>
            <h1>إضافة المدارس</h1>

            <input type="file" id="input" onChange={handler} />
        </div>
    )
}

export default AddSchools