import React, { FC } from "react";
import Footer from "../../../components/Footer";
import QuoteBlock from "../../../components/QuoteBlock";
import useTranslation from "../../../i18n/hooks/useTranslation";
import Header from "./Header";
import AlJisrLogo from './al_jisr.png'
import CollabLogo from './collab_logo.png'

const Supporters: FC = () => {
  const t = useTranslation();

  return (
    <div className="latest-news-page">
      <Header title={t("SUPPORTERS.TITLE")} />
        <div className="px-4 bg-light">
          <div className="d-flex w-100">
            <QuoteBlock
              text="المدارس المعززة للصحة هي المدرسة التي تسعى بشكل دائم الى تحسين قدراتها لتأمين بيئة صحية داعمة للعيش و التعلم والعمل"
              className="mx-auto"
            />
          </div>
        </div>
        <div className="bg-light">
          <div className="container px-4 pt-3 tw-px-5 tw-pb-8">
            <h3 className="tw-mb-10 pt-5 md:tw-mb-8 tw-text-xl lg:tw-text-2xl">{t("SUPPORTERS.INTRO")}</h3>
            <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row sm:tw-flex-col-reverse xsm:tw-flex-col-reverse md:tw-flex-col-reverse md:tw-gap-16 lg:tw-gap-32 tw-mb-28">
              <div className="tw-basis-10/12">
                <p>{t("SUPPORTERS.INTRO_DESCRIPTION")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-4 tw-px-5 ">
          <h3 className="tw-mb-7 pt-5 md:tw-mt-8 tw-text-xl lg:tw-text-2xl">{t("SUPPORTERS.COLLABORATION")}</h3>
          <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row sm:tw-flex-col-reverse xsm:tw-flex-col-reverse md:tw-flex-col-reverse md:tw-gap-16 lg:tw-gap-32 tw-mb-12">
            <div className="tw-basis-10/12">
              <p className="mb-5">{t("SUPPORTERS.COLLABORATION_DESCRIPTION")}</p>
              <img 
                src={CollabLogo}  
                style={{ maxHeight: 130 }}
                alt="WHO - Oman MOH logos" 
              />
            </div>
          </div>
          <h3 className="tw-mb-10 pt-5 md:tw-mb-8 tw-text-xl lg:tw-text-2xl">{t("SUPPORTERS.AL_JISR")}</h3>
          <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row sm:tw-flex-col-reverse xsm:tw-flex-col-reverse md:tw-flex-col-reverse md:tw-gap-16 lg:tw-gap-32 tw-mb-28">
            <div className="tw-basis-10/12">
              <p className="mb-5">{t("SUPPORTERS.AL_JISR_DESCRIPTION")}</p>
              <img 
                src={AlJisrLogo}  
                className="mb-5"
                style={{ maxHeight: 130 }}
                alt="Al jisr organization logo" 
              />
            </div>
          </div>
        </div>
      <Footer />
    </div>
  );
};

export default Supporters;
