import moment from "moment";
import React, { FC } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import api from "../../../../api";
import useTranslation from "../../../../i18n/hooks/useTranslation";
import { Season } from "../../../../models/season";
import Clock from "./Clock";

const Started: FC<{ season: Season; seasonUpdated: Function; openUpdateModal: Function }> = ({ season, seasonUpdated, openUpdateModal }) => {
  const t = useTranslation();

  const navigate = useNavigate();

  const endSeason = async (args: any) => {
    const toastId = toast.loading(t("LOADING"));
    const body = JSON.stringify({ endDate: moment().locale("en").format("YYYY-MM-DD HH:mm:00") });
    try {
      await api.put("/seasons/" + season.id + "?close_reg=true", body);
      toast.success(t("SUCCESS.DONE"), { id: toastId });
      seasonUpdated();
      navigate("?push");
    } catch (err: any) {
      toast.error(t(err?.response?.data?.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
    }
  };

  return (
    <>
      <div className="lg:tw-col-span-8">
        <h4 className="mb-4">
          الموسم الحالي <span className="green">{season.year}</span>
        </h4>
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-6">
          <div className="lg:tw-col-span-4 d-flex details-card tw-pt-6">
            <div className="icon yellow d-flex me-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="31.235" height="31.013" viewBox="0 0 31.235 31.013">
                <path
                  id="Path_62393"
                  data-name="Path 62393"
                  d="M26.857,31.89H3a3,3,0,0,1-3-3V5.032a3,3,0,0,1,3-3H19.214a1,1,0,0,1,0,2H3a1,1,0,0,0-1,1V28.89a1,1,0,0,0,1,1H26.857a1,1,0,0,0,1-1V12.675a1,1,0,0,1,2,0V28.89A3,3,0,0,1,26.857,31.89ZM24.482,23.5h0L5.192,23.407a1,1,0,0,1,0-2h0l19.286.089a1,1,0,0,1,0,2Zm-9.231-5.081a1.361,1.361,0,0,1-.957-.366c-.3-.28-.709-.931-.14-2.151l.63-1.35a14.59,14.59,0,0,1,2.459-3.51L26,2.281l.007-.006.006-.006.451-.451A2.87,2.87,0,0,1,30.5,1.611h0a2.868,2.868,0,0,1-.207,4.036s-.536.552-.586.586l-8.635,8.635a17.4,17.4,0,0,1-3.405,2.538l-1.218.657A2.544,2.544,0,0,1,15.251,18.415ZM26.714,4.4l-8.056,8.057A12.609,12.609,0,0,0,16.6,15.4l-.2.42.316-.17a15.505,15.505,0,0,0,2.942-2.192L27.715,5.4Zm1.449-1.38.932.932a.794.794,0,0,0-.009-.923A.791.791,0,0,0,28.163,3.016Z"
                  transform="translate(0 -0.877)"
                  fill="#d1b14e"
                />
              </svg>
            </div>
            <div className="details">
              <div className="title mb-1">طلبات التسجيل</div>
              <div className="count">{season.registration_requests.filter((r) => r.status === "pending").length}</div>
            </div>
          </div>
          <div className="lg:tw-col-span-4 d-flex details-card lg:tw-pt-6">
            <div className="icon orange d-flex me-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                <g id="award-check" transform="translate(-3508 -1492)">
                  <rect id="Frame" width="45" height="45" transform="translate(3508 1492)" fill="#eb9c3f" opacity="0" />
                  <path
                    id="award-check-2"
                    data-name="award-check"
                    d="M3538.835,1503.976l-.6-.6a.631.631,0,0,1-.184-.443v-.84a5.47,5.47,0,0,0-5.465-5.466h-.84a.63.63,0,0,1-.442-.184l-.6-.6a5.473,5.473,0,0,0-7.732,0l-.6.6a.63.63,0,0,1-.442.184h-.84a5.47,5.47,0,0,0-5.465,5.466v.84a.623.623,0,0,1-.184.441l-.6.6a5.475,5.475,0,0,0,0,7.731l.6.6a.631.631,0,0,1,.184.443v.84a5.467,5.467,0,0,0,3.437,5.066l-2.955,10.365a1.408,1.408,0,0,0,1.877,1.691l5.19-2.08a9.839,9.839,0,0,1,7.32,0l5.193,2.081a1.423,1.423,0,0,0,.523.1,1.408,1.408,0,0,0,1.354-1.792l-2.955-10.365a5.467,5.467,0,0,0,3.437-5.066v-.84a.623.623,0,0,1,.184-.441l.6-.6v0A5.475,5.475,0,0,0,3538.835,1503.976Zm-7.29,22.041a12.662,12.662,0,0,0-9.409,0l-2.529,1.014,2.274-7.98h.057a.63.63,0,0,1,.442.184l.6.6a5.473,5.473,0,0,0,7.732,0l.6-.6a.63.63,0,0,1,.442-.184h.057l2.274,7.98Zm5.3-16.3-.6.6a3.406,3.406,0,0,0-1.009,2.43v.84a2.654,2.654,0,0,1-2.653,2.653h-.84a3.458,3.458,0,0,0-2.432,1.009l-.6.6a2.721,2.721,0,0,1-3.754,0l-.6-.6a3.458,3.458,0,0,0-2.432-1.009h-.84a2.654,2.654,0,0,1-2.653-2.653v-.84a3.413,3.413,0,0,0-1.009-2.432l-.6-.6a2.658,2.658,0,0,1,0-3.752l.6-.6a3.406,3.406,0,0,0,1.009-2.43v-.84a2.654,2.654,0,0,1,2.653-2.653h.84a3.458,3.458,0,0,0,2.432-1.009l.6-.6a2.721,2.721,0,0,1,3.754,0l.6.6a3.458,3.458,0,0,0,2.432,1.009h.84a2.654,2.654,0,0,1,2.653,2.653v.84a3.413,3.413,0,0,0,1.009,2.432l.6.6A2.661,2.661,0,0,1,3536.847,1509.72Zm-5.261-5.372a1.407,1.407,0,0,1,0,1.99l-5,5a1.408,1.408,0,0,1-1.988,0l-2.5-2.5a1.405,1.405,0,1,1,1.987-1.988l1.506,1.506,4.007-4.007A1.4,1.4,0,0,1,3531.586,1504.348Z"
                    transform="translate(4.595 1.97)"
                    fill="#eb9c3f"
                  />
                </g>
              </svg>
            </div>
            <div className="details">
              <div className="title mb-1">المدارس المشاركة</div>
              <div className="count">{season.registration_requests.filter((r) => r.status === "accepted").length}</div>
            </div>
          </div>
          <div className="lg:tw-col-span-4 details-card">
            <div className="icon d-flex me-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="30.278" height="30.277" viewBox="0 0 30.278 30.277">
                <path
                  id="Path_62388"
                  data-name="Path 62388"
                  d="M28.493,5.028H23.951V2.757a.757.757,0,1,0-1.514,0V5.028H11.84V2.757a.757.757,0,1,0-1.514,0V5.028H5.784A3.789,3.789,0,0,0,2,8.812v19.68a3.789,3.789,0,0,0,3.785,3.785H28.493a3.788,3.788,0,0,0,3.785-3.785V8.812A3.788,3.788,0,0,0,28.493,5.028Zm2.271,23.465a2.273,2.273,0,0,1-2.271,2.271H5.784a2.273,2.273,0,0,1-2.271-2.271V15.625h27.25Zm0-14.382H3.513v-5.3A2.273,2.273,0,0,1,5.784,6.542h4.542V8.812h0a.756.756,0,0,0,.757.756h0a.756.756,0,0,0,.756-.757V6.542h10.6V8.812h0a.756.756,0,0,0,.757.756h0a.756.756,0,0,0,.756-.757V6.542h4.542a2.273,2.273,0,0,1,2.271,2.271Z"
                  transform="translate(-2 -2)"
                  fill="#909537"
                />
              </svg>
            </div>
            <div className="details">
              <div className="title mb-1">حالة الموسم</div>
              <div className="state mb-1">التسجيل مفتوح</div>
              <div className="date">{moment(season.startDate).locale("en").format("DD/MM/YYYY HH:mm")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:tw-col-span-4 tw-border-t tw-border-t-[#707070]/20 lg:tw-border-t-0 lg:tw-border-r lg:tw-border-r-[#707070]/20 lg:tw-px-4">
        <h4 className="green fz-16 mb-2" style={{ maxWidth: 180 }}>
          الوقت المتبقي حتى اغلاق التسجيل
        </h4>
        <div className="date mb-2">حتى {moment(season.endDate).locale("en").format("DD/MM/YYYY HH:mm")}</div>
        <Clock deadline={season.endDate} />
        <button className="btn-green-o me-2" onClick={endSeason}>
          إغلاق التسجيل
        </button>
        <button className="btn-green-o black" onClick={() => openUpdateModal()}>
          تمديد التسجيل
        </button>
      </div>
    </>
  );
};

export default Started;
