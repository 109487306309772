import React from "react";
import { motion } from "framer-motion";

// Word wrapper
const Wrapper = (props) => {
  // We'll do this to prevent wrapping of words using CSS
  return <span className="word-wrapper">{props.children}</span>;
};


const AnimatedTextUp = (props) => {
  // Framer Motion variant object, for controlling animation
  const item = {
    hidden: {
      y: "200%",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 }
    },
    visible: {
      y: 0,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 }
    }
  };

  //  Split each word of props.text into an array
  let splitWords = props.children.split(" ");

  splitWords = splitWords.map((word) => {
    return word = word + "\u00A0";
  });

  return (
    <>
      {splitWords.map((word, index) => {
        return (
          // Wrap each word in the Wrapper component
          <Wrapper key={index}>
            <span
              style={{
                overflow: "hidden",
                display: "inline-block",
              }}
              key={index}>
              <motion.span
                style={{ display: "inline-block" }}
                variants={item}>
                {word}
              </motion.span>
            </span>
          </Wrapper>
        );
      })}
    </>
  );
};

export default AnimatedTextUp;
