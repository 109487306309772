import React, { FC, useEffect, useState } from "react";
import api from "../../../api";
import { RegistrationRequest } from "../../../models/registration-request";
import ExportCSVButton from "../../../components/ExportCSVButton";
import useTranslation from "../../../i18n/hooks/useTranslation";
import ResultsTable from "./ResultsTable";
import { Season } from "../../../models/season";
import Sidebar from "./Sidebar";
import "./results.scss";
import SelectSearch from "react-select-search";
import toast from "react-hot-toast";
import Sticky from "../../../utils/Sticky";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import DialogBox from "./DialogBox";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/DashboardWidgets/LoadingSpinner/LoadingSpinner";

const PublishResults: FC = () => {
  const t = useTranslation();

  const navigate = useNavigate();

  const [requests, setRequests] = useState<RegistrationRequest[] | null>(null);
  const [seasons, setSeasons] = useState<Season[] | null>(null);
  const [search, setSearch] = useState("");
  const [shownModal, setShownModal] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  const [activeSeason, setActiveSeason] = useState(0);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  const changeSeaon = (k: string) => {
    let idx = parseInt(k);
    setActiveSeason(idx);
    setRequests(seasons![idx].registration_requests.filter((r) => r.status === "accepted"));
  };

  const updateValue = (id: number, score: number) => {
    let clone: RegistrationRequest[] = JSON.parse(JSON.stringify(requests));
    let idx = clone.findIndex((r) => r.id === id);
    clone[idx].score = score;
    setRequests(clone);
    setShowDialog(true);
  };

  const getSeasons = async () => {
    let { data } = await api.get("/seasons");
    setSeasons(data);
    setRequests(data[activeSeason].registration_requests.filter((r: RegistrationRequest) => r.status === "accepted"));
  };

  useEffect(() => {
    if (!seasons) getSeasons();
    // eslint-disable-next-line
  }, [seasons]);

  if (!seasons) return <LoadingSpinner />;

  let sorted = requests!.sort((a, b) => b.score! - a.score!);

  let validValues = sorted.every((r) => r.score! > 0);

  const saveResults = async (publish?: boolean) => {
    const toastId = toast.loading(t("LOADING"));
    if (sorted[0]?.score === sorted[1]?.score) return toast.error(t("RESULTS.ONE_TWO_HAS_SAME_SCORE"), { id: toastId });

    if (sorted[0]?.score === sorted[2]?.score) return toast.error(t("RESULTS.ONE_THREE_HAS_SAME_SCORE"), { id: toastId });

    if (sorted[1]?.score === sorted[2]?.score) return toast.error(t("RESULTS.TWO_THREE_HAS_SAME_SCORE"), { id: toastId });

    const body = JSON.stringify({ results: sorted, season_id: seasons[activeSeason].id });

    try {
      await api.post(`/results${publish ? "?publish=true" : ""}`, body);
      toast.success(t(`SUCCESS.RESULTS_${publish ? "PUBLISHED" : "SAVED"}`), { id: toastId });
      getSeasons();
      setUpdateMode(false);
      setShownModal("");
      if (publish) navigate("?push");
    } catch (err: any) {
      toast.error(t(err?.response?.data.message || "ERROR.ERROR_OCCURED"), {
        id: toastId,
      });
    }
  };

  return (
    <div className="publish-results-page">
      <DialogBox showDialog={showPrompt} confirmNavigation={confirmNavigation} cancelNavigation={cancelNavigation} />
      {shownModal === "confirm_publish" && <ConfirmationModal setShown={setShownModal} title="تاكيد نشر النتائج" action={() => saveResults(true)} />}
      <Sticky
        position="top"
        stuckClasses="stuck"
        unstuckClasses=""
        stuckStyles={{ background: "white" }}
        unstuckStyles={{ background: "transparent" }}>
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-gap-6 tw-mb-6">
          <div className="tw-flex tw-w-full tw-justify-between md:tw-justify-start tw-items-center tw-gap-3">
            <h1 className="tw-text-lg md:tw-text-2xl">{t("RESULTS.TITLE")}</h1>
            <SelectSearch
              className={"SelectSec my-auto"}
              onChange={(...args: any) => changeSeaon(args[0])}
              options={seasons.map((season, idx) => ({ name: season.year, value: idx }))}
              value={activeSeason.toString()}
            />
          </div>
          <div className="tw-flex">
            <div className="table-search ms-auto my-auto d-flex px-2">
              <input type="text" className="my-auto me-auto" placeholder="بحث" value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
              <svg xmlns="http://www.w3.org/2000/svg" width="19.457" height="19.457" viewBox="0 0 19.457 19.457" className="my-auto">
                <g id="Layer_2" data-name="Layer 2" opacity="0.1">
                  <path
                    id="search"
                    d="M22.138,20.6,18.463,16.94a8.558,8.558,0,0,0,1.826-5.295,8.645,8.645,0,1,0-8.645,8.645,8.558,8.558,0,0,0,5.295-1.826L20.6,22.138A1.085,1.085,0,1,0,22.138,20.6ZM5.161,11.645a6.484,6.484,0,1,1,6.484,6.484,6.484,6.484,0,0,1-6.484-6.484Z"
                    transform="translate(-3 -3)"
                  />
                </g>
              </svg>
            </div>
            <ExportCSVButton
              headers={["الشهادة", "التقييم", "المدرسة", "المرتبة"]}
              data={sorted.map(({ school, score }, idx) => [
                idx === 0 ? "الشهادة الذهبية" : idx === 1 ? "الشهادة الفضية" : idx === 2 ? "الشهادة البرونزية" : idx < 9 ? "افضل 9 مدارس" : "-",
                score,
                school.name,
                idx + 1,
              ])}
              file={"النتائج_لموسم_" + seasons[activeSeason].year}
            />
            {!updateMode ? (
              <button
                className="btn-green tw-py-0 tw-px-2 md:tw-px-4 tw-text-xs md:tw-text-base tw-whitespace-nowrap"
                onClick={(e) => setUpdateMode(true)}
                disabled={seasons[activeSeason].resultsPublished}>
                إدخال التقييم
              </button>
            ) : (
              <button
                className="btn-green tw-py-0 tw-px-2 md:tw-px-4 tw-text-xs md:tw-text-base tw-whitespace-nowrap"
                onClick={(e) => saveResults()}
                disabled={false}>
                حفظ النتائج
              </button>
            )}
            {!seasons[activeSeason].resultsPublished && validValues && (
              <button
                className="btn-green tw-py-0 tw-px-2 md:tw-px-4 tw-text-xs md:tw-text-base tw-whitespace-nowrap ms-2"
                onClick={(e) => setShownModal("confirm_publish")}>
                نشر النتائج
              </button>
            )}
          </div>
        </div>
      </Sticky>

      <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-6">
        <div className="lg:tw-col-span-3">
          <Sidebar season={seasons[activeSeason]} requests={requests!} validValues={validValues} />
        </div>
        <div className="lg:tw-col-span-9 tw-overflow-x-scroll">
          <ResultsTable
            search={search}
            activeSeason={activeSeason}
            sorted={sorted.map((s, idx) => ({ ...s, idx }))}
            updateMode={updateMode}
            updateValue={updateValue}
          />
        </div>
      </div>
    </div>
  );
};

export default PublishResults;
